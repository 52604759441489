import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	useIntl,
	cryptowalletCtx,
	localStorageProperty,
} from '@itsa.io/web3utils';
import soundCtx from 'context/sound';
import useLatestWalletVersion from 'hooks/useLatestWalletVersion';
import subscriptionCtx from 'context/subscription';
import navigatorCtx from 'context/navigator';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import nftcardCtx from 'context/nftcard';
import SelectCurrency from 'components/settings/SelectCurrency';
import ListInfo from 'components/settings/ListInfo';
import ScreenLock from 'components/settings/ScreenLock';
import SelectSound from 'components/settings/SelectSound';
import ShowPossibleSpamTokens from 'components/settings/ShowPossibleSpamTokens';
import ActivateExpertMode from 'components/settings/ActivateExpertMode';
import ShowBlockHeight from 'components/settings/ShowBlockHeight';
import ShowGasPrice from 'components/settings/ShowGasPrice';
import MaxTokens from 'components/settings/MaxTokens';
import LightTheme from 'components/settings/LightTheme';
import SubscriptionInfo from 'components/settings/SubscriptionInfo';
import NewVersionApp from 'components/settings/NewVersionApp';
import GasSlider from 'components/common/GasSlider';
import ExclamationIcon from 'components/common/ExclamationIcon';
import { playSound } from 'utils/play-sound';
import isNewerVersion from 'utils/is-newer-version';
import { Button, IconButton } from '@itsa.io/ui';
import { VolumeUp as VolumeUpIcon } from '@itsa.io/ui/icons';
import useAlert from 'hooks/useAlert';
import { PAGES_NAMES, TIMEOUT_SETTINGS_MESSAGE } from 'config/constants';
import useStyles from 'styles/pages/SettingsPage';

const lsSettingsDescriptionShown = localStorageProperty(
	'settingsdescriptionshown',
	{
		simpleType: true, // we use a boolean
		encoded: false,
	},
);

const SettingsPage = props => {
	const propsVersion = props.pageContext.version;
	const { sound } = useContext(soundCtx);
	const subscription = useContext(subscriptionCtx);
	const { hardwareWallet } = useContext(cryptowalletCtx);
	const latestWalletVersion = useLatestWalletVersion(propsVersion);
	const { setPage } = useContext(navigatorCtx);
	const [newerWalletVersion, setNewerWalletVersion] = useState(false);
	const { extraGaspriceHardwareWallet, setExtraGaspriceHardwareWallet } =
		useContext(extragaspricehardwarewalletCtx);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const alert = useAlert();
	const { setSelectedNftCard } = useContext(nftcardCtx);
	const mounted = useRef(false);
	const { hasFullAccess, subscriptionChain } = subscription;
	const isExpired = !!subscriptionChain;
	const { t } = useIntl();
	const classes = useStyles();
	const currencySelector = <SelectCurrency />;
	const listInfoSelector = <ListInfo />;
	const soundSelector = <SelectSound />;
	const showPossibleSpamTokensButtons = <ShowPossibleSpamTokens />;
	const screenLock = <ScreenLock />;
	const expertModeRadioButtons = <ActivateExpertMode />;
	const showBlockHeightRadioButtons = <ShowBlockHeight />;
	const showGasPriceRadioButtons = <ShowGasPrice />;
	const maxTokens = <MaxTokens />;
	const lightThemeRadioButtons = <LightTheme />;

	const newVersionApp = (
		<NewVersionApp
			version={propsVersion}
			latestVersion={latestWalletVersion}
			isNewerVersion={newerWalletVersion}
		/>
	);

	const checkWalletVersion = () => {
		const versionIsNewer = isNewerVersion(propsVersion, latestWalletVersion);
		if (mounted.current) {
			setNewerWalletVersion(versionIsNewer);
		}
	};

	const handlePlaySound = () => {
		playSound(sound);
	};

	const openPageCustomTokens = () => {
		setPage(PAGES_NAMES.CUSTOMTOKENS);
	};

	useEffect(() => {
		if (latestWalletVersion) {
			checkWalletVersion();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestWalletVersion]);

	useEffect(() => {
		setSelectedNftCard(null);
		if (!lsSettingsDescriptionShown.get()) {
			lsSettingsDescriptionShown.set(true);
			alert(t('page.settingspage.description'), {
				severity: 'info',
				timeout: TIMEOUT_SETTINGS_MESSAGE,
			});
		}
		mounted.current = true;
		checkWalletVersion(); // after mounted.current = true;
		return () => {
			mounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const currencyContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.currency')}
			</div>
			{currencySelector}
		</div>
	);

	const listInfoContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.list_info')}
			</div>
			{listInfoSelector}
		</div>
	);

	const soundContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>{t('page.settingspage.sound')}</div>
			{soundSelector}
			<IconButton className={classes.iconButton} onClick={handlePlaySound}>
				<VolumeUpIcon />
			</IconButton>
		</div>
	);

	const showPossibleSpamTokensContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.show_possible_spam_tokens')}
			</div>
			{showPossibleSpamTokensButtons}
		</div>
	);

	const expertmodeContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.professional_mode')}
			</div>
			{expertModeRadioButtons}
		</div>
	);

	const showBlockHeightContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.show_blockheight')}
			</div>
			{showBlockHeightRadioButtons}
		</div>
	);

	const showGasPriceContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.show_gasprice')}
			</div>
			{showGasPriceRadioButtons}
		</div>
	);

	const themeContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.theme_mode')}
			</div>
			{lightThemeRadioButtons}
		</div>
	);

	const screenlockContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.screen_lock')}
			</div>
			{screenLock}
		</div>
	);

	const customTokensContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.custom_tokens')}
			</div>
			<Button
				className={classes.button}
				variant="contained"
				color="primary"
				onClick={openPageCustomTokens}
				disableElevation
			>
				{t('page.settingspage.manage_tokens')}
			</Button>
		</div>
	);

	const subscriptionContent = (
		<ExclamationIcon
			className={clsx(classes.optionContainer, classes.exclamationWrap)}
			isActivate={!hasFullAccess && isExpired}
			blinkBorder
		>
			<div className={classes.optionLabel}>
				{t('page.settingspage.subscription_plan')}
			</div>
			<SubscriptionInfo subscription={subscription} />
		</ExclamationIcon>
	);

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
		setExtraGaspriceHardwareWallet(val);
	};

	let gaspriceContent;
	if (hardwareWallet) {
		gaspriceContent = (
			<div className={clsx(classes.optionContainer)}>
				<div className={classes.optionLabel}>
					{t('page.settingspage.gasprice')}
				</div>
				<GasSlider
					disabled={!hardwareWallet}
					value={gaspriceIndex}
					onChange={changeGaspriceIndex}
				/>
			</div>
		);
	}

	const versionContent = (
		<ExclamationIcon
			className={clsx(classes.optionContainer, classes.exclamationWrap)}
			isActivate={newerWalletVersion}
			blinkBorder
		>
			<div className={classes.optionLabel}>
				{t('page.settingspage.version')}
			</div>
			{t('page.settingspage.current')}: {propsVersion}
			{newVersionApp}
		</ExclamationIcon>
	);

	const maxTokensContent = (
		<div className={classes.optionContainer}>
			<div className={classes.optionLabel}>
				{t('page.settingspage.show_tokens')}
			</div>
			{maxTokens}
		</div>
	);

	return (
		<div className={classes.root}>
			<div className={classes.title}>{t('page.settingspage.title')}</div>
			<hr className={classes.separateLine} />
			<div className={classes.optionWrapper}>
				{versionContent}
				{expertmodeContent}
				{subscriptionContent}
				{screenlockContent}
				{themeContent}
				{currencyContent}
				{listInfoContent}
				{showPossibleSpamTokensContent}
				{showBlockHeightContent}
				{showGasPriceContent}
				{gaspriceContent}
				{soundContent}
				{maxTokensContent}
				{customTokensContent}
			</div>
		</div>
	);
};

SettingsPage.propTypes = {
	pageContext: PropTypes.shape({
		version: PropTypes.string.isRequired,
	}).isRequired,
};

export default SettingsPage;
