import { makeStyles } from '@itsa.io/ui';

export default makeStyles(() => ({
	root: {
		width: '100%',
		maxWidth: 200,
		textTransform: 'uppercase',
	},
	menuItem: {
		textTransform: 'uppercase',
	},
}));
