import React, { useContext } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@itsa.io/ui';
import gaspricevisibleCtx from 'context/gaspricevisible';

import useStyles from 'styles/components/settings/ShowGasPrice';

const ShowGasPrice = () => {
	const classes = useStyles();
	const { gasPriceVisible, toggleGasPriceVisible } =
		useContext(gaspricevisibleCtx);

	const handleRadioButtonsChange = event => {
		// note: typeof event.target.value === 'string'
		toggleGasPriceVisible(event.target.value === 'true');
	};

	return (
		<FormControl component="fieldset">
			<RadioGroup
				aria-label="expertmode"
				className={classes.radioGroup}
				name="sound"
				onChange={handleRadioButtonsChange}
				row
				value={gasPriceVisible}
			>
				<FormControlLabel
					control={<Radio color="primary" />}
					label="off"
					value={false}
				/>
				<FormControlLabel
					control={<Radio color="primary" />}
					label="on"
					value
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default ShowGasPrice;
