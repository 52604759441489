import React, { useContext } from 'react';
import { CircularProgress } from '@itsa.io/ui';
import tokenListCtx from 'context/tokenlist';
import selectedtokenCtx from 'context/selectedtoken';
import navigatorCtx from 'context/navigator';
import DashboardPage from 'components/pages/DashboardPage';
import TokenPage from 'components/pages/TokenPage';
import SendPage from 'components/pages/SendPage';
import WrapPage from 'components/pages/WrapPage';
import ExchangePage from 'components/pages/ExchangePage';
import NewFeaturesPage from 'components/pages/NewFeaturesPage';
import CollateralPage from 'components/pages/CollateralPage';
import SettingsPage from 'components/pages/SettingsPage';
import CustomTokensPage from 'components/pages/CustomTokensPage';
import SubscriptionPage from 'components/pages/SubscriptionPage';
import NftExtraWalletsPage from 'components/pages/NftExtraWalletsPage';
import SubscriptionsExtraWalletsPage from 'components/pages/SubscriptionsExtraWalletsPage';
import BindToSubscriptionPage from 'components/pages/BindToSubscriptionPage';
import TransferNftPage from 'components/pages/TransferNftPage';
import LegalPage from 'components/pages/LegalPage';
import PrivacyPage from 'components/pages/PrivacyPage';
import { cryptowalletCtx } from '@itsa.io/web3utils';
import { PAGES_NAMES, NETWORK_NAMES } from 'config/constants';
import useStyles from 'styles/pages/IndexPage';

const IndexPage = props => {
	const classes = useStyles();
	const { isLoading: loading } = useContext(tokenListCtx);
	const { selectedToken } = useContext(selectedtokenCtx);
	const { page } = useContext(navigatorCtx);
	const { chainId, address } = useContext(cryptowalletCtx);

	if (loading) {
		return (
			<div className={classes.circularContainer}>
				<CircularProgress size={30} />
			</div>
		);
	}

	if (page === PAGES_NAMES.HOME) {
		if (NETWORK_NAMES[chainId] && address) {
			return <DashboardPage {...props} />;
		}
	}
	if (page === PAGES_NAMES.SETTINGS) {
		return <SettingsPage {...props} />;
	}
	if (page === PAGES_NAMES.NEWFEATURES) {
		return <NewFeaturesPage {...props} />;
	}
	if (page === PAGES_NAMES.SUBSCRIPTION) {
		return <SubscriptionPage {...props} />;
	}
	if (page === PAGES_NAMES.CUSTOMTOKENS) {
		return <CustomTokensPage {...props} />;
	}
	if (page === PAGES_NAMES.NFTADRESSES) {
		return <NftExtraWalletsPage {...props} />;
	}
	if (page === PAGES_NAMES.SUBSCRIPTIONADDRESSES) {
		return <SubscriptionsExtraWalletsPage {...props} />;
	}
	if (page === PAGES_NAMES.BINDTOSUBSCRIPTION) {
		return <BindToSubscriptionPage {...props} />;
	}
	if (page === PAGES_NAMES.TRANSFERNFT) {
		return <TransferNftPage {...props} />;
	}
	if (page === PAGES_NAMES.LEGAL) {
		return <LegalPage {...props} />;
	}
	if (page === PAGES_NAMES.PRIVACY) {
		return <PrivacyPage {...props} />;
	}

	if (selectedToken) {
		if (page === PAGES_NAMES.SENDCOIN) {
			return <SendPage {...props} coinPage />;
		}
		if (page === PAGES_NAMES.SENDTOKEN) {
			return <SendPage {...props} coinPage={false} />;
		}
		if (page === PAGES_NAMES.WRAP) {
			return <WrapPage {...props} />;
		}
		if (page === PAGES_NAMES.EXCHANGE) {
			return <ExchangePage {...props} />;
		}
		if (page === PAGES_NAMES.TOKEN) {
			return <TokenPage {...props} key={selectedToken.address} />; // need to set the key in order to get a fresh txs state
		}
		if (page === PAGES_NAMES.COLLATERAL) {
			return <CollateralPage {...props} />;
		}
	} else if (page === PAGES_NAMES.SETTINGS) {
		return <SettingsPage {...props} />;
	}
	return <DashboardPage {...props} />;
};

export default IndexPage;
