import { makeStyles } from '@itsa.io/ui';
import { FONT_SIZE_DECIMALS } from 'config/constants';

export default makeStyles(theme => ({
	root: {
		maxWidth: 500,
		width: '100%',
		textAlign: 'center',
	},
	buttonFirstRow: {
		marginTop: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(5),
		},
	},
	buttonHorizontalGroup: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: 500,
		gap: theme.spacing(4),
		'& button': {
			width: '100%',
			maxWidth: '100%',
			margin: theme.spacing(0.75, 0),
			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(1.5, 0),
			},
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			'& button': {
				width: '100%',
			},
		},
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
			width: '85%',
			gap: theme.spacing(1),
			flexDirection: 'column-reverse',
		},
	},
	logo: {
		width: 96,
		height: 96,
		fontSize: 40,
		margin: theme.spacing(0, 'auto'),
		[theme.breakpoints.down('sm')]: {
			width: 80,
			height: 80,
		},
	},
	logoUnknown: {
		fontSize: 48,
		fontWeight: 500,
		color: theme.palette.secondary.light,
		margin: theme.spacing(0, 'auto'),
	},
	tokenDescription: {
		fontSize: 30,
		marginTop: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			fontSize: 35,
			marginTop: theme.spacing(1.5),
		},
	},
	textFieldAmount: {
		marginTop: theme.spacing(10),
	},
	textFieldRecipient: {
		marginTop: theme.spacing(3),
	},
	balance: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		fontWeight: 500,
		textAlign: 'right',
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	symbolMaxAmount: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: theme.spacing(3, 0, 0, 0),
		'&>div': {
			fontSize: 20,
			fontWeight: 500,
			alignSelf: 'center',
			marginRight: theme.spacing(1),
		},
	},
	buttonMax: {
		padding: theme.spacing(0.5, 2),
	},
	recipientContainer: {
		marginTop: theme.spacing(3),
	},
	iconButton: {
		borderRadius: 8,
		border: `1px solid ${theme.palette.primary.main}`,
		'& + &': {
			marginLeft: theme.spacing(1),
		},
	},
	warningIcon: {
		fontSize: '1.2rem',
		color: theme.palette.primary.main,
		marginRight: theme.spacing(1),
	},
	criticalBalanceDescription: {
		display: 'flex',
		fontSize: '0.85rem',
		textAlign: 'left',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(3),
	},
	sendWarningDescription: {
		display: 'flex',
		fontSize: '0.85rem',
		textAlign: 'left',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(3),
	},
	pendingTransaction: {
		animation: `$blink 1.5s infinite`,
	},
	'@keyframes blink': {
		'50%': {
			opacity: 0.5,
		},
	},
}));
