/* eslint-disable prefer-destructuring */
import getSDK from 'utils/get-sdk';
import { getRouterContract } from 'utils/get-contract';
import { getToken } from 'utils/smartcontracts/token';
import { getPair, getReserves } from 'utils/smartcontracts/pair';
import setGasLimitAndPrice from 'utils/set-gas-limit-and-price';
import addressMatch from 'utils/address-match';
import noLeadingZeros from 'utils/no-leading-zeros';
import {
	priceChangeFromExactInput,
	priceChangeToExactInput,
	priceChangeFromExactOutput,
	priceChangeToExactOutput,
	getAmountIn,
	getAmountOut,
} from 'utils/get-priceimpact';
import { utils } from 'web3';
import { toBN } from '@itsa.io/web3utils';
import {
	BASE_TRADE_TOKENS,
	STABLECOIN_REF_ADDRESSES,
	ROUTER_ADDRESSES,
	ROUTER_METHODS,
	TRADES_DEADLINES_MINS,
	HEX0,
	ALTERNATIVE_BASE_TRADE_TOKENS,
} from 'config/constants';
import { BN_GAS_LIMIT_UNIT_PRICES } from 'config/constants/transaction-gas-units';
import { getWeb3 } from 'utils/get-web3';

const { isBN, toHex, isAddress } = utils;

const REGEXP_ONLY_NUMBERS = /[^\d]/g;

const errorMessages = {
	TRADE_CONTRACT_EXCEPTION: 'Trade Contract Exception',
	ZERO_AMOUNT: 'Zero amount',
	INVALID_SMARTCONTRACT_ADDRESS: 'Invalid Trade SmartContract Address',
};

const TEN_THOUSAND = '10000';

const MAX_PRICE_IMPACT = 100000000000000;

/**
 * Generates an Exception thrown by a trade error
 *
 * @method TradeException
 * @protected
 * @param txResponse {Object} the txResponse that raised the exception
 * @since 0.0.1
 */
// eslint-disable-next-line func-names
const TradeException = function (msg) {
	// note: do not use arrow function, because we need to maintain the right context
	let keys;
	let l;
	let i;
	let key;

	this.name = 'TradeException';
	if (typeof msg === 'string') {
		this.message = msg;
	} else {
		this.message = errorMessages.TRADE_CONTRACT_EXCEPTION;
		keys = Object.keys(msg);
		l = keys.length;
		i = -1;
		// eslint-disable-next-line no-plusplus
		while (++i < l) {
			key = keys[i];
			this[key] = msg[key];
		}
	}
};

const computeTradePriceBreakdown = async (
	chainId,
	route,
	amount,
	tradeType,
	fromIsBaseToken,
	toIsBaseToken,
	currentBlockHeight,
) => {
	const { TradeType } = getSDK(chainId);
	const amountBN = toBN(amount);
	const token0 = route.path[0];
	const token1 = route.path[1];
	const reserves = await getReserves(
		chainId,
		token0,
		token1,
		true,
		currentBlockHeight,
	);
	const reserve0 = toBN(reserves[0]);
	const reserve1 = toBN(reserves[1]);

	if (tradeType === TradeType.EXACT_INPUT) {
		return {
			from: fromIsBaseToken
				? 0
				: priceChangeFromExactInput(amountBN, reserve0, reserve1),
			to: toIsBaseToken
				? 0
				: priceChangeToExactInput(amountBN, reserve0, reserve1),
		};
	}
	return {
		from: fromIsBaseToken
			? 0
			: priceChangeFromExactOutput(amountBN, reserve0, reserve1),
		to: toIsBaseToken
			? 0
			: priceChangeToExactOutput(amountBN, reserve0, reserve1),
	};
};

const getSimplePathAndRoute = async (
	chainId,
	baseTradeToken,
	tokenSell,
	tokenBuy,
) => {
	const { Route } = getSDK(chainId);
	const routes = [];
	let path = [];
	try {
		let pair1;
		if (
			addressMatch(baseTradeToken.address, tokenSell.address) ||
			addressMatch(baseTradeToken.address, tokenBuy.address)
		) {
			pair1 = await getPair(chainId, tokenSell, tokenBuy);
			if (pair1) {
				routes.push(pair1);
				path = [tokenSell.address, tokenBuy.address];
			}
		} else {
			const tokenWrapped = await getToken(chainId, baseTradeToken);
			pair1 = await getPair(chainId, tokenSell, tokenWrapped);
			if (pair1) {
				const pair2 = await getPair(chainId, tokenWrapped, tokenBuy);
				if (pair2) {
					routes.push(pair1);
					routes.push(pair2);
					path = [tokenSell.address, baseTradeToken.address, tokenBuy.address];
				}
			}
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
	}
	let route;
	if (routes[0]) {
		route = new Route(routes, tokenSell);
	}
	return {
		path,
		route,
	};
};

const getComplexPathAndRoute = async (
	chainId,
	tokenSell,
	tokenMiddle1,
	tokenMiddle2,
	tokenBuy,
) => {
	const { Route } = getSDK(chainId);
	const midTokens = await Promise.all([
		getToken(chainId, tokenMiddle1),
		getToken(chainId, tokenMiddle2),
	]);
	const tokenMid1 = midTokens[0];
	const tokenMid2 = midTokens[1];
	const pairs = [];

	pairs.push(getPair(chainId, tokenSell, tokenMid1));
	pairs.push(getPair(chainId, tokenMid1, tokenMid2));
	pairs.push(getPair(chainId, tokenMid2, tokenBuy));

	let routePairs = await Promise.all(pairs);

	// if one of the pairs does not exists: empty routes
	routePairs = routePairs.filter(pair => !!pair);

	const path = [
		tokenSell.address,
		tokenMiddle1.address,
		tokenMiddle2.address,
		tokenBuy.address,
	];

	if (routePairs.length !== pairs.length) {
		return {
			path: [],
			route: null,
		};
	}
	const route = new Route(routePairs, tokenSell);
	return {
		path,
		route,
	};
};

const getPathAndRoute = async (
	chainId,
	baseTradeToken,
	tokenSell,
	tokenBuy,
	amountToSell,
	amountToBuy,
	currentBlockHeight,
) => {
	// check if the chainId has an alternativeTradeToken,
	// if so, then we need to inspect multiple paths
	let alternativeBaseTradeTokenDefinition =
		ALTERNATIVE_BASE_TRADE_TOKENS[chainId];
	// make it an array
	if (
		alternativeBaseTradeTokenDefinition &&
		!Array.isArray(alternativeBaseTradeTokenDefinition)
	) {
		alternativeBaseTradeTokenDefinition = [alternativeBaseTradeTokenDefinition];
	}

	if (
		!alternativeBaseTradeTokenDefinition ||
		!alternativeBaseTradeTokenDefinition[0]
	) {
		// use simple path calculation, because we only have 1 base ref token
		return getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy);
	}
	const { TradeType } = getSDK(chainId);

	const alternativeBaseTradeToken = await getToken(
		chainId,
		alternativeBaseTradeTokenDefinition[0],
	);

	let alternativeBaseTradeToken2;
	if (alternativeBaseTradeTokenDefinition[1]) {
		alternativeBaseTradeToken2 = await getToken(
			chainId,
			alternativeBaseTradeTokenDefinition[1],
		);
	}

	/*
	    in this stage, baseTradeToken is an array: we need to choose the most route
	    As an example of all possible configurations, we will tabel WETH as baseTradeToken and USDT as alternativeBaseTradeToken:

		A) WETH to USDT swap:
			1. WETH -> USDT

		B) USDT to WETH swap:
			1. USDT -> WETH

		C) USDT to Token swap:
			1. USDT -> Token2
			2. USDT -> WETH -> Token2

		D) WETH to Token swap:
			1. WETH -> Token2
			2. WETH -> USDT -> Token2

		E) Token to USDT swap:
			1. Token1 -> USDT
			2. Token1 -> WETH -> USDT

		F) Token to WETH swap:
			1. Token1 -> WETH
			2. Token1 -> USDT -> WETH

		G) Token to Token swap:
			1. Token1 -> WETH -> Token2
			2. Token1 -> USDT -> Token2
			3. Token1 -> WETH -> USDT -> Token2
			4. Token1 -> USDT -> WETH -> Token2
	*/

	// Handle A1:
	if (
		addressMatch(alternativeBaseTradeToken.address, tokenSell.address) &&
		addressMatch(baseTradeToken.address, tokenBuy.address)
	) {
		return getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy);
	}

	// Handle A2:
	if (
		alternativeBaseTradeToken2 &&
		addressMatch(alternativeBaseTradeToken2.address, tokenSell.address) &&
		addressMatch(baseTradeToken.address, tokenBuy.address)
	) {
		return getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy);
	}

	// Handle B1:
	if (
		addressMatch(baseTradeToken.address, tokenSell.address) &&
		addressMatch(alternativeBaseTradeToken.address, tokenBuy.address)
	) {
		return getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy);
	}

	// Handle B2:
	if (
		alternativeBaseTradeToken2 &&
		addressMatch(baseTradeToken.address, tokenSell.address) &&
		addressMatch(alternativeBaseTradeToken2.address, tokenBuy.address)
	) {
		return getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy);
	}

	const promiseRoutes = [];
	let fromIsBaseToken;
	let toIsBaseToken;

	// Handle C1: from USDT
	// 1. USDT -> Token2
	// 2. USDT -> WETH -> Token2
	if (addressMatch(alternativeBaseTradeToken.address, tokenSell.address)) {
		promiseRoutes.push(
			getSimplePathAndRoute(
				chainId,
				alternativeBaseTradeToken,
				tokenSell,
				tokenBuy,
			),
		);
		promiseRoutes.push(
			getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy),
		);
		fromIsBaseToken = false;
		toIsBaseToken = false;
	}

	// Handle C2: from USDC
	// 1. USDC -> Token2
	// 2. USDC -> WETH -> Token2
	if (
		alternativeBaseTradeToken2 &&
		addressMatch(alternativeBaseTradeToken2.address, tokenSell.address)
	) {
		promiseRoutes.push(
			getSimplePathAndRoute(
				chainId,
				alternativeBaseTradeToken2,
				tokenSell,
				tokenBuy,
			),
		);
		promiseRoutes.push(
			getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy),
		);
		fromIsBaseToken = false;
		toIsBaseToken = false;
	}

	// Handle D: from WETH
	// 1. WETH -> Token2
	// 2. WETH -> USDT -> Token2
	// 2. WETH -> USDC -> Token2
	if (addressMatch(baseTradeToken.address, tokenSell.address)) {
		promiseRoutes.push(
			getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy),
		);
		promiseRoutes.push(
			getSimplePathAndRoute(
				chainId,
				alternativeBaseTradeToken,
				tokenSell,
				tokenBuy,
			),
		);
		if (alternativeBaseTradeToken2) {
			promiseRoutes.push(
				getSimplePathAndRoute(
					chainId,
					alternativeBaseTradeToken2,
					tokenSell,
					tokenBuy,
				),
			);
		}
		fromIsBaseToken = true;
		toIsBaseToken = false;
	}

	// Handle E1: to USDT
	// 1. Token1 -> USDT
	// 2. Token1 -> WETH -> USDT
	if (addressMatch(alternativeBaseTradeToken.address, tokenBuy.address)) {
		promiseRoutes.push(
			getSimplePathAndRoute(
				chainId,
				alternativeBaseTradeToken,
				tokenSell,
				tokenBuy,
			),
		);
		promiseRoutes.push(
			getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy),
		);
		fromIsBaseToken = false;
		toIsBaseToken = false;
	}

	// Handle E2: to USDC
	// 1. Token1 -> USDC
	// 2. Token1 -> WETH -> USDC
	if (
		alternativeBaseTradeToken2 &&
		addressMatch(alternativeBaseTradeToken2.address, tokenBuy.address)
	) {
		promiseRoutes.push(
			getSimplePathAndRoute(
				chainId,
				alternativeBaseTradeToken2,
				tokenSell,
				tokenBuy,
			),
		);
		promiseRoutes.push(
			getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy),
		);
		fromIsBaseToken = false;
		toIsBaseToken = false;
	}

	// Handle F: to WETH
	// 1. Token1 -> WETH
	// 2. Token1 -> USDT -> WETH
	// 2. Token1 -> USDC -> WETH
	if (addressMatch(baseTradeToken.address, tokenBuy.address)) {
		promiseRoutes.push(
			getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy),
		);
		promiseRoutes.push(
			getSimplePathAndRoute(
				chainId,
				alternativeBaseTradeToken,
				tokenSell,
				tokenBuy,
			),
		);
		if (alternativeBaseTradeToken2) {
			promiseRoutes.push(
				getSimplePathAndRoute(
					chainId,
					alternativeBaseTradeToken2,
					tokenSell,
					tokenBuy,
				),
			);
		}
		fromIsBaseToken = false;
		toIsBaseToken = true;
	}

	// Handle G: all other scenarios
	// 1. Token1 -> WETH -> Token2
	// 2a. Token1 -> USDT -> Token2
	// 2b. Token1 -> USDC -> Token2
	// 3a. Token1 -> WETH -> USDT -> Token2
	// 3b. Token1 -> WETH -> USDC -> Token2
	// 4a. Token1 -> USDT -> WETH -> Token2
	// 4b. Token1 -> USDC -> WETH -> Token2
	// 5a. Token1 -> USDT -> USDC -> Token2
	// 5b. Token1 -> USDC -> USDT -> Token2
	if (promiseRoutes.length === 0) {
		// 1
		promiseRoutes.push(
			getSimplePathAndRoute(chainId, baseTradeToken, tokenSell, tokenBuy),
		);

		// 2a
		promiseRoutes.push(
			getSimplePathAndRoute(
				chainId,
				alternativeBaseTradeToken,
				tokenSell,
				tokenBuy,
			),
		);

		// 3a
		promiseRoutes.push(
			getComplexPathAndRoute(
				chainId,
				tokenSell,
				baseTradeToken,
				alternativeBaseTradeToken,
				tokenBuy,
			),
		);

		// 4a
		promiseRoutes.push(
			getComplexPathAndRoute(
				chainId,
				tokenSell,
				alternativeBaseTradeToken,
				baseTradeToken,
				tokenBuy,
			),
		);

		if (alternativeBaseTradeToken2) {
			// 2b
			promiseRoutes.push(
				getSimplePathAndRoute(
					chainId,
					alternativeBaseTradeToken2,
					tokenSell,
					tokenBuy,
				),
			);

			// 3b
			promiseRoutes.push(
				getComplexPathAndRoute(
					chainId,
					tokenSell,
					baseTradeToken,
					alternativeBaseTradeToken2,
					tokenBuy,
				),
			);

			// 4b
			promiseRoutes.push(
				getComplexPathAndRoute(
					chainId,
					tokenSell,
					alternativeBaseTradeToken2,
					baseTradeToken,
					tokenBuy,
				),
			);

			// 5a
			promiseRoutes.push(
				getComplexPathAndRoute(
					chainId,
					tokenSell,
					alternativeBaseTradeToken,
					alternativeBaseTradeToken2,
					tokenBuy,
				),
			);

			// 5b
			promiseRoutes.push(
				getComplexPathAndRoute(
					chainId,
					tokenSell,
					alternativeBaseTradeToken2,
					alternativeBaseTradeToken,
					tokenBuy,
				),
			);
		}

		fromIsBaseToken = false;
		toIsBaseToken = false;
	}

	// take the most profitable route:
	const priceImpacts = [];
	let routes = await Promise.all(promiseRoutes);

	if (amountToSell) {
		if (routes[0].route) {
			priceImpacts.push(
				computeTradePriceBreakdown(
					chainId,
					routes[0].route,
					amountToSell,
					TradeType.EXACT_INPUT,
					fromIsBaseToken,
					toIsBaseToken,
					currentBlockHeight,
				),
			);
		}

		if (routes[1].route) {
			priceImpacts.push(
				computeTradePriceBreakdown(
					chainId,
					routes[1].route,
					amountToSell,
					TradeType.EXACT_INPUT,
					fromIsBaseToken,
					toIsBaseToken,
					currentBlockHeight,
				),
			);
		}

		if (routes.length > 2) {
			// Handle A or B has either 2 or 3 routes
			if (routes[2].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[2].route,
						amountToSell,
						TradeType.EXACT_INPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
		}
		if (routes.length > 3) {
			// Handle A or B has either 2 or 3 routes
			if (routes[3].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[3].route,
						amountToSell,
						TradeType.EXACT_INPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
		}
		if (routes.length > 4) {
			if (routes[4].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[4].route,
						amountToSell,
						TradeType.EXACT_INPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
			if (routes[5].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[5].route,
						amountToSell,
						TradeType.EXACT_INPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
			if (routes[6].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[6].route,
						amountToSell,
						TradeType.EXACT_INPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
			if (routes[7].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[7].route,
						amountToSell,
						TradeType.EXACT_INPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
			if (routes[8].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[8].route,
						amountToSell,
						TradeType.EXACT_INPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
		}
	} else {
		if (routes[0].route) {
			priceImpacts.push(
				computeTradePriceBreakdown(
					chainId,
					routes[0].route,
					amountToBuy,
					TradeType.EXACT_OUTPUT,
					fromIsBaseToken,
					toIsBaseToken,
					currentBlockHeight,
				),
			);
		}

		if (routes[1].route) {
			priceImpacts.push(
				computeTradePriceBreakdown(
					chainId,
					routes[1].route,
					amountToBuy,
					TradeType.EXACT_OUTPUT,
					fromIsBaseToken,
					toIsBaseToken,
					currentBlockHeight,
				),
			);
		}

		if (routes.length > 2) {
			// Handle A or B has either 2 or 3 routes
			if (routes[2].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[2].route,
						amountToBuy,
						TradeType.EXACT_OUTPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
		}

		if (routes.length > 3) {
			// Handle A or B has either 2 or 3 routes
			if (routes[3].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[3].route,
						amountToBuy,
						TradeType.EXACT_OUTPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
		}

		if (routes.length > 4) {
			if (routes[4].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[4].route,
						amountToBuy,
						TradeType.EXACT_OUTPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
			if (routes[5].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[5].route,
						amountToBuy,
						TradeType.EXACT_OUTPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
			if (routes[6].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[6].route,
						amountToBuy,
						TradeType.EXACT_OUTPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
			if (routes[7].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[7].route,
						amountToBuy,
						TradeType.EXACT_OUTPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
			if (routes[8].route) {
				priceImpacts.push(
					computeTradePriceBreakdown(
						chainId,
						routes[8].route,
						amountToBuy,
						TradeType.EXACT_OUTPUT,
						fromIsBaseToken,
						toIsBaseToken,
						currentBlockHeight,
					),
				);
			}
		}
	}

	// eliminate false routes
	routes.forEach((route, i) => {
		if (!route.route) {
			// delete index i from priceImpacts
			priceImpacts.splice(i, 1);
		}
	});
	routes = routes.filter(route => !!route.route);

	const result = await Promise.all(priceImpacts);

	const bestItem = result.reduce((bestItem, item, index) => {
		const nextItem = {
			index,
			priceimpact: item.to || item.from, // either from or to will be 0, depending on but or sell. We are intersted in the one that has a value
		};
		// return the item with the less priceimpact:
		return !bestItem ||
			// (nextItem.priceimpact > 0 && nextItem.priceimpact < bestItem.priceimpact)
			(nextItem.priceimpact > 0 && nextItem.priceimpact > bestItem.priceimpact)
			? nextItem
			: bestItem;
	}, null);

	return bestItem && routes[bestItem.index];
};

export const getMinimumAmountOut = async (
	chainId,
	baseTradeToken,
	tokenToSell,
	tokenToBuy,
	amountToSell = '0',
	slippage = 0.5,
	currentBlockHeight,
	recursive,
) => {
	if (isBN(amountToSell)) {
		amountToSell = amountToSell.toString();
	}
	if (amountToSell === '0' || amountToSell === '') {
		return { amountOutMin: toBN('0'), priceImpact: '0.00', tradeSteps: 0 };
	}
	const { TradeType } = getSDK(chainId);
	const tokenSell = await getToken(chainId, tokenToSell);
	const tokenBuy = await getToken(chainId, tokenToBuy);
	const pathAndRoute = await getPathAndRoute(
		chainId,
		baseTradeToken,
		tokenSell,
		tokenBuy,
		amountToSell,
		null,
		currentBlockHeight,
	);

	if (!pathAndRoute) {
		return { amountOutMin: toBN('0'), priceImpact: '0.00', tradeSteps: 0 };
	}
	const { route } = pathAndRoute;
	if (!route) {
		return { amountOutMin: toBN('0'), priceImpact: '0.00', tradeSteps: 0 };
	}

	const token0 = route.path[0];
	const token1 = route.path[1];
	const reserves = await getReserves(
		chainId,
		token0,
		token1,
		false,
		currentBlockHeight,
	);

	const reserve0 = toBN(reserves[0]);
	const reserve1 = toBN(reserves[1]);
	let amountOutMin = getAmountOut(toBN(amountToSell), reserve0, reserve1);

	const fromIsBaseToken = addressMatch(
		tokenSell.address,
		BASE_TRADE_TOKENS[chainId],
	);
	const toIsBaseToken = addressMatch(
		tokenBuy.address,
		BASE_TRADE_TOKENS[chainId],
	);
	const fromIsStableTokenRef = addressMatch(
		tokenSell.address,
		STABLECOIN_REF_ADDRESSES[chainId],
	);
	const toIsStableTokenRef = addressMatch(
		tokenBuy.address,
		STABLECOIN_REF_ADDRESSES[chainId],
	);

	let priceImpact;
	if (route.path.length === 2) {
		priceImpact = await computeTradePriceBreakdown(
			chainId,
			route,
			amountToSell,
			TradeType.EXACT_INPUT,
			fromIsBaseToken,
			toIsBaseToken,
			currentBlockHeight,
		);
	} else {
		// break the calculation down into 2 or 3 separate trades
		const pathTokens = route.path;
		const { amountOutMin: amountOutMin1, priceImpact: priceImpact1 } =
			await getMinimumAmountOut(
				chainId,
				baseTradeToken,
				pathTokens[0],
				pathTokens[1],
				amountToSell,
				slippage,
				currentBlockHeight,
				true,
			);
		const { amountOutMin: amountOutMin2, priceImpact: priceImpact2 } =
			await getMinimumAmountOut(
				chainId,
				baseTradeToken,
				pathTokens[1],
				pathTokens[2],
				amountOutMin1,
				slippage,
				currentBlockHeight,
				true,
			);
		amountOutMin = amountOutMin2;
		let priceImpactTo = priceImpact2;
		if (pathTokens.length === 4) {
			const { amountOutMin: amountOutMin3, priceImpact: priceImpact3 } =
				await getMinimumAmountOut(
					chainId,
					baseTradeToken,
					pathTokens[2],
					pathTokens[3],
					amountOutMin2,
					slippage,
					currentBlockHeight,
					true,
				);
			priceImpactTo = priceImpact3; // we need to retain the final priceimpact
			amountOutMin = amountOutMin3;
		}

		priceImpact = {
			from: priceImpact1.from,
			to: priceImpactTo.to,
		};
	}

	if (!recursive && (fromIsStableTokenRef || toIsStableTokenRef)) {
		if (fromIsBaseToken || toIsBaseToken) {
			const bkp = priceImpact.from;
			priceImpact.from = -priceImpact.to;
			priceImpact.to = -bkp;
		} else {
			// 2 step swap, which will effect the price even more, since the BaseToken price will be moved
			const cummulated =
				100 * ((1 + priceImpact.from / 100) * (1 - priceImpact.to / 100) - 1);
			if (fromIsStableTokenRef) {
				priceImpact.from = 0;
				priceImpact.to = -cummulated;
			} else {
				priceImpact.from = cummulated;
				priceImpact.to = 0;
			}
		}
	}
	if (priceImpact.from > MAX_PRICE_IMPACT) {
		priceImpact.from = 'infinite';
	}
	if (priceImpact.to > MAX_PRICE_IMPACT) {
		priceImpact.to = 'infinite';
	}

	// for some reason, we failed to calculate the exact priceimpact on trades between DAI and non-coin:
	priceImpact.notExactFrom =
		!fromIsBaseToken && !toIsBaseToken && toIsStableTokenRef;
	priceImpact.notExactTo =
		!fromIsBaseToken && !toIsBaseToken && fromIsStableTokenRef;

	if (priceImpact.from > -0.005 && priceImpact.from < 0) {
		priceImpact.from = 0;
	} else if (priceImpact.from < 0.005 && priceImpact.from > 0) {
		priceImpact.from = 0;
	}
	if (priceImpact.to > -0.005 && priceImpact.to < 0) {
		priceImpact.to = 0;
	} else if (priceImpact.to < 0.005 && priceImpact.to > 0) {
		priceImpact.to = 0;
	}

	return { amountOutMin, priceImpact, tradeSteps: route.path.length - 1 };
};

export const getMaximumAmountIn = async (
	chainId,
	baseTradeToken,
	tokenToSell,
	tokenToBuy,
	amountToBuy = '0',
	slippage = 0.5,
	currentBlockHeight,
	recursive,
) => {
	if (isBN(amountToBuy)) {
		amountToBuy = amountToBuy.toString();
	}
	if (amountToBuy === '0' || amountToBuy === '') {
		return { amountInMax: toBN('0'), priceImpact: '0.00', tradeSteps: 0 };
	}
	const { TradeType } = getSDK(chainId);
	const tokenSell = await getToken(chainId, tokenToSell);
	const tokenBuy = await getToken(chainId, tokenToBuy);

	const pathAndRoute = await getPathAndRoute(
		chainId,
		baseTradeToken,
		tokenSell,
		tokenBuy,
		null,
		amountToBuy,
		currentBlockHeight,
	);

	if (!pathAndRoute) {
		return { amountOutMin: toBN('0'), priceImpact: '0.00', tradeSteps: 0 };
	}
	const { route } = pathAndRoute;

	if (!route) {
		return { amountOutMin: toBN('0'), priceImpact: '0.00', tradeSteps: 0 };
	}

	const token0 = route.path[0];
	const token1 = route.path[1];
	const reserves = await getReserves(
		chainId,
		token0,
		token1,
		false,
		currentBlockHeight,
	);
	const reserve0 = toBN(reserves[0]);
	const reserve1 = toBN(reserves[1]);
	let amountInMax = getAmountIn(toBN(amountToBuy), reserve0, reserve1);

	const fromIsBaseToken = addressMatch(
		tokenSell.address,
		BASE_TRADE_TOKENS[chainId],
	);
	const toIsBaseToken = addressMatch(
		tokenBuy.address,
		BASE_TRADE_TOKENS[chainId],
	);
	const fromIsStableTokenRef = addressMatch(
		tokenSell.address,
		STABLECOIN_REF_ADDRESSES[chainId],
	);
	const toIsStableTokenRef = addressMatch(
		tokenBuy.address,
		STABLECOIN_REF_ADDRESSES[chainId],
	);

	let priceImpact;
	if (route.path.length === 2) {
		// if (fromIsBaseToken || toIsBaseToken) {
		priceImpact = await computeTradePriceBreakdown(
			chainId,
			route,
			amountToBuy,
			TradeType.EXACT_OUTPUT,
			fromIsBaseToken,
			toIsBaseToken,
			currentBlockHeight,
		);
	} else {
		// break the calculation down into 2 or 3 separate trades
		const pathTokens = route.path;
		let amountToIn;
		let priceImpactTo;
		if (pathTokens.length === 4) {
			const { amountInMax: amountInMax3, priceImpact: priceImpact3 } =
				await getMaximumAmountIn(
					chainId,
					baseTradeToken,
					pathTokens[2],
					pathTokens[3],
					amountToBuy,
					slippage,
					currentBlockHeight,
					true,
				);
			amountToIn = amountInMax3;
			priceImpactTo = priceImpact3; // we need to retain the final priceimpact
		} else {
			amountToIn = amountToBuy;
		}

		const { amountInMax: amountInMax2, priceImpact: priceImpact2 } =
			await getMaximumAmountIn(
				chainId,
				baseTradeToken,
				pathTokens[1],
				pathTokens[2],
				amountToIn,
				slippage,
				currentBlockHeight,
				true,
			);
		if (pathTokens.length < 4) {
			priceImpactTo = priceImpact2;
		}
		const { amountInMax: amountInMax1, priceImpact: priceImpact1 } =
			await getMaximumAmountIn(
				chainId,
				baseTradeToken,
				pathTokens[0],
				pathTokens[1],
				amountInMax2,
				slippage,
				currentBlockHeight,
				true,
			);
		amountInMax = amountInMax1;
		priceImpact = {
			from: priceImpact1.from,
			to: priceImpactTo.to,
		};
	}

	if (!recursive && (fromIsStableTokenRef || toIsStableTokenRef)) {
		if (fromIsBaseToken || toIsBaseToken) {
			const bkp = priceImpact.from;
			priceImpact.from = -priceImpact.to;
			priceImpact.to = -bkp;
		} else {
			// 2 step swap, which will effect the price even more, since the BaseToken price will be moved
			const cummulated =
				100 * ((1 + priceImpact.from / 100) * (1 - priceImpact.to / 100) - 1);
			if (fromIsStableTokenRef) {
				priceImpact.from = 0;
				priceImpact.to = -cummulated;
			} else {
				priceImpact.from = cummulated;
				priceImpact.to = 0;
			}
		}
	}
	if (priceImpact.from > MAX_PRICE_IMPACT) {
		priceImpact.from = 'infinite';
	}
	if (priceImpact.to > MAX_PRICE_IMPACT) {
		priceImpact.to = 'infinite';
	}

	// for some reason, we failed to calculate the exact priceimpact on trades between DAI and non-coin:
	priceImpact.notExactFrom =
		!fromIsBaseToken && !toIsBaseToken && toIsStableTokenRef;
	priceImpact.notExactTo =
		!fromIsBaseToken && !toIsBaseToken && fromIsStableTokenRef;

	if (priceImpact.from > -0.005 && priceImpact.from < 0) {
		priceImpact.from = 0;
	} else if (priceImpact.from < 0.005 && priceImpact.from > 0) {
		priceImpact.from = 0;
	}
	if (priceImpact.to > -0.005 && priceImpact.to < 0) {
		priceImpact.to = 0;
	} else if (priceImpact.to < 0.005 && priceImpact.to > 0) {
		priceImpact.to = 0;
	}
	return { amountInMax, priceImpact, tradeSteps: route.path.length - 1 };
};

export const sellTokens = async (
	chainId,
	owner,
	baseTradeToken,
	tokenToSell,
	tokenToBuy,
	amountToSell,
	slippage = 0.5,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (isBN(amountToSell)) {
		amountToSell = amountToSell.toString();
	}
	if (amountToSell.toString() === '0') {
		throw new TradeException(errorMessages.ZERO_AMOUNT);
	}
	const { Trade, TokenAmount, TradeType, Percent } = getSDK(chainId);
	const routerContract = getRouterContract(chainId);
	const tokenSell = await getToken(chainId, tokenToSell);
	const tokenBuy = await getToken(chainId, tokenToBuy);
	const { path, route } = await getPathAndRoute(
		chainId,
		baseTradeToken,
		tokenSell,
		tokenBuy,
		amountToSell,
		null,
	);

	let trade;
	try {
		trade = new Trade(
			route,
			new TokenAmount(tokenSell, amountToSell),
			TradeType.EXACT_INPUT,
		);
	} catch (err) {
		return '';
	}
	const slippageTolerance = new Percent(
		Math.round(slippage * 100).toString(),
		TEN_THOUSAND,
	); // 50 bips, or 0.50%
	const deadline = toHex(
		Math.floor(Date.now() / 1000) + 60 * TRADES_DEADLINES_MINS[chainId],
	); // minutes from the current Unix time
	const amountIn = toHex(amountToSell);
	const amountOutMin = toHex(
		noLeadingZeros(
			trade
				.minimumAmountOut(slippageTolerance)
				.toFixed(tokenBuy.decimals)
				.replace(REGEXP_ONLY_NUMBERS, ''),
		),
	);

	const transactionData = routerContract.methods[
		ROUTER_METHODS.swapExactTokensForTokens[chainId]
	](amountIn, amountOutMin, path, owner, deadline).encodeABI();

	const rawTx = {
		to: ROUTER_ADDRESSES[chainId],
		data: transactionData,
		from: owner,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !isAddress(rawTx.to)) {
		throw new TradeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	let gasLimitBN =
		BN_GAS_LIMIT_UNIT_PRICES.swapexacttokensfortokens[chainId].onestep;
	const stradingSteps = (route?.path?.length || 1) - 1 || 1;
	for (let i = 1; i < stradingSteps; i += 1) {
		gasLimitBN = gasLimitBN.add(
			BN_GAS_LIMIT_UNIT_PRICES.swapexacttokensfortokens[chainId].extrastep,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		gasLimitBN,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const buyTokens = async (
	chainId,
	owner,
	baseTradeToken,
	tokenToSell,
	tokenToBuy,
	amountToBuy,
	slippage = 0.5,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (isBN(amountToBuy)) {
		amountToBuy = amountToBuy.toString();
	}
	if (amountToBuy.toString() === '0') {
		throw new TradeException(errorMessages.ZERO_AMOUNT);
	}
	const { Trade, TokenAmount, TradeType, Percent } = getSDK(chainId);
	const routerContract = getRouterContract(chainId);
	const tokenSell = await getToken(chainId, tokenToSell);
	const tokenBuy = await getToken(chainId, tokenToBuy);
	const { path, route } = await getPathAndRoute(
		chainId,
		baseTradeToken,
		tokenSell,
		tokenBuy,
		null,
		amountToBuy,
	);

	let trade;
	try {
		trade = new Trade(
			route,
			new TokenAmount(tokenBuy, amountToBuy),
			TradeType.EXACT_OUTPUT,
		);
	} catch (err) {
		return '';
	}

	const slippageTolerance = new Percent(
		Math.round(slippage * 100).toString(),
		TEN_THOUSAND,
	); // 50 bips, or 0.50%
	const deadline =
		Math.floor(Date.now() / 1000) + 60 * TRADES_DEADLINES_MINS[chainId]; // minutes from the current Unix time

	const amountOut = toHex(amountToBuy);
	const amountInMax = toHex(
		noLeadingZeros(
			trade
				.maximumAmountIn(slippageTolerance)
				.toFixed(tokenSell.decimals)
				.replace(REGEXP_ONLY_NUMBERS, ''),
		),
	);

	const transactionData = routerContract.methods[
		ROUTER_METHODS.swapTokensForExactTokens[chainId]
	](amountOut, amountInMax, path, owner, deadline).encodeABI();

	const rawTx = {
		to: ROUTER_ADDRESSES[chainId],
		data: transactionData,
		from: owner,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !isAddress(rawTx.to)) {
		throw new TradeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	let gasLimitBN =
		BN_GAS_LIMIT_UNIT_PRICES.swaptokensforexacttokens[chainId].onestep;
	const stradingSteps = (route?.path?.length || 1) - 1 || 1;
	for (let i = 1; i < stradingSteps; i += 1) {
		gasLimitBN = gasLimitBN.add(
			BN_GAS_LIMIT_UNIT_PRICES.swapexacttokensfortokens[chainId].extrastep,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		gasLimitBN,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });

	// Metamask transaction: return tx as a String:
	return tx;
};

export const sellTokensForCoins = async (
	chainId,
	owner,
	tokenToSell,
	baseTradeToken,
	amountToSell,
	slippage,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (isBN(amountToSell)) {
		amountToSell = amountToSell.toString();
	}
	if (amountToSell.toString() === '0') {
		throw new TradeException(errorMessages.ZERO_AMOUNT);
	}
	const { Trade, TokenAmount, TradeType, Percent } = getSDK(chainId);
	const routerContract = getRouterContract(chainId);
	const tokenSell = await getToken(chainId, tokenToSell);
	const tokenBuy = await getToken(chainId, baseTradeToken);
	const { path, route } = await getPathAndRoute(
		chainId,
		baseTradeToken,
		tokenSell,
		tokenBuy,
		amountToSell,
		null,
	);

	let trade;
	try {
		trade = new Trade(
			route,
			new TokenAmount(tokenSell, amountToSell),
			TradeType.EXACT_INPUT,
		);
	} catch (err) {
		return '';
	}

	const slippageTolerance = new Percent(
		Math.round(slippage * 100).toString(),
		TEN_THOUSAND,
	); // 50 bips, or 0.50%
	const deadline =
		Math.floor(Date.now() / 1000) + 60 * TRADES_DEADLINES_MINS[chainId]; // minutes from the current Unix time

	const amountIn = toHex(amountToSell);
	const amountOutMin = toHex(
		noLeadingZeros(
			trade
				.minimumAmountOut(slippageTolerance)
				.toFixed(tokenBuy.decimals)
				.replace(REGEXP_ONLY_NUMBERS, ''),
		),
	);

	const transactionData = routerContract.methods[
		ROUTER_METHODS.swapExactTokensForETH[chainId]
	](amountIn, amountOutMin, path, owner, deadline).encodeABI();

	const rawTx = {
		to: ROUTER_ADDRESSES[chainId],
		data: transactionData,
		from: owner,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !isAddress(rawTx.to)) {
		throw new TradeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	let gasLimitBN =
		BN_GAS_LIMIT_UNIT_PRICES.swapexacttokensforcoin[chainId].onestep;
	const stradingSteps = (route?.path?.length || 1) - 1 || 1;
	for (let i = 1; i < stradingSteps; i += 1) {
		gasLimitBN = gasLimitBN.add(
			BN_GAS_LIMIT_UNIT_PRICES.swapexacttokensforcoin[chainId].extrastep,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		gasLimitBN,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const buyTokensFromCoin = async (
	chainId,
	owner,
	baseTradeToken,
	tokenToBuy,
	amountToBuy,
	slippage,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (isBN(amountToBuy)) {
		amountToBuy = amountToBuy.toString();
	}
	if (amountToBuy.toString() === '0') {
		throw new TradeException(errorMessages.ZERO_AMOUNT);
	}
	const { Trade, TokenAmount, TradeType, Percent } = getSDK(chainId);
	const routerContract = getRouterContract(chainId);
	const tokenBuy = await getToken(chainId, tokenToBuy);
	const tokenSell = await getToken(chainId, baseTradeToken);
	const { path, route } = await getPathAndRoute(
		chainId,
		baseTradeToken,
		tokenSell,
		tokenBuy,
		null,
		amountToBuy,
	);

	let trade;
	try {
		trade = new Trade(
			route,
			new TokenAmount(tokenBuy, amountToBuy),
			TradeType.EXACT_OUTPUT,
		);
	} catch (err) {
		return '';
	}

	const slippageTolerance = new Percent(
		Math.round(slippage * 100).toString(),
		TEN_THOUSAND,
	); // 50 bips, or 0.50%

	const deadline =
		Math.floor(Date.now() / 1000) + 60 * TRADES_DEADLINES_MINS[chainId]; // minutes from the current Unix time

	const amountOut = toHex(amountToBuy);
	const amountInMax = toHex(
		noLeadingZeros(
			trade
				.maximumAmountIn(slippageTolerance)
				.toFixed(tokenSell.decimals)
				.replace(REGEXP_ONLY_NUMBERS, ''),
		),
	);

	const transactionData = routerContract.methods[
		ROUTER_METHODS.swapETHForExactTokens[chainId]
	](amountOut, path, owner, deadline).encodeABI();

	const rawTx = {
		to: ROUTER_ADDRESSES[chainId],
		data: transactionData,
		from: owner,
		value: amountInMax,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !isAddress(rawTx.to)) {
		throw new TradeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	let gasLimitBN =
		BN_GAS_LIMIT_UNIT_PRICES.swapcoinforexacttokens[chainId].onestep;
	const stradingSteps = (route?.path?.length || 1) - 1 || 1;
	for (let i = 1; i < stradingSteps; i += 1) {
		gasLimitBN = gasLimitBN.add(
			BN_GAS_LIMIT_UNIT_PRICES.swapcoinforexacttokens[chainId].extrastep,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		gasLimitBN,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });

	// Metamask transaction: return tx as a String:
	return tx;
};

export const sellCoins = async (
	chainId,
	owner,
	baseTradeToken,
	tokenToBuy,
	amountToSell,
	slippage,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (isBN(amountToSell)) {
		amountToSell = amountToSell.toString();
	}
	if (amountToSell.toString() === '0') {
		throw new TradeException(errorMessages.ZERO_AMOUNT);
	}
	const { Trade, TokenAmount, TradeType, Percent } = getSDK(chainId);
	const routerContract = getRouterContract(chainId);
	const tokenSell = await getToken(chainId, baseTradeToken);
	const tokenBuy = await getToken(chainId, tokenToBuy);
	const { path, route } = await getPathAndRoute(
		chainId,
		baseTradeToken,
		tokenSell,
		tokenBuy,
		amountToSell,
		null,
	);

	let trade;
	try {
		trade = new Trade(
			route,
			new TokenAmount(tokenSell, amountToSell),
			TradeType.EXACT_INPUT,
		);
	} catch (err) {
		return '';
	}

	const slippageTolerance = new Percent(
		Math.round(slippage * 100).toString(),
		TEN_THOUSAND,
	); // 50 bips, or 0.50%

	const deadline =
		Math.floor(Date.now() / 1000) + 60 * TRADES_DEADLINES_MINS[chainId]; // minutes from the current Unix time

	const amountIn = toHex(amountToSell);
	const amountOutMin = toHex(
		noLeadingZeros(
			trade
				.minimumAmountOut(slippageTolerance)
				.toFixed(tokenBuy.decimals)
				.replace(REGEXP_ONLY_NUMBERS, ''),
		),
	);

	const transactionData = routerContract.methods[
		ROUTER_METHODS.swapExactETHForTokens[chainId]
	](amountOutMin, path, owner, deadline).encodeABI();

	const rawTx = {
		to: ROUTER_ADDRESSES[chainId],
		data: transactionData,
		from: owner,
		value: amountIn,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !isAddress(rawTx.to)) {
		throw new TradeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	let gasLimitBN =
		BN_GAS_LIMIT_UNIT_PRICES.swapexactcoinfortokens[chainId].onestep;
	const stradingSteps = (route?.path?.length || 1) - 1 || 1;
	for (let i = 1; i < stradingSteps; i += 1) {
		gasLimitBN = gasLimitBN.add(
			BN_GAS_LIMIT_UNIT_PRICES.swapexactcoinfortokens[chainId].extrastep,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		gasLimitBN,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const buyCoins = async (
	chainId,
	owner,
	tokenToSell,
	baseTradeToken,
	amountToBuy,
	slippage,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (isBN(amountToBuy)) {
		amountToBuy = amountToBuy.toString();
	}
	if (amountToBuy.toString() === '0') {
		throw new TradeException(errorMessages.ZERO_AMOUNT);
	}
	const { Trade, TokenAmount, TradeType, Percent } = getSDK(chainId);
	const routerContract = getRouterContract(chainId);
	const tokenSell = await getToken(chainId, tokenToSell);
	const tokenBuy = await getToken(chainId, baseTradeToken);
	const { path, route } = await getPathAndRoute(
		chainId,
		baseTradeToken,
		tokenSell,
		tokenBuy,
		null,
		amountToBuy,
	);

	let trade;
	try {
		trade = new Trade(
			route,
			new TokenAmount(tokenBuy, amountToBuy),
			TradeType.EXACT_OUTPUT,
		);
	} catch (err) {
		return '';
	}

	const slippageTolerance = new Percent(
		Math.round(slippage * 100).toString(),
		TEN_THOUSAND,
	); // 50 bips, or 0.50%

	const deadline =
		Math.floor(Date.now() / 1000) + 60 * TRADES_DEADLINES_MINS[chainId]; // minutes from the current Unix time

	const amountOut = toHex(amountToBuy);
	const amountInMax = toHex(
		noLeadingZeros(
			trade
				.maximumAmountIn(slippageTolerance)
				.toFixed(tokenSell.decimals)
				.replace(REGEXP_ONLY_NUMBERS, ''),
		),
	);

	const transactionData = routerContract.methods[
		ROUTER_METHODS.swapTokensForExactETH[chainId]
	](amountOut, amountInMax, path, owner, deadline).encodeABI();

	const rawTx = {
		to: ROUTER_ADDRESSES[chainId],
		data: transactionData,
		from: owner,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !isAddress(rawTx.to)) {
		throw new TradeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	let gasLimitBN =
		BN_GAS_LIMIT_UNIT_PRICES.swaptokensforexactcoin[chainId].onestep;
	const stradingSteps = (route?.path?.length || 1) - 1 || 1;
	for (let i = 1; i < stradingSteps; i += 1) {
		gasLimitBN = gasLimitBN.add(
			BN_GAS_LIMIT_UNIT_PRICES.swaptokensforexactcoin[chainId].extrastep,
		);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		gasLimitBN,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};
