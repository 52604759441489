import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Modal as MuiModal,
	IconButton,
	Fade,
	Backdrop as MuiBackdrop,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import QrReader from 'react-qr-reader';
import useStyles from 'styles/components/common/modals/QRCodeReaderModal';

const QRCodeReaderModal = ({ handleClose, show, callBackResult }) => {
	const classes = useStyles();
	const [qrResult, setQrResult] = useState('');

	useEffect(() => {
		setQrResult('');
	}, [show]);

	const handleQrReaderScan = data => {
		if (data && typeof data === 'string') {
			const res = data.replace('ethereum:', '');
			if (callBackResult) {
				callBackResult(res);
			}
			setQrResult(res);
		}
	};
	const handleQrReaderError = err => {
		// eslint-disable-next-line no-console
		console.error(err);
	};

	const qrCodeReader = (
		<Box className={classes.qrReaderWrapper}>
			<QrReader
				className={classes.qrReader}
				delay={300}
				onError={handleQrReaderError}
				onScan={handleQrReaderScan}
			/>
			<p className={classes.qrReaderText}>
				Place the QR code in front of your camera
			</p>
		</Box>
	);

	const closeButton = (
		<IconButton
			className={classes.closeButton}
			color="primary"
			onClick={handleClose}
		>
			<CloseIcon color="primary" />
		</IconButton>
	);

	const header = (
		<header className={classes.header}>
			<h2 className={classes.title}>Scan QR Code</h2>
		</header>
	);

	return (
		<MuiModal
			open={show}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={MuiBackdrop}
			BackdropProps={{
				className: classes.backdrop,
			}}
		>
			<Fade
				timeout={{
					enter: 500,
					exit: 300,
				}}
				in={show}
			>
				<Box className={classes.container}>
					{closeButton}
					{header}
					<div className={classes.body}>
						{qrCodeReader}
						<p className={classes.qrReaderResult}>{qrResult}</p>
					</div>
				</Box>
			</Fade>
		</MuiModal>
	);
};

QRCodeReaderModal.defaultProps = {
	show: false,
	handleClose: null,
	callBackResult: null,
};

QRCodeReaderModal.propTypes = {
	show: PropTypes.bool,
	handleClose: PropTypes.func,
	callBackResult: PropTypes.func,
};

export default QRCodeReaderModal;
