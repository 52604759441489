import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Carousel from 'nuka-carousel';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import wrappedtokenCtx from 'context/wrappedtoken';
import navigatorCtx from 'context/navigator';
import promoformCtx from 'context/promoform';
import darkmodeCtx from 'context/darkmode';
import lightTheme from 'styles/light-theme';
import darkTheme from 'styles/dark-theme';
import { Button } from '@itsa.io/ui';
import NftCard from 'components/common/NftCard';
import getRemainingDays from 'utils/get-remaining-days';
import useAlert from 'hooks/useAlert';
import {
	PAGES_NAMES,
	NETWORK_NAMES,
	GENERAL_MESSAGE_TIMEOUT,
	SHOW_MAX_UNIQUE_NFTS,
} from 'config/constants';
import useStyles from 'styles/components/settings/SubscriptionInfo';

const SubscriptionInfo = ({ subscription }) => {
	const classes = useStyles();
	const { darkmode } = useContext(darkmodeCtx);
	const currentTheme = darkmode ? darkTheme : lightTheme;
	const { setPage } = useContext(navigatorCtx);
	const { open: promoformOpen } = useContext(promoformCtx);
	const wrappedtoken = useContext(wrappedtokenCtx);
	const { chainId } = useContext(cryptowalletCtx);
	const { t } = useIntl();
	const alert = useAlert();
	const {
		hasTrial,
		hasFullAccess,
		isSubscribed,
		expiration,
		trialExpiration,
		hasFreeSubscription,
		subscriptionChain,
		nftIds,
		hasNftSubscription,
		chainSubscriptions,
		hasValidBindToSubscription,
	} = subscription;
	const isExpired = !!subscriptionChain;
	const exprirationDate =
		isExpired &&
		(isSubscribed || hasValidBindToSubscription ? expiration : trialExpiration);
	const hasSilverNFT = !!nftIds.silver.length;
	const hasGoldNFT = !!nftIds.gold.length;
	const hasPlatinaNFT = !!nftIds.platina.length;
	const hasNfts = hasSilverNFT || hasGoldNFT || hasPlatinaNFT;
	let subscriptionContent;
	let buttonContent;
	let buttonContent2;
	const defaultControlsConfig = {
		nextButtonText: '›',
		prevButtonText: '‹',
		nextButtonStyle: {
			position: 'relative',
			right: -25,
			width: 25,
			fontSize: '1.85rem',
			padding: '0 0 4px 0',
			// borderRadius: 6,
			background: currentTheme.palette.background.dark,
			border: `1px solid ${currentTheme.palette.border.dark}`,
		},
		prevButtonStyle: {
			position: 'relative',
			width: 25,
			left: -25,
			fontSize: '1.85rem',
			padding: '0 0 4px 0',
			// borderRadius: 6,
			background: currentTheme.palette.background.dark,
			border: `1px solid ${currentTheme.palette.border.dark}`,
		},
		pagingDotsStyle: {
			position: 'relative',
			fill: currentTheme.palette.primary.main,
			top: 30,
		},
	};

	const gotoSubscriptionPage = () => {
		if (!subscriptionChain || chainId === subscriptionChain) {
			setPage(PAGES_NAMES.SUBSCRIPTIONADDRESSES);
		} else {
			alert(
				t('page.settingspage.please_switch_to_network', {
					values: {
						network: NETWORK_NAMES[subscriptionChain],
					},
				}),
				{
					severity: 'info',
					timeout: GENERAL_MESSAGE_TIMEOUT,
				},
			);
		}
	};

	const gotoBindToSubscriptionPage = () => {
		if (!subscriptionChain || chainId === subscriptionChain) {
			setPage(PAGES_NAMES.BINDTOSUBSCRIPTION);
		} else {
			alert(
				t('page.settingspage.please_switch_to_network', {
					values: {
						network: NETWORK_NAMES[subscriptionChain],
					},
				}),
				{
					severity: 'info',
					timeout: GENERAL_MESSAGE_TIMEOUT,
				},
			);
		}
	};

	const updateSubscriptionButton = () => {
		if (hasFullAccess) {
			setPage(PAGES_NAMES.SUBSCRIPTION);
		} else {
			promoformOpen(chainId);
		}
	};

	if (hasNfts) {
		buttonContent = [];
		const keys = Object.keys(chainSubscriptions);
		keys.forEach(chainid => {
			const chainIdInteger = parseInt(chainid, 10);
			const chainNftIds = chainSubscriptions[chainid]?.nftIds;
			if (chainNftIds) {
				chainNftIds.platina.forEach((nftId, i) => {
					if (i < SHOW_MAX_UNIQUE_NFTS) {
						buttonContent.push(
							<NftCard
								chainid={chainIdInteger}
								className={clsx({
									[classes.blinking]:
										wrappedtoken?.isTransferringNftOwnership[
											`${chainid}-platina-${nftId}`
										] ||
										wrappedtoken?.isChangingNftUnlockedWallets[
											`${chainid}-platina-${nftId}`
										],
								})}
								clickable
								key={`${chainid}-platina-${nftId}`}
								nftId={nftId}
								type="platina"
							/>,
						);
					}
				});
				chainNftIds.gold.forEach((nftId, i) => {
					if (i < SHOW_MAX_UNIQUE_NFTS) {
						buttonContent.push(
							<NftCard
								chainid={chainIdInteger}
								className={clsx({
									[classes.blinking]:
										wrappedtoken?.isTransferringNftOwnership[
											`${chainid}-gold-${nftId}`
										] ||
										wrappedtoken?.isChangingNftUnlockedWallets[
											`${chainid}-gold-${nftId}`
										],
								})}
								clickable
								key={`${chainid}-gold-${nftId}`}
								nftId={nftId}
								type="gold"
							/>,
						);
					}
				});
				chainNftIds.silver.forEach((nftId, i) => {
					if (i < SHOW_MAX_UNIQUE_NFTS) {
						buttonContent.push(
							<NftCard
								chainid={chainIdInteger}
								className={clsx({
									[classes.blinking]:
										wrappedtoken?.isTransferringNftOwnership[
											`${chainid}-silver-${nftId}`
										] ||
										wrappedtoken?.isChangingNftUnlockedWallets[
											`${chainid}-silver-${nftId}`
										],
								})}
								clickable
								key={`${chainid}-silver-${nftId}`}
								nftId={nftId}
								type="silver"
							/>,
						);
					}
				});
			}
		});
	} else if (hasNftSubscription) {
		buttonContent = t('page.settingspage.nft_attached_subscription');
	} else if (hasFreeSubscription) {
		buttonContent = t('page.settingspage.free_subscription');
	} else {
		if (!hasValidBindToSubscription) {
			buttonContent = (
				<Button
					className={clsx(classes.upgradeButton, {
						[classes.busy]: wrappedtoken?.isGoingToGetFullAccess,
					})}
					disabled={!!wrappedtoken?.isGoingToGetFullAccess}
					variant="contained"
					color="primary"
					onClick={updateSubscriptionButton}
					disableElevation
				>
					{t(
						isExpired
							? 'page.settingspage.subscription_upgrade'
							: 'page.settingspage.subscription_choose',
					)}
				</Button>
			);
		}

		if (isSubscribed) {
			buttonContent2 = (
				<Button
					className={clsx(classes.upgradeButton, {
						[classes.busy]: wrappedtoken?.isChangingBoundWalletList,
					})}
					variant="contained"
					color="primary"
					onClick={gotoSubscriptionPage}
					disableElevation
				>
					{t('page.settingspage.unlock_extra_wallets')}
				</Button>
			);
		} else {
			buttonContent2 = (
				<Button
					className={clsx(classes.upgradeButton, {
						[classes.busy]: wrappedtoken?.isChangingBoundSubscription,
					})}
					variant="contained"
					color="primary"
					onClick={gotoBindToSubscriptionPage}
					disableElevation
				>
					{t(
						hasValidBindToSubscription
							? 'page.settingspage.manage_bound_subscription'
							: 'page.settingspage.bind_to_subscription',
					)}
				</Button>
			);
		}
	}

	if (
		isExpired &&
		!hasSilverNFT &&
		!hasGoldNFT &&
		!hasPlatinaNFT &&
		!hasNftSubscription
	) {
		let msg;
		if (exprirationDate) {
			if (hasValidBindToSubscription) {
				msg = 'page.settingspage.bound_subscription_expire';
			} else if (hasTrial) {
				msg = 'page.settingspage.trial_expire';
			} else {
				msg = 'page.settingspage.subscription_expire';
			}
		} else {
			msg = 'page.settingspage.trial_has_expired';
		}
		subscriptionContent = (
			<>
				{t(msg)}{' '}
				<span className={classes.expireText}>
					{exprirationDate && getRemainingDays(t, exprirationDate)}
				</span>
				{buttonContent}
				{buttonContent2}
			</>
		);
	} else {
		subscriptionContent = (
			<div className={classes.root}>
				<div className={clsx(classes.btnContainer, classes.columnDirection)}>
					{buttonContent}
					{buttonContent2}
				</div>
			</div>
		);
	}

	if (hasNfts) {
		subscriptionContent = (
			<Carousel
				className={classes.carousel}
				cellAlign="left"
				slidesToShow={2.2}
				// heightMode="max"
				// cellSpacing={24}
				renderBottomCenterControls={null}
				defaultControlsConfig={defaultControlsConfig}
			>
				{buttonContent}
				{buttonContent2}
			</Carousel>
		);
	}

	return subscriptionContent;
};

SubscriptionInfo.defaultProps = {};

SubscriptionInfo.propTypes = {
	subscription: PropTypes.object.isRequired,
};

export default SubscriptionInfo;
