import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	Button,
	ListItem,
	IconButton,
	Avatar as TokenImg,
	Backdrop as MuiBackdrop,
	CircularProgress,
} from '@itsa.io/ui';
import {
	ArrowDropDown as ArrowDropDownIcon,
	Close as CloseIcon,
} from '@material-ui/icons';
import { formatBN } from '@itsa.io/web3utils';
import { getMainName, getMainSymbol } from 'config/constants';

import useStyles from 'styles/components/common/SelectNativeOrWrapped';

const SelectNativeOrWrapped = ({
	callBackResult,
	className,
	wrappedToken,
	coinSelected,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [selectedToken, setSelectedToken] = useState();
	const tokenList = [wrappedToken, wrappedToken];

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSelectClick = index => {
		const isCoin = index === 0;
		if (callBackResult) {
			callBackResult(isCoin);
		}
		setSelectedToken(index);
		handleClose();
	};

	const tokenListContent = tokenList.map((token, index) => {
		let name;
		let symbol;
		let balance;
		if (index === 0) {
			name = getMainName(token.name, token.symbol);
			symbol = getMainSymbol(token.symbol);
			balance = token.coinBalance;
		} else {
			name = token.name;
			symbol = token.symbol;
			balance = token.balance;
		}
		const icon = token.image;

		const balanceProgressIndicator = balance ? (
			formatBN(balance, {
				assetDigits: token.decimals,
			})
		) : (
			<CircularProgress className={classes.circularProgress} size={20} />
		);

		return (
			<ListItem
				button
				className={classes.item}
				selected={selectedToken === index}
				onClick={() => handleSelectClick(index)}
				key={index === 0 ? 'COIN' : token.address}
			>
				<TokenImg className={classes.tokenImg} alt={name} src={icon}>
					<TokenImg className={classes.tokenImgUnknown} alt={name} src={icon}>
						?
					</TokenImg>
				</TokenImg>
				<Box className={classes.itemSymbol}>
					{symbol}
					<Box className={classes.itemName}>{name}</Box>
				</Box>
				<Box className={classes.itemAmount}>{balanceProgressIndicator}</Box>
			</ListItem>
		);
	});

	const { image } = wrappedToken;
	const name = coinSelected
		? getMainName(wrappedToken.name, wrappedToken.symbol)
		: wrappedToken.name;
	const symbol = coinSelected
		? getMainSymbol(wrappedToken.symbol)
		: wrappedToken.symbol;

	const toTokenBox = (
		<Box component="span" display="flex" alignItems="center">
			<img
				className={classes.selectedItemIcon}
				src={image}
				alt={getMainName(name, symbol)}
				width={24}
				height={24}
			/>
			<Box className={classes.selectedItemSymbol}>{symbol}</Box>
		</Box>
	);

	const buttonContent = (
		<Button
			className={clsx(classes.button, className)}
			size="small"
			variant="contained"
			color="secondary"
			onClick={handleOpen}
			disableElevation
		>
			{name ? (
				<Box component="span" display="flex" alignItems="center">
					<img
						className={classes.buttonIcon}
						src={image}
						alt={name}
						width={24}
						height={24}
					/>
					<Box className={classes.selectedItemSymbol}>{symbol}</Box>
					<ArrowDropDownIcon className={classes.buttonArrowDown} />
				</Box>
			) : (
				<Box>{toTokenBox}</Box>
			)}
		</Button>
	);

	const listContent = (
		<div
			className={clsx(classes.root, {
				[classes.hidden]: !open,
			})}
		>
			<div className={classes.wrapper}>
				<div className={classes.headerWrap}>
					<div className={classes.header}>
						<IconButton onClick={handleClose} className={classes.closeIcon}>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<div className={classes.tokenListContainer}>{tokenListContent}</div>
			</div>
		</div>
	);

	const backdropContent = (
		<MuiBackdrop
			className={classes.backdrop}
			open={open}
			onClick={handleClose}
		/>
	);

	return (
		<>
			{backdropContent}
			{buttonContent}
			{listContent}
		</>
	);
};

SelectNativeOrWrapped.defaultProps = {
	className: null,
};

SelectNativeOrWrapped.propTypes = {
	className: PropTypes.string,
	callBackResult: PropTypes.func.isRequired,
	wrappedToken: PropTypes.object.isRequired,
	coinSelected: PropTypes.bool.isRequired,
};

export default SelectNativeOrWrapped;
