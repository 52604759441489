/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import navigatorCtx from 'context/navigator';
import gaspriceCtx from 'context/gasprice';
import {
	Box,
	Button,
	IconButton,
	TextField as MuiTextField,
	InputAdornment,
} from '@material-ui/core';

import {
	ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
	RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@itsa.io/ui/icons';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import blockHeightCtx from 'context/blockheight';
import subscriptionCtx from 'context/subscription';
import useAlert from 'hooks/useAlert';
import { PAGES_NAMES, GAS_PERCENTAGES } from 'config/constants';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import wrappedtokenCtx from 'context/wrappedtoken';
import securedsendtxCtx from 'context/securedsendtx';
import GasSlider from 'components/common/GasSlider';
import { utils } from 'web3';
import addressMatch from 'utils/address-match';
import {
	bindToSubscription,
	removeBoundSubscription,
	getMasterSubscription,
} from 'utils/smartcontracts/itsa-subscription';

import useStyles from 'styles/pages/BindToSubscriptionPage';

const { isAddress } = utils;

const REGEXP_ADDRESS_TYPING = /^0x[0-9a-fA-F]*$/;

const BindToSubscriptionPage = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const { chainId, address, hardwareWallet } = useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const alert = useAlert();
	const { gasprice } = useContext(gaspriceCtx);
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const { setPage } = useContext(navigatorCtx);
	const wrappedtoken = useContext(wrappedtokenCtx);
	const [subscribedWallet, setSubscribedWallet] = useState('');
	const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
	const subscription = useContext(subscriptionCtx);
	const { hasValidBindToSubscription } = subscription;

	const [boundToSubscription, setBoundToSubscription] = useState('');
	const isMounted = useRef(false);
	const { blockheight } = useContext(blockHeightCtx);
	const validSubscribedWallet = utils.isAddress(subscribedWallet);

	let helperSubscribedWalletText;

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	const getInitialBoundAddress = async () => {
		const boundAddress = await getMasterSubscription(chainId, address);
		if (isMounted.current && isAddress(boundAddress)) {
			setBoundToSubscription(boundAddress);
			setSubscribedWallet(boundAddress);
		}
	};

	const checkBoundAddress = async () => {
		let boundAddress = await getMasterSubscription(chainId, address);
		if (!boundAddress) {
			boundAddress = '';
		}
		if (isMounted.current && !addressMatch(boundToSubscription, boundAddress)) {
			setBoundToSubscription(boundAddress);
			setSubscribedWallet(boundAddress);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		getInitialBoundAddress();

		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		checkBoundAddress();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, address, blockheight]);

	const handleAbort = () => {
		setPage(PAGES_NAMES.SETTINGS);
	};

	const handleChangeSubscribedWallet = e => {
		const value = e.target.value.trim();
		if (value === '' || value === '0' || REGEXP_ADDRESS_TYPING.test(value)) {
			setSubscribedWallet(value);
		}
	};

	const handleRemoveBoundSubscription = () => {
		if (isAddress(subscribedWallet)) {
			removeBoundSubscription(
				chainId,
				address,
				sendTx,
				gasprice,
				getExtraPercentageGas(),
				hardwareWallet,
			);
		}
	};

	const targetPermitted =
		!addressMatch(subscribedWallet, address) && isAddress(address);
	if (!targetPermitted) {
		helperSubscribedWalletText = t(
			'page.bindtosubscriptionpage.cannot_be_this_wallet_address',
		);
	}

	const textFieldSubscribedWallet = (
		<MuiTextField
			id="subscribedWallet"
			className={classes.textFieldSubscribedWallet}
			label={t('page.bindtosubscriptionpage.subscribed_wallet')}
			placeholder="0x..."
			variant="filled"
			value={subscribedWallet}
			onChange={handleChangeSubscribedWallet}
			fullWidth
			error={!targetPermitted}
			helperText={helperSubscribedWalletText}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							className={clsx(classes.iconButton, {
								[classes.iconButtonDisabled]: !boundToSubscription,
							})}
							color="primary"
							disabled={!boundToSubscription}
							onClick={handleRemoveBoundSubscription}
						>
							<RemoveCircleOutlineIcon color="primary" />
						</IconButton>
					</InputAdornment>
				),
				disableUnderline: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);

	const handleBindSubscription = async () => {
		setSendButtonEnabled(false);
		try {
			await bindToSubscription(
				chainId,
				subscribedWallet,
				address,
				sendTx,
				gasprice,
				getExtraPercentageGas(),
				hardwareWallet,
			);
		} catch (err) {
			alert(err.message, 'error');
		}
		if (isMounted.current) {
			setSendButtonEnabled(true);
		}
	};

	const bindToSubscriptionButton = (
		<Box
			className={clsx(classes.buttonHorizontalGroup, classes.buttonFirstRow)}
		>
			<Button
				disabled={!sendButtonEnabled}
				variant="outlined"
				size="large"
				color="primary"
				onClick={handleAbort}
			>
				{t('page.bindtosubscriptionpage.cancel')}
			</Button>
			<Button
				className={clsx({
					[classes.busy]: wrappedtoken?.isChangingBoundSubscription,
				})}
				disabled={
					!validSubscribedWallet ||
					!targetPermitted ||
					!sendButtonEnabled ||
					addressMatch(subscribedWallet, boundToSubscription) ||
					wrappedtoken?.isChangingBoundSubscription
				}
				onClick={handleBindSubscription}
				variant="outlined"
				size="large"
				color="primary"
			>
				{t(
					wrappedtoken?.isChangingBoundSubscription
						? 'page.bindtosubscriptionpage.unlocking_wallet'
						: 'page.bindtosubscriptionpage.unlock_wallet',
				)}
			</Button>
		</Box>
	);

	const title = (
		<Box className={classes.subscribedWalletContainer}>
			{t('page.bindtosubscriptionpage.title')}
		</Box>
	);

	const description = (
		<Box className={classes.description}>
			{t('page.bindtosubscriptionpage.description')}
		</Box>
	);

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	let warningBoundAddressText;
	if (!hasValidBindToSubscription && subscribedWallet) {
		warningBoundAddressText = (
			<div className={classes.sendWarningDescription}>
				<ErrorOutlineOutlinedIcon className={classes.warningIcon} />
				<div>{t('page.bindtosubscriptionpage.no_valid_bound')}</div>
			</div>
		);
	}

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider value={gaspriceIndex} onChange={changeGaspriceIndex} />
		);
	}

	return (
		<Box
			className={classes.root}
			autoComplete="off"
			component="form"
			noValidate
		>
			{title}
			{description}
			{textFieldSubscribedWallet}
			{gaspriceSlider}
			{warningBoundAddressText}
			{bindToSubscriptionButton}
		</Box>
	);
};

export default BindToSubscriptionPage;
