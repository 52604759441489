import { formatBN, toBN } from '@itsa.io/web3utils';
import addressMatch from 'utils/address-match';
import { BN_ZERO } from 'config/constants';

const TRANSFER_TYPES = {
	cointransfer: true,
	tokentransfer: true,
	itsaNftTransfer: true,
};

const HIDDEN_ADDRESSES = {
	removeliquidity: true,
	addliquidity: true,
	tokenwrap: true,
	tokenunwrap: true,
	tokenapproval: true,
	energiMasternodeAnnounce: true,
	energiMasternodeDenounce: true,
	energiMasternodeDeposit: true,
	energiMasternodeWithdraw: true,
	itsaSubscriptionSubscription: true,
	itsaSubscriptionTrial: true,
	itsaNftAddressSubscription: true,
	itsaNftAddressUnsubscription: true,
	itsaNftAllAddressesSubscription: true,
	itsaNftSubscriptionsReset: true,
	itsaSubscriptionRemoveBoundSubscription: true,
	itsaSubscriptionRemoveBoundSubscriptionApproval: true,
	itsaSubscriptionApproveBoundSubscription: true,
	itsaSubscriptionSetApprovedMultipleBoundSubscriptions: true,
};

const HIDDEN_FIRST_SYMBOLS = {
	tokenapproval: true,
	itsaSubscriptionBindToSubscription: true,
	itsaSubscriptionRemoveBoundSubscriptionApproval: true,
	itsaSubscriptionApproveBoundSubscription: true,
	itsaSubscriptionSetApprovedMultipleBoundSubscriptions: true,
	itsaSubscriptionRemoveBoundSubscription: true,
};

const HIDDEN_AMOUNTS = {
	tokenapproval: true,
	itsaNftTransfer: true,
	itsaNftAddressSubscription: true,
	itsaNftAddressUnsubscription: true,
	itsaNftAllAddressesSubscription: true,
	itsaNftSubscriptionsReset: true,
	itsaSubscriptionBindToSubscription: true,
	itsaSubscriptionRemoveBoundSubscriptionApproval: true,
	itsaSubscriptionApproveBoundSubscription: true,
	itsaSubscriptionSetApprovedMultipleBoundSubscriptions: true,
	itsaSubscriptionRemoveBoundSubscription: true,
};

const TYPES = {
	cointransfer: 'token_transfer',
	tokentransfer: 'transfer',
	tokenwrap: 'wrap',
	tokenunwrap: 'unwrap',
	tokenapproval: 'token_approval',
	removeliquidity: 'remove_liquidity',
	addliquidity: 'add_liquidity',
	energiMasternodeDeposit: 'masternode_deposit',
	energiMasternodeWithdraw: 'masternode_withdraw',
	energiMasternodeAnnounce: 'masternode_announce',
	energiMasternodeDenounce: 'masternode_denounce',
	itsaSubscriptionSubscription: 'subscription',
	itsaSubscriptionTrial: 'trial_subscription',
	itsaSubscriptionBindToSubscription: 'bind_to_subscription',
	itsaSubscriptionRemoveBoundSubscription: 'unbind_from_subscription',
	itsaSubscriptionRemoveBoundSubscriptionApproval: 'bound_subscriptions_change',
	itsaSubscriptionApproveBoundSubscription: 'bound_subscriptions_change',
	itsaSubscriptionSetApprovedMultipleBoundSubscriptions:
		'bound_subscriptions_change',
	itsaNftTransfer: 'nft_transfer',
	itsaNftAddressSubscription: 'extra_wallets_change',
	itsaNftAddressUnsubscription: 'extra_wallets_change',
	itsaNftAllAddressesSubscription: 'extra_wallets_change',
	itsaNftSubscriptionsReset: 'extra_wallets_change',
};

const transformTransaction = (
	transaction,
	pending,
	walletaddress,
	selectedToken,
	wrappedToken,
	t,
) => {
	const { data } = transaction;
	const { type, nftType, tokenId, nftId, estimated } = data;
	//------------------------------------------------------------
	let address;
	//------------------------------------------------------------
	let decimals = 4;
	let amount;
	let description;
	let tokenfrom;
	let tokento;
	let nftSent;
	let estimatedAmount = false;
	let negativeAmount = false;
	let otherSymbol;
	const showFirstSymbol = !HIDDEN_FIRST_SYMBOLS[type];
	if (!HIDDEN_ADDRESSES[type]) {
		address = data.to || transaction.addressto;
	}
	if (TRANSFER_TYPES[type]) {
		const incoming = addressMatch(transaction.addressto, walletaddress);
		description = incoming ? 'from' : 'to';
		negativeAmount = !incoming;
	} else if (type === 'tokenswap') {
		tokenfrom = data.tokenfrom;
		tokento = data.tokento;
		if (addressMatch(data.from, data.to)) {
			description = 'token_swapping';
			address = null;
		} else if (addressMatch(walletaddress, data.to)) {
			description = 'token_swap_to';
		} else {
			description = 'token_swap_from';
			address = data.from;
		}
		if (addressMatch(tokenfrom, selectedToken?.address)) {
			amount = data.amount;
			negativeAmount = true;
		} else if (addressMatch(tokento, selectedToken?.address)) {
			amount = data.amountto;
		} else {
			amount = tokenfrom === 'coin' ? data.amount : data.amountto;
			negativeAmount = tokenfrom === 'coin';
		}
		const tokenfromAddress =
			data.tokenfrom === 'coin' ? wrappedToken?.address : data.tokenfrom;
		const tokentoAddress =
			data.tokento === 'coin' ? wrappedToken?.address : data.tokento;
		estimatedAmount =
			(addressMatch(tokenfromAddress, selectedToken?.address) &&
				estimated === 'from') ||
			(addressMatch(tokentoAddress, selectedToken?.address) &&
				estimated === 'to');
	} else {
		description = TYPES[type] || type;
	}
	//------------------------------------------------------------
	if (type === 'tokenwrap') {
		negativeAmount = true;
	}
	//------------------------------------------------------------
	if (type === 'energiMasternodeAnnounce') {
		otherSymbol = 'MNRG';
		amount = selectedToken.collateral || BN_ZERO;
		decimals = 0;
	}
	//------------------------------------------------------------
	if (type === 'energiMasternodeDenounce') {
		negativeAmount = true;
		otherSymbol = 'MNRG';
		amount = selectedToken.collateral || BN_ZERO;
		decimals = 0;
	}
	//------------------------------------------------------------
	if (type === 'energiMasternodeWithdraw') {
		decimals = 0;
	}
	//------------------------------------------------------------
	if (type === 'energiMasternodeDeposit') {
		decimals = 0;
		negativeAmount = true;
	}
	//------------------------------------------------------------
	if (type === 'itsaNftTransfer') {
		const capitalizedNft = nftType[0].toUpperCase() + nftType.substring(1);
		otherSymbol = `Wallet ${capitalizedNft} NFT - ${tokenId}`;
		nftSent = !addressMatch(walletaddress, address);
		if (nftSent) {
			address = data.to;
			description = 'to';
		} else {
			address = data.from;
			description = 'from';
		}
	}
	//------------------------------------------------------------
	if (
		type === 'itsaNftAddressSubscription' ||
		type === 'itsaNftAddressUnsubscription' ||
		type === 'itsaNftAllAddressesSubscription' ||
		type === 'itsaNftSubscriptionsReset'
	) {
		const capitalizedNft = nftType[0].toUpperCase() + nftType.substring(1);
		otherSymbol = `Wallet ${capitalizedNft} NFT - ${nftId}`;
	}
	//------------------------------------------------------------
	if (type === 'itsaSubscriptionBindToSubscription') {
		address = data.subscriptionAddress;
	}
	//------------------------------------------------------------

	if (!amount && !HIDDEN_AMOUNTS[type]) {
		amount = data.amount || data.amountA;
	}
	//------------------------------------------------------------
	if (amount) {
		if (negativeAmount) {
			amount = amount.mul(toBN('-1'));
		}
		amount = formatBN(amount, {
			assetDigits: selectedToken?.decimals,
			decimals,
			formatHTML: true,
		});
	}
	pending = true;

	return {
		updated: transaction.time,
		type,
		tokenfrom,
		tokento,
		tokenA: data.tokenA,
		tokenB: data.tokenB,
		amount,
		otherSymbol,
		estimatedAmount,
		description: t(`transactions.${description}`),
		address,
		showFirstSymbol,
		nftSent,
		status: t(`transactions.${pending ? 'pending' : 'completed'}`),
		txHash: transaction.transactionhash,
	};
};

export default transformTransaction;
