import React, { useContext, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import selectedtokenCtx from 'context/selectedtoken';
import wrappedtokenCtx from 'context/wrappedtoken';
import navigatorCtx from 'context/navigator';
import gaspriceCtx from 'context/gasprice';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import securedsendtxCtx from 'context/securedsendtx';
import GasSlider from 'components/common/GasSlider';
import {
	Box,
	Button,
	TextField as MuiTextField,
	Avatar as TokenImg,
} from '@material-ui/core';
import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from '@itsa.io/ui/icons';
import {
	useIntl,
	formatBN,
	toBN,
	cryptowalletCtx,
	localStorageProperty,
} from '@itsa.io/web3utils';
import useAlert from 'hooks/useAlert';
import {
	PAGES_NAMES,
	NETWORK_NAMES,
	CHAIN_SYMBOLS,
	getMainName,
	BN_ZERO,
	BN_GAS_ENERGI_COLLATERIZATION,
	GAS_PERCENTAGES,
} from 'config/constants';
import { announce, denounce } from 'utils/smartcontracts/energi-registry';
import getNumberSeparators from 'utils/get-number-separators';
import { isValidInteger } from 'utils/validate-numeric-input';
import checkValidEnode from 'utils/valid-enode';
import { deposit, withdraw } from 'utils/smartcontracts/energi-collaterization';
import SliderSteps from 'components/common/SliderSteps';
import useStyles from 'styles/pages/CollateralPage';

const REGEXP_ONLYZEROS = /^0*$/;
const MAX_MN_COLLATERAL = 100000; // never more than 100.000 to collaterize
const SLIDER_MIN = 0;
const SLIDER_STEP = 1000;

const lsEnode = localStorageProperty('enode', {
	simpleType: true, // we use a string
	encoded: true,
});

const CollateralPage = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const { address, chainId, hardwareWallet } = useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const networkName = NETWORK_NAMES[chainId];
	const coinSymbol = CHAIN_SYMBOLS[chainId];
	const alert = useAlert();
	const { selectedToken } = useContext(selectedtokenCtx);
	const wrappedToken = useContext(wrappedtokenCtx);
	const { setPage } = useContext(navigatorCtx);
	const { gasprice } = useContext(gaspriceCtx);
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const [amount, setAmount] = useState('');
	const [enode, setEnode] = useState(lsEnode.get() || '');
	const [balance, setBalance] = useState(BN_ZERO);
	const [maxCollateral, setMaxCollateral] = useState(BN_ZERO);
	const [currentGas, setCurrentGas] = useState(BN_ZERO);
	const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
	const [validEnode, setValidEnode] = useState(false);
	const [checkEnode, setCheckEnode] = useState(false);
	const isMounted = useRef(false);
	const enodeRef = useRef();

	const { coinBalance, collateral, masternodeStatus } = selectedToken;
	const noMasternode = masternodeStatus === 0;
	const masternodeNeedsReAnnouncement = !noMasternode && masternodeStatus < 3;
	const masternodeIsAnnounced = !noMasternode;
	const isAnnouncingOrDenouncing =
		selectedToken?.isEnergiMasternodeAnnouncing ||
		selectedToken?.isEnergiMasternodeDenouncing;

	const amountWithoutDecimals = amount.replaceAll(
		getNumberSeparators.decimalSeparator,
		'',
	);

	let validAmount =
		!REGEXP_ONLYZEROS.test(amountWithoutDecimals) ||
		amountWithoutDecimals === '0';
	let enoughBalance = true;
	let notEnoughBalanceText = t('page.collateralpage.no_collateral_change');
	let warningBalanceText;
	const noCollateral =
		selectedToken?.collateral && selectedToken.collateral.toString() === '0';

	// TODO: use "masternodeStatus" to give feedback about the status of the MasterNode -> see how components/common/masternodeIndicator does this

	const [sliderValue, setSliderValue] = useState(
		parseInt(
			formatBN(collateral, {
				assetDigits: 18,
				withThousandSeparator: false,
			}),
			10,
		),
	);
	const marks = [];
	let sliderMax = parseInt(maxCollateral, 10);
	let length = sliderMax / SLIDER_STEP; // calculate the slider steps
	if (length !== 100) {
		// eslint-disable-next-line no-plusplus
		length++;
		sliderMax += 1000; // Add extra step for showing 100000 Max. Collateral
	}

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	if (validAmount) {
		const sameBalance =
			amount ===
			formatBN(collateral, { assetDigits: 18, withThousandSeparator: false });
		const amountNumber = parseInt(amount, 10);
		enoughBalance = amountNumber <= maxCollateral;
		const amountNumberRounded1000 = Math.round(amountNumber / 1000) * 1000;
		validAmount =
			!sameBalance && enoughBalance && amountNumber === amountNumberRounded1000;
		if (sameBalance) {
			notEnoughBalanceText = t('page.collateralpage.no_collateral_change');
		} else if (!enoughBalance) {
			notEnoughBalanceText = t('page.collateralpage.not_enough_balance');
		} else if (!validAmount) {
			notEnoughBalanceText = t('page.collateralpage.only_pieces_1000');
		}
	}

	const getAmountCoinsSpend = () => {
		if (!validAmount) {
			return BN_ZERO;
		}
		const amountWei = amount.padEnd(amount.length + 18, '0');
		const amountBN = toBN(amountWei);
		return amountBN.gt(collateral) ? amountBN.sub(collateral) : BN_ZERO;
	};

	if (wrappedToken) {
		const criticalBalance = wrappedToken.belowCriticalBalance(
			toBN(getAmountCoinsSpend()).add(currentGas),
			gasprice,
		);

		if (criticalBalance) {
			warningBalanceText = (
				<div className={classes.criticalBalanceDescription}>
					<ErrorOutlineOutlinedIcon className={classes.warningIcon} />
					<div>
						{t('page.collateralpage.careful_deposit_not_enough_balance', {
							values: { networkName, coinSymbol },
						})}
					</div>
				</div>
			);
		}
	}

	const calculateMaxCollateral = newBalance => {
		let amountString = formatBN(newBalance, {
			assetDigits: 18,
			withThousandSeparator: false,
		});
		const indexDecimalSeparator = amountString.indexOf(
			getNumberSeparators.decimalSeparator,
		);
		if (indexDecimalSeparator !== -1) {
			amountString = amountString.substring(0, indexDecimalSeparator);
		}
		let amount = parseInt(amountString, 10);
		amount = Math.floor(amount / 1000) * 1000;
		amount = Math.min(amount, MAX_MN_COLLATERAL);
		return amount.toString();
	};

	const defineCurrentGas = async () => {
		try {
			if (gasprice) {
				let gasBN = gasprice.mul(BN_GAS_ENERGI_COLLATERIZATION.DEPOSIT);
				if (hardwareWallet) {
					gasBN = gasBN
						.mul(toBN((100 + getExtraPercentageGas()).toString()))
						.mul(toBN('22')) // an extra 2.2 because of GAS_LIMIT_SAFETY_MULTIPLIER_HARDWARE_DEVICES which is 2, and it seems we need a bit more
						.div(toBN('1000'));
				}
				if (isMounted.current) {
					setCurrentGas(gasBN);
				}
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		defineCurrentGas();
		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const valid = checkValidEnode(enode);
		setValidEnode(valid);
	}, [enode]);

	useEffect(() => {
		defineCurrentGas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gasprice, gaspriceIndex]);

	useEffect(() => {
		if (currentGas) {
			const newBalance = coinBalance.add(collateral).sub(currentGas);
			if (isMounted.current) {
				setBalance(newBalance);
			}
			if (isMounted.current) {
				setMaxCollateral(calculateMaxCollateral(newBalance));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coinBalance, collateral, currentGas]);

	const logo = (
		<TokenImg
			className={clsx(classes.logo, {
				[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
			})}
			alt={getMainName(selectedToken.name, selectedToken.symbol)}
			src={selectedToken.image}
		>
			<TokenImg
				className={clsx(classes.logoUnknown, {
					[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
				})}
				alt={getMainName(selectedToken.name, selectedToken.symbol)}
				src={selectedToken.logoURI}
			>
				?
			</TokenImg>
		</TokenImg>
	);

	const handleChangeAmount = (e, newValue) => {
		let value = newValue.toString();
		if (newValue > parseInt(maxCollateral, 10)) {
			return;
		}
		if (newValue === sliderMax) {
			setSliderValue(sliderMax);
		} else {
			setSliderValue(Math.min(newValue, sliderMax));
		}

		if (
			isValidInteger(value, {
				onlyPositive: true,
			}) ||
			value === 0
		) {
			// just "number" type for Input
			// also make sure that when bigger than 100.000, it will be set to the max of 100.000
			const valueNumber = parseInt(value, 10);
			if (valueNumber > MAX_MN_COLLATERAL) {
				value = MAX_MN_COLLATERAL.toString();
			}
			setAmount(value);
		}
	};

	const handleChangeEnode = e => {
		const value = e.target.value.trim();
		setEnode(value);
	};

	const handleEnodeBlur = () => {
		setCheckEnode(true);
	};

	const handleEnodeFocus = () => {
		setCheckEnode(false);
	};

	const handleEnodeKeyDown = e => {
		if (e.keyCode === 13) {
			handleEnodeBlur();
		}
	};

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i <= length; i++) {
		const value = i * SLIDER_STEP;
		let mark = {
			value,
			label: '',
		};
		if (SLIDER_MIN === mark.value) {
			mark.label = SLIDER_MIN;
		}
		/*
		if (i === length) {
			mark = {
				value: sliderMax,
				label: `${mark.value} Max. Allowed Collateral`,
			};
		}
		*/
		if (i === length) {
			mark = {
				value,
				label: `${MAX_MN_COLLATERAL} Max. Collateral`,
			};
		}
		marks.push(mark);
	}

	const valueLabelFormat = value => {
		const labelContent = (
			<div className={classes.sliderValueLabel}>{value}</div>
		);
		if (value === 0 || validAmount) {
			return labelContent;
		}
		return (
			<div className={classes.sliderValueLabel}>{notEnoughBalanceText}</div>
		);
	};

	const sliderAmount = (
		<SliderSteps
			value={sliderValue}
			getAriaValueText={valueLabelFormat}
			valueLabelFormat={valueLabelFormat}
			valueLabelDisplay="on"
			onChange={handleChangeAmount}
			step={SLIDER_STEP}
			marks={marks}
			min={SLIDER_MIN}
			max={sliderMax}
			disabled={
				!sendButtonEnabled ||
				balance.toString() === '0' ||
				selectedToken?.hasPendingEnergiMasternodeCollateralChange ||
				isAnnouncingOrDenouncing
			}
		/>
	);

	const handleAbort = () => {
		setPage(PAGES_NAMES.TOKEN);
	};

	const handleSetCollateral = async () => {
		try {
			const amountWei = amount.padEnd(amount.length + 18, '0');

			const amountBN = toBN(amountWei);
			setSendButtonEnabled(false);
			if (amountBN.gt(collateral)) {
				const depositAmount = amountBN.sub(collateral);
				await deposit(
					chainId,
					address,
					depositAmount,
					!noMasternode,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
				// over here, tx accepted by metamask
			} else if (amountBN.lt(collateral)) {
				const withdrawAmount = collateral.sub(amountBN);
				await withdraw(
					chainId,
					address,
					withdrawAmount,
					amountBN.eq(BN_ZERO),
					!noMasternode,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
			alert(err.message, 'error');
		}
		if (isMounted.current) {
			setSendButtonEnabled(true);
		}
	};

	const announceMasternode = async () => {
		try {
			setSendButtonEnabled(false);
			await announce(
				chainId,
				address,
				enode,
				sendTx,
				gasprice,
				getExtraPercentageGas(),
				hardwareWallet,
			);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
			alert(err.message, 'error');
		}
		if (isMounted.current) {
			setSendButtonEnabled(true);
		}
	};

	const handleAnnounce = async () => {
		lsEnode.set(enode);
		await announceMasternode();
	};

	const handleReannounce = async () => {
		await announceMasternode();
	};

	const handleDenounce = async () => {
		try {
			setSendButtonEnabled(false);
			const masternodeAddress = selectedToken.masternodeInfo?.masternode;
			await denounce(
				chainId,
				address,
				masternodeAddress,
				sendTx,
				gasprice,
				getExtraPercentageGas(),
				hardwareWallet,
			);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
			alert(err.message, 'error');
		}
		if (isMounted.current) {
			setSendButtonEnabled(true);
		}
	};

	const cancelOrCollaterize = (
		<div
			className={clsx(classes.buttonHorizontalGroup, classes.buttonFirstRow)}
		>
			<Button
				disabled={!sendButtonEnabled}
				variant="outlined"
				size="large"
				onClick={handleAbort}
			>
				{t('page.collateralpage.cancel')}
			</Button>
			<Button
				className={clsx({
					[classes.changingButton]:
						selectedToken?.hasPendingEnergiMasternodeCollateralChange,
				})}
				disabled={
					!validAmount ||
					!sendButtonEnabled ||
					selectedToken?.hasPendingEnergiMasternodeCollateralChange ||
					isAnnouncingOrDenouncing
				}
				onClick={handleSetCollateral}
				variant="outlined"
				size="large"
			>
				{t(
					selectedToken?.hasPendingEnergiMasternodeCollateralChange
						? 'page.collateralpage.collateral_change'
						: 'page.collateralpage.set_collateral',
				)}
			</Button>
		</div>
	);

	// const noMasternode
	// const masternodeNeedsReAnnouncement
	// const masternodeIsOffline
	// const masternodeIsRunning
	// const masternodeIsAnnounced

	let nodeHelptext;
	if (!validEnode) {
		nodeHelptext =
			checkEnode && !!enode
				? 'invalid enode'
				: 'your enode can be found on your masternode';
	}

	let enodeField;
	if (noMasternode && !noCollateral) {
		enodeField = (
			<MuiTextField
				id="amount"
				className={clsx(classes.textFieldEnode, classes.spaceBelow)}
				disabled={!sendButtonEnabled}
				label="enode"
				placeholder={`enode://... :${chainId}`}
				variant="filled"
				value={enode}
				onBlur={handleEnodeBlur}
				onChange={handleChangeEnode}
				onKeyDown={handleEnodeKeyDown}
				onFocus={handleEnodeFocus}
				fullWidth
				error={!validEnode && checkEnode && !!enode}
				helperText={nodeHelptext}
				InputProps={{
					disableUnderline: true,
				}}
				InputLabelProps={{
					shrink: true,
				}}
				ref={enodeRef}
			/>
		);
	}

	let rightButton;
	if (masternodeNeedsReAnnouncement) {
		rightButton = (
			<Button
				className={clsx({
					[classes.changingButton]: selectedToken?.isEnergiMasternodeAnnouncing,
				})}
				disabled={
					!sendButtonEnabled || isAnnouncingOrDenouncing || noCollateral
				}
				onClick={handleReannounce}
				variant="outlined"
				size="large"
			>
				{t(
					selectedToken?.isEnergiMasternodeAnnouncing
						? 'page.collateralpage.reannouncing'
						: 'page.collateralpage.reannounce',
				)}
			</Button>
		);
	} else {
		rightButton = (
			<Button
				className={clsx({
					[classes.changingButton]: selectedToken?.isEnergiMasternodeAnnouncing,
				})}
				disabled={
					!sendButtonEnabled ||
					masternodeIsAnnounced ||
					!validEnode ||
					noCollateral ||
					isAnnouncingOrDenouncing
				}
				onClick={handleAnnounce}
				variant="outlined"
				size="large"
			>
				{t(
					selectedToken?.isEnergiMasternodeAnnouncing
						? 'page.collateralpage.announcing'
						: 'page.collateralpage.announce',
				)}
			</Button>
		);
	}

	const denounceOrAnnounce = (
		<Box
			className={clsx(classes.buttonHorizontalGroup, classes.buttonFirstRow)}
		>
			<Button
				className={clsx({
					[classes.changingButton]:
						!!selectedToken?.isEnergiMasternodeDenouncing,
				})}
				disabled={
					!sendButtonEnabled ||
					noMasternode ||
					isAnnouncingOrDenouncing ||
					noCollateral
				}
				variant="outlined"
				size="large"
				onClick={handleDenounce}
			>
				{t(
					selectedToken?.isEnergiMasternodeDenouncing
						? 'page.collateralpage.denouncing'
						: 'page.collateralpage.denounce',
				)}
			</Button>
			{rightButton}
		</Box>
	);

	const tokenDescription = (
		<Box className={classes.tokenDescription}>
			{t('page.collateralpage.collateralization')}
		</Box>
	);

	const infoBox = (
		<Box className={classes.info}>
			<div>{t('page.collateralpage.info1')}</div>
			<div>
				{t('page.collateralpage.visit')}{' '}
				<a
					href="https://nexus.energi.network/masternodes/announce"
					target="_blank"
					rel="noreferrer noopener"
				>
					Energi Nexus
				</a>{' '}
				{t('page.collateralpage.info2')}
			</div>
		</Box>
	);

	const currentCollaterization = (
		<Box className={classes.currentCollaterization}>
			{t('page.collateralpage.current_collateral')}:{' '}
			{formatBN(collateral, { assetDigits: 18 })} MNRG
		</Box>
	);

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider value={gaspriceIndex} onChange={changeGaspriceIndex} />
		);
	}

	return (
		<Box
			className={classes.root}
			autoComplete="off"
			component="form"
			noValidate
		>
			{logo}
			{tokenDescription}
			{infoBox}
			{currentCollaterization}
			{sliderAmount}
			{warningBalanceText}
			{enodeField}
			{denounceOrAnnounce}
			{cancelOrCollaterize}
			{gaspriceSlider}
		</Box>
	);
};

export default CollateralPage;
