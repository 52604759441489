/* eslint-disable no-async-promise-executor */
import axios from 'axios';

const getMetadata = async url => {
	let metadata;
	try {
		const symbol = url.indexOf('?') === -1 ? '?' : '&';
		const uniqueUrl = `${url + symbol}t=${Date.now()}`;
		const response = await axios.get(uniqueUrl);
		if (response.status === 200) {
			metadata = response.data;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		metadata = null;
	}

	return metadata;
};

export default getMetadata;
