import { BN_ZERO, BN_1, BN_997, BN_1000, BN_100000 } from 'config/constants';

export const getAmountOut = (amountInBN, reserveInBN, reserveOutBN) => {
	if (
		amountInBN.lte(BN_ZERO) ||
		reserveInBN.lte(BN_ZERO) ||
		reserveOutBN.lte(BN_ZERO)
	) {
		return BN_ZERO;
	}
	const amountInWithFee = amountInBN.mul(BN_997); // 0.3% fee
	const numerator = amountInWithFee.mul(reserveOutBN);
	const denominator = reserveInBN.mul(BN_1000).add(amountInWithFee);
	return numerator.div(denominator);
};

export const getAmountIn = (amountOutBN, reserveInBN, reserveOutBN) => {
	if (
		amountOutBN.lte(BN_ZERO) ||
		reserveInBN.lte(BN_ZERO) ||
		reserveOutBN.lte(BN_ZERO)
	) {
		return BN_ZERO;
	}
	const numerator = reserveInBN.mul(amountOutBN).mul(BN_1000);
	const denominator = reserveOutBN.sub(amountOutBN).mul(BN_997);
	return numerator.div(denominator).add(BN_1);
};

// OK
export const priceChangeToExactInput = (
	amountFromBN,
	reserveFromBN,
	reserveToBN,
) => {
	const tokensOutBN = getAmountOut(amountFromBN, reserveFromBN, reserveToBN);
	const numerator = reserveFromBN.add(amountFromBN).mul(reserveToBN);
	const denumerator =
		tokensOutBN.toString() === '0'
			? reserveToBN.mul(reserveFromBN)
			: reserveToBN.sub(tokensOutBN).mul(reserveFromBN);
	const percentThreeDecBN = BN_100000.mul(numerator).div(denumerator);
	const percentThreeDecString = percentThreeDecBN.toString();
	const percentThreeDec = parseFloat(percentThreeDecString);
	const percent = percentThreeDec / 1000;
	return Math.abs(percent - 100);
};

export const priceChangeFromExactInput = (
	amountFromBN,
	reserveFromBN,
	reserveToBN,
) => {
	const nrgsOutBN = getAmountOut(amountFromBN, reserveFromBN, reserveToBN);
	const numerator =
		nrgsOutBN.toString() === '0'
			? reserveToBN.mul(reserveFromBN)
			: reserveToBN.sub(nrgsOutBN).mul(reserveFromBN);
	const denumerator = reserveFromBN.add(amountFromBN).mul(reserveToBN);
	const percentThreeDecBN = BN_100000.mul(numerator).div(denumerator);
	const percentThreeDecString = percentThreeDecBN.toString();
	const percentThreeDec = parseFloat(percentThreeDecString);
	const percent = percentThreeDec / 1000;
	return -Math.abs(percent - 100);
};

// OK
export const priceChangeToExactOutput = (
	amountToBN,
	reserveFromBN,
	reserveToBN,
) => {
	const tokensInBN = getAmountIn(amountToBN, reserveFromBN, reserveToBN);
	const newReserveFromBN =
		tokensInBN.toString() === '0'
			? reserveFromBN
			: reserveFromBN.sub(tokensInBN);
	const newReserveToBN =
		amountToBN.toString() === '0' ? reserveToBN : reserveToBN.add(amountToBN);

	const numerator = newReserveToBN.mul(reserveFromBN);
	const denumerator = newReserveFromBN.mul(reserveToBN);

	const percentThreeDecBN = BN_100000.mul(numerator).div(denumerator);

	const percentThreeDecString = percentThreeDecBN.toString();
	const percentThreeDec = parseFloat(percentThreeDecString);
	const percent = percentThreeDec / 1000;
	return Math.abs(percent - 100);
};

export const priceChangeFromExactOutput = (
	amountToBN,
	reserveFromBN,
	reserveToBN,
) => {
	// const tokensInBN = getAmountIn(amountToBN, reserveFromBN, reserveToBN);
	// const numerator =
	// 	amountToBN.toString() === '0'
	// 		? reserveToBN.mul(reserveFromBN)
	// 		: reserveToBN.add(amountToBN).mul(reserveFromBN);
	// const denumerator = reserveFromBN.add(tokensInBN).mul(reserveToBN);
	// const percentThreeDecBN = BN_100000.mul(numerator).div(denumerator);
	// const percentThreeDecString = percentThreeDecBN.toString();
	// const percentThreeDec = parseFloat(percentThreeDecString);
	// const percent = percentThreeDec / 1000;
	// return percent - 100;
	const tokensInBN = getAmountIn(amountToBN, reserveFromBN, reserveToBN);
	const newReserveFromBN =
		tokensInBN.toString() === '0'
			? reserveFromBN
			: reserveFromBN.sub(tokensInBN);
	const newReserveToBN =
		amountToBN.toString() === '0' ? reserveToBN : reserveToBN.add(amountToBN);

	const numerator = newReserveFromBN.mul(reserveToBN);
	const denumerator = newReserveToBN.mul(reserveFromBN);

	const percentThreeDecBN = BN_100000.mul(numerator).div(denumerator);

	const percentThreeDecString = percentThreeDecBN.toString();
	const percentThreeDec = parseFloat(percentThreeDecString);
	const percent = percentThreeDec / 1000;
	return -Math.abs(percent - 100);
};

// export const getPriceImpactSellingTOKENS100000BN = async (
// 	chainId,
// 	amountTokensBN,
// 	sellToken,
// ) => {
// 	const reserves = await getReserves(
// 		chainId,
// 		WRAPPED_ADDRESSES[chainId],
// 		sellToken,
// 		false,
// 	);

// 	const reserveNrgsBN = toBN(reserves[0]);
// 	const reserveTokensBN = toBN(reserves[1]);

// 	return priceChangeAfterAddingTokens100000BN(
// 		amountTokensBN,
// 		reserveNrgsBN,
// 		reserveTokensBN,
// 	);
// };
