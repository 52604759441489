import React, { useContext } from 'react';
import { Select as MuiSelect, MenuItem as MuiMenuItem } from '@itsa.io/ui';
import { CURRENCIES } from 'config/constants';
import currencyCtx from 'context/currency';

import useStyles from 'styles/components/settings/SelectCurrency';

const SelectCurrency = () => {
	const classes = useStyles();
	const { currency, setCurrency } = useContext(currencyCtx);

	const handleChange = event => {
		const { value } = event.target;
		setCurrency(value);
	};

	const menuItemItems = CURRENCIES.map(currency => (
		<MuiMenuItem className={classes.menuItem} key={currency} value={currency}>
			{currency}
		</MuiMenuItem>
	));

	return (
		<MuiSelect
			className={classes.root}
			value={currency}
			onChange={handleChange}
			variant="outlined"
			displayEmpty
		>
			{menuItemItems}
		</MuiSelect>
	);
};

export default SelectCurrency;
