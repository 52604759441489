import React, { useContext } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@itsa.io/ui';
import expertModeCtx from 'context/expertmode';

import useStyles from 'styles/components/settings/ActivateExpertMode';

const ActivateExpertMode = () => {
	const classes = useStyles();
	const { expertMode, toggleExpertMode } = useContext(expertModeCtx);

	const handleRadioButtonsChange = event => {
		// note: typeof event.target.value === 'string'
		toggleExpertMode(event.target.value === 'true');
	};

	return (
		<FormControl component="fieldset">
			<RadioGroup
				aria-label="expertmode"
				className={classes.radioGroup}
				name="sound"
				onChange={handleRadioButtonsChange}
				row
				value={expertMode}
			>
				<FormControlLabel
					control={<Radio color="primary" />}
					label="off"
					value={false}
				/>
				<FormControlLabel
					control={<Radio color="primary" />}
					label="on"
					value
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default ActivateExpertMode;
