import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	lockControl: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	formControlLabel: {},
	textField: {
		width: '4rem',
		marginRight: theme.spacing(1),
		'& input': {
			padding: theme.spacing(1.5, 0),
			textAlign: 'center',
		},
	},
	passwordField: {
		width: '100%',
		marginTop: theme.spacing(2),
	},
	eye: {
		cursor: 'pointer',
	},
}));
