import getNumberSeparators from 'utils/get-number-separators';

const REG_PATTERN_INTEGER = /^(([-]?[1-9][0-9]*)|0)$/;
const REG_PATTERN_INTEGER_ABS = /^(([1-9][0-9]*)|0)$/;
const PATTER_FLOAT_PART = '(([1-9][0-9]*)|0))?(\\';
const PATTERN_FLOAT_START = `^([-]?${PATTER_FLOAT_PART}`;
const PATTERN_FLOAT_START_ABS = `^(${PATTER_FLOAT_PART}`;
const PATTERN_FLOAT_END = '[0-9]+)?$';

export const isValidInteger = (value, { onlyPositive }) => {
	if (typeof value !== 'string') {
		return false;
	}
	return onlyPositive
		? REG_PATTERN_INTEGER_ABS.test(value)
		: REG_PATTERN_INTEGER.test(value);
};

export const isValidFloat = (
	value,
	{ onlyPositive, acceptTrailingDecimal },
) => {
	if (typeof value !== 'string') {
		return false;
	}
	// eslint-disable-next-line prefer-destructuring
	const decimalSeparator = getNumberSeparators.decimalSeparator;
	const REG_PATTERN_FLOAT = new RegExp(
		(onlyPositive ? PATTERN_FLOAT_START_ABS : PATTERN_FLOAT_START) +
			decimalSeparator +
			PATTERN_FLOAT_END,
	);

	if (acceptTrailingDecimal && value.endsWith(decimalSeparator)) {
		const shortenedValue = value.substr(0, value.length - 1);
		if (!shortenedValue.includes(decimalSeparator)) {
			value = shortenedValue;
		}
	}

	return REG_PATTERN_FLOAT.test(value);
};
