/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { utils } from 'web3';
import { useToggle } from 'react-use';
import selectedtokenCtx from 'context/selectedtoken';
import wrappedtokenCtx from 'context/wrappedtoken';
import navigatorCtx from 'context/navigator';
import gaspriceCtx from 'context/gasprice';
import securedsendtxCtx from 'context/securedsendtx';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import GasSlider from 'components/common/GasSlider';
import {
	Box,
	Button,
	IconButton,
	TextField as MuiTextField,
	InputAdornment,
	Avatar as TokenImg,
} from '@material-ui/core';
import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from '@itsa.io/ui/icons';
import { useIntl, formatBN, toBN, cryptowalletCtx } from '@itsa.io/web3utils';
import useAlert from 'hooks/useAlert';
import {
	PAGES_NAMES,
	NETWORK_NAMES,
	CHAIN_SYMBOLS,
	getMainName,
	BN_ZERO,
	BN_GAS_WRAPPING,
	GAS_PERCENTAGES,
} from 'config/constants';
import getNumberSeparators from 'utils/get-number-separators';
import { isValidFloat } from 'utils/validate-numeric-input';
import { wrap, unwrap } from 'utils/smartcontracts/wrapcoin';

import useStyles from 'styles/pages/WrapPage';
import { SwapHorizontalCircle as SwapIcon } from '@material-ui/icons';

const { isBN } = utils;

const REGEXP_ONLYZEROS = /^0*[,|.]?0*$/;

const WrapPage = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const { chainId, address, hardwareWallet } = useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const networkName = NETWORK_NAMES[chainId];
	const coinSymbol = CHAIN_SYMBOLS[chainId];
	const alert = useAlert();
	const { selectedToken } = useContext(selectedtokenCtx);
	const wrappedToken = useContext(wrappedtokenCtx);
	const { setPage } = useContext(navigatorCtx);
	const { gasprice } = useContext(gaspriceCtx);
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const [wrapping, toggleWrapping] = useToggle(true);
	const [amount, setAmount] = useState('');
	const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
	const [currentGas, setCurrentGas] = useState(BN_ZERO);
	const isMounted = useRef(false);

	const usedBalance = wrapping
		? selectedToken.coinBalance
		: selectedToken.balance;
	const balance = isBN(usedBalance) ? usedBalance : toBN('0');

	const usedOtherBalance = wrapping
		? selectedToken.balance
		: selectedToken.coinBalance;
	const otherBalance = isBN(usedOtherBalance) ? usedOtherBalance : toBN('0');

	const formatBNOptions = {
		assetDigits: selectedToken?.decimals,
		formatHTML: true,
	};

	let validAmount = !REGEXP_ONLYZEROS.test(amount);
	let enoughBalance = true;
	let notEnoughBalanceText;
	let warningBalanceText;

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	const getPreparedAmount = () => {
		let preparedAmount = validAmount ? amount : '0';
		if (!preparedAmount.includes(getNumberSeparators.decimalSeparator)) {
			preparedAmount = preparedAmount.padEnd(
				preparedAmount.length + selectedToken.decimals,
				'0',
			);
		} else {
			// append with '0'
			const decimalIndex = preparedAmount.indexOf(
				getNumberSeparators.decimalSeparator,
			);
			preparedAmount = preparedAmount.padEnd(
				selectedToken.decimals + decimalIndex + 1,
				'0',
			);
			preparedAmount =
				preparedAmount.substr(0, decimalIndex) +
				preparedAmount.substr(decimalIndex + 1);
			// remove prepended zeros:
			while (preparedAmount[0] === '0') {
				preparedAmount = preparedAmount.substring(1);
			}
		}
		return preparedAmount;
	};

	if (validAmount) {
		// check is amount is not bigger than available
		const preparedAmount = getPreparedAmount();
		enoughBalance = toBN(preparedAmount).lte(balance);
		validAmount = enoughBalance;
		if (!enoughBalance) {
			notEnoughBalanceText = t('page.wrappage.not_enough_balance');
		}
	}

	if (wrappedToken) {
		const criticalBalance = wrappedToken.belowCriticalBalance(
			wrapping && validAmount
				? toBN(getPreparedAmount()).add(currentGas)
				: currentGas,
			gasprice,
		);

		if (criticalBalance && enoughBalance) {
			warningBalanceText = (
				<div className={classes.criticalBalanceDescription}>
					<ErrorOutlineOutlinedIcon className={classes.warningIcon} />
					<div>
						{t('page.wrappage.careful_wrapping_not_enough_balance', {
							values: {
								networkName,
								coinSymbol,
								wrapType: wrapping
									? t('page.wrappage.wrapping')
									: t('page.wrappage.unwrapping'),
							},
						})}
					</div>
				</div>
			);
		}
	}

	const defineCurrentGas = async () => {
		try {
			if (isMounted.current && gasprice) {
				let gasBN = gasprice.mul(BN_GAS_WRAPPING[chainId].WRAP);
				if (hardwareWallet) {
					gasBN = gasBN
						.mul(toBN((100 + getExtraPercentageGas()).toString()))
						.mul(toBN('22')) // an extra 2.2 because of GAS_LIMIT_SAFETY_MULTIPLIER_HARDWARE_DEVICES which is 2, and it seems we need a bit more
						.div(toBN('1000'));
				}
				setCurrentGas(gasBN);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		defineCurrentGas();
		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		defineCurrentGas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gasprice, gaspriceIndex]);

	const logo = (
		<TokenImg
			className={clsx(classes.logo, {
				[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
			})}
			alt={getMainName(selectedToken.name, selectedToken.symbol)}
			src={selectedToken.image}
		>
			<TokenImg
				className={clsx(classes.logoUnknown, {
					[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
				})}
				alt={getMainName(selectedToken.name, selectedToken.symbol)}
				src={selectedToken.logoURI}
			>
				?
			</TokenImg>
		</TokenImg>
	);

	const handleToggleWrapping = () => {
		setAmount('');
		toggleWrapping();
	};

	const handleChangeAmount = e => {
		let value = e.target.value.trim();
		if (value.endsWith(getNumberSeparators.groupSeparator)) {
			value = `${value.substr(0, value.length - 1)}${
				getNumberSeparators.decimalSeparator
			}`;
		}
		if (value === getNumberSeparators.decimalSeparator) {
			value = `0${getNumberSeparators.decimalSeparator}`;
		}
		const decimalIndex = value.indexOf(getNumberSeparators.decimalSeparator);
		if (
			isValidFloat(value, {
				onlyPositive: true,
				acceptTrailingDecimal: true,
			}) &&
			(decimalIndex === -1 ||
				decimalIndex >= value.length - selectedToken.decimals - 1)
		) {
			// just "number" type for Input, will also accept 'e'; needed finer restriction
			setAmount(value);
		}
	};

	const handleBlurAmount = () => {
		if (amount.endsWith(getNumberSeparators.decimalSeparator)) {
			setAmount(amount.substr(0, amount.length - 1));
		}
	};

	const handleMaxAmountClick = () => {
		if (!wrapping || currentGas) {
			let amount = wrapping ? balance.sub(currentGas) : balance;
			if (amount.lt(BN_ZERO)) {
				amount = BN_ZERO;
			}
			setAmount(
				formatBN(amount, { assetDigits: selectedToken?.decimals }).replaceAll(
					getNumberSeparators.groupSeparator,
					'',
				),
			);
		}
	};

	const textFieldAmount = (
		<MuiTextField
			id="amount"
			className={classes.textFieldAmount}
			label={t('page.wrappage.amount')}
			disabled={balance.toString() === '0'}
			placeholder={`0${getNumberSeparators.decimalSeparator}0`}
			variant="filled"
			autoFocus
			value={amount}
			onBlur={handleBlurAmount}
			onChange={handleChangeAmount}
			fullWidth
			error={!enoughBalance}
			helperText={notEnoughBalanceText}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Box className={classes.balance} color="text.secondary">
							{t('convert_token.balance')}{' '}
							<span
								dangerouslySetInnerHTML={{
									__html: formatBN(balance, formatBNOptions),
								}}
							/>
						</Box>
						<Box className={classes.symbolMaxAmount}>
							<Box color="secondary.dark">
								{wrapping
									? selectedToken.symbol.substr(1)
									: selectedToken.symbol}
							</Box>
							<Button
								className={classes.buttonMax}
								disabled={balance.toString() === '0'}
								variant="contained"
								color="primary"
								size="small"
								disableElevation
								onClick={handleMaxAmountClick}
							>
								{t('convert_token.button_max')}
							</Button>
						</Box>
					</InputAdornment>
				),
				disableUnderline: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);

	const handleAbort = () => {
		setPage(PAGES_NAMES.TOKEN);
	};

	const handleWrapOrUnwrap = async () => {
		let tx;
		setSendButtonEnabled(false);
		try {
			const preparedAmount = getPreparedAmount();
			if (wrapping) {
				tx = await wrap(
					chainId,
					address,
					preparedAmount,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
			} else {
				tx = await unwrap(
					chainId,
					address,
					preparedAmount,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
			}
			// tx accepted by metamask
			if (!tx) {
				setSendButtonEnabled(true);
			}
		} catch (err) {
			setSendButtonEnabled(true);
			// eslint-disable-next-line no-console
			console.error(err);
			alert(err.message, 'error');
		}
		return tx;
	};

	const abortOrWrap = (
		<Box
			className={clsx(classes.buttonHorizontalGroup, classes.buttonFirstRow)}
		>
			<Button
				disabled={!sendButtonEnabled}
				variant="outlined"
				color="primary"
				size="large"
				disableElevation
				onClick={handleAbort}
			>
				{t('page.wrappage.cancel')}
			</Button>
			<Button
				disabled={!sendButtonEnabled || !validAmount}
				onClick={handleWrapOrUnwrap}
				variant="outlined"
				color="primary"
				size="large"
				disableElevation
			>
				{t(wrapping ? 'page.wrappage.wrap' : 'page.wrappage.unwrap')}
			</Button>
		</Box>
	);

	const tokenDescription = (
		<Box className={classes.tokenDescription}>
			{getMainName(selectedToken.name, selectedToken.symbol)}
		</Box>
	);

	let tokenFrom = selectedToken.symbol;
	let tokenTo = selectedToken.symbol;

	if (wrapping) {
		tokenFrom = tokenFrom.substr(1);
	} else {
		tokenTo = tokenTo.substr(1);
	}

	const directionBox = (
		<Box className={classes.direction}>
			<Box className={classes.tokenFrom}>{tokenFrom}</Box>
			<IconButton
				className={clsx(classes.iconButton, {
					[classes.iconButtonSwap]: !wrapping,
				})}
				disabled={!sendButtonEnabled || otherBalance.toString() === '0'}
				variant="contained"
				color="inherit"
				onClick={handleToggleWrapping}
			>
				<SwapIcon className={classes.swapIcon} />
			</IconButton>
			<Box className={classes.tokenTo}>{tokenTo}</Box>
		</Box>
	);

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider value={gaspriceIndex} onChange={changeGaspriceIndex} />
		);
	}

	return (
		<Box
			className={classes.root}
			autoComplete="off"
			component="form"
			noValidate
		>
			{logo}
			{tokenDescription}
			{directionBox}
			{textFieldAmount}
			{gaspriceSlider}
			{warningBalanceText}
			{abortOrWrap}
		</Box>
	);
};

export default WrapPage;
