import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
	},
	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
		margin: 'auto',
		padding: theme.spacing(3),
		background: theme.palette.background.light,
		borderRadius: 6,
		overflow: 'hidden',
		zIndex: theme.zIndex.drawer + 1,
		[theme.breakpoints.up('sm')]: {
			maxWidth: 438,
			height: 700,
		},
	},
	emptyMessage: {
		fontSize: '1.2em',
		padding: theme.spacing(4, 2),
	},
	contactListContent: {
		overflowY: 'auto',
		'-webkitOverflowScrolling': 'touch',
		height: '100%',
		[theme.breakpoints.up('sm')]: {
			height: 480,
		},
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: 6,
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.common.white}`,
		backgroundColor: theme.palette.grey[50],
		'&:hover': {
			backgroundColor: `${alpha(theme.palette.grey[50], 0.85)}!important`,
		},
		'&.Mui-focusVisible': {
			backgroundColor: theme.palette.grey[50],
		},
		'&.Mui-selected': {
			backgroundColor: theme.palette.grey[50],
		},
	},
	itemAddress: {
		width: '100%',
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.grey[300],
		marginTop: theme.spacing(1),
		textAlign: 'left',
	},
	itemIconsBox: {
		display: 'flex',
		marginLeft: 'auto',
	},
	iconButton: {
		color: theme.palette.grey[200],
	},
	iconButtonCurrAddress: {
		color: theme.palette.grey[100],
	},
	hiddenItem: {
		display: 'none',
	},
	title: {
		fontSize: 20,
		fontWeight: 500,
		color: theme.palette.grey[50],
	},
	closeIcon: {
		position: 'relative',
		top: -10,
		right: -12,
		color: theme.palette.grey[50],
		marginLeft: 'auto',
	},
	accountOptions: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	textFieldAccount: {
		'& .MuiInputBase-root': {
			color: theme.palette.grey[500],
		},
		'& .MuiInputBase-input.Mui-disabled': {
			cursor: 'pointer',
		},
	},
	disabledItem: {
		border: `1px solid ${theme.palette.grey[300]}`,
		color: `${alpha(theme.palette.common.white, 0.32)} !important`,
		backgroundColor: theme.palette.grey[200],
		'&:hover': {
			backgroundColor: `${theme.palette.grey[200]}!important`,
		},
		'& .MuiInputBase-input.Mui-disabled': {
			cursor: 'inherit',
		},
		'& .MuiFilledInput-input': {
			color: `${alpha(theme.palette.common.white, 0.32)} !important`,
		},
		'& .MuiTouchRipple-root': {
			visibility: 'hidden',
		},
	},
	textFieldSearch: {
		margin: theme.spacing(0, 0, 4, 0),
		'& .MuiFormLabel-root': {
			color: theme.palette.grey[100],
		},
		'& .MuiInputBase-root, & .MuiInputBase-root:hover': {
			border: `1px solid ${alpha(theme.palette.grey[500], 0.5)}`,
			backgroundColor: alpha(theme.palette.grey[500], 0.3),
			'&.Mui-focused': {
				border: `1px solid ${alpha(theme.palette.grey[500], 0.5)}`,
				backgroundColor: alpha(theme.palette.grey[500], 0.3),
			},
		},
		'& .MuiFilledInput-input': {
			fontSize: 16,
			fontWeight: 500,
			color: theme.palette.grey[50],
		},
	},

	textFieldDisabled: {
		'& .MuiInputBase-root': {
			color: theme.palette.grey[400],
			backgroundColor: 'transparent',
		},
		'& .MuiFilledInput-input': {
			cursor: 'pointer',
			fontWeight: 500,
		},
	},
	searchIcon: {
		color: theme.palette.grey[50],
	},
	currentAddress: {
		width: 18,
		height: 18,
		color: theme.palette.green[200],
		border: `1px solid ${theme.palette.green[300]}`,
		borderRadius: '100%',
		marginRight: theme.spacing(1),
	},
	security: {
		width: 20,
		height: 20,
		color: theme.palette.grey[100],
		marginRight: theme.spacing(1),
	},
	isSecured: {
		color: theme.palette.red[600],
	},
}));
