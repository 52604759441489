import React, {
	useState,
	useContext,
	useEffect,
	useRef,
	useMemo,
	useCallback,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	Button,
	ListItem,
	IconButton,
	InputAdornment,
	TextField as MuiTextField,
	Avatar as TokenImg,
	Backdrop as MuiBackdrop,
	// CircularProgress,
	useTheme,
	useMediaQuery,
} from '@itsa.io/ui';
import { FixedSizeList as List } from 'react-window';
import {
	ArrowDropDown as ArrowDropDownIcon,
	Search as SearchIcon,
	Close as CloseIcon,
} from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { useIntl, formatBN, cryptowalletCtx } from '@itsa.io/web3utils';
import tokenListCtx from 'context/tokenlist';
import selectedtokenCtx from 'context/selectedtoken';
import expertModeCtx from 'context/expertmode';
import addressMatch from 'utils/address-match';
import {
	getMainName,
	getMainSymbol,
	WRAPPED_ADDRESSES,
	BN_ZERO,
} from 'config/constants';

import useStyles from 'styles/components/common/SelectTokenList';

const sortFn = (wrappedAddress, a, b) => {
	const isWrappedTokenA = addressMatch(a.address, wrappedAddress);
	const isWrappedTokenB = addressMatch(b.address, wrappedAddress);
	if (isWrappedTokenA && !isWrappedTokenB) {
		return -1;
	}
	if (!isWrappedTokenA && isWrappedTokenB) {
		return 1;
	}
	if (isWrappedTokenA && isWrappedTokenB) {
		if (a.isCoin && !b.isCoin) {
			return -1;
		}
		if (!a.isCoin && b.isCoin) {
			return 1;
		}
	}
	if (a.cmcrank < b.cmcrank) {
		return -1;
	}
	if (a.cmcrank > b.cmcrank) {
		return 1;
	}
	return 0;
};

const generateFilteredList = (chainId, list = [], tokenFrom, expertMode) => {
	list = cloneDeep(list);
	if (expertMode && list.length > 0) {
		// duplicate first element
		const wrappedToken = cloneDeep(list[0]);
		// insert wrapped Token at second position:
		list.splice(1, 0, wrappedToken);
	}
	// mark first item as COIN:
	if (
		list[0] &&
		tokenFrom.address &&
		!addressMatch(tokenFrom.address, WRAPPED_ADDRESSES[chainId])
	) {
		list[0].isCoin = true;
	}
	const sortFuncWithWrappedAddress = sortFn.bind(null, list[0]?.address);
	list = list.filter(item => item.address !== tokenFrom?.address);
	list.sort(sortFuncWithWrappedAddress);
	return list;
};

const WAIT_INTERVAL = 200;
const WIDTH = '100%';
const MAX_REQUIRED_HEIGHT_LIST = 700;
const MIN_REQUIRED_HEIGHT_LIST = 562;
const FREE_REQUIRED_SPACE_LIST = 138;
const ITEM_SIZE = 56;

const SelectTokenList = ({ callBackResult, className, disabled }) => {
	const { t } = useIntl();
	const classes = useStyles();
	const theme = useTheme();
	const smallScreenSize = useMediaQuery(theme.breakpoints.up('sm'));
	const minHeightScreenSize = useMediaQuery(
		`(min-height:${MAX_REQUIRED_HEIGHT_LIST}px)`,
	);
	const [open, setOpen] = useState(false);
	const [selectedToken, setSelectedToken] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [dep] = useState(false);
	const timeoutRef = useRef();
	const isMounted = useRef();
	const focusSearchInputField = useRef();
	const { data: tokenList } = useContext(tokenListCtx);
	const { selectedToken: tokenFrom } = useContext(selectedtokenCtx);
	const { expertMode } = useContext(expertModeCtx);
	const { chainId } = useContext(cryptowalletCtx);
	let tokenListContent;

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleOpen = () => {
		setOpen(true);
		setTimeout(() => {
			if (isMounted.current && focusSearchInputField.current) {
				focusSearchInputField.current.focus();
			}
		}, 100);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSearchChange = e => {
		const { value } = e.target;
		setSearchValue(value);
	};

	// temporarely disabled: seems to be invoked too many times
	// TODO: fix autofocus
	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			if (isMounted.current) {
				setSearchQuery(searchValue);
			}
		}, WAIT_INTERVAL);

		return () => clearTimeout(timeoutRef.current);
	}, [searchValue]);

	const handleSelectClick = useCallback(
		token => {
			if (callBackResult) {
				callBackResult(token);
			}
			setSelectedToken(token);
			handleClose();
			setSearchValue('');
			setSearchQuery('');
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dep],
	);

	const searchField = (
		<MuiTextField
			className={classes.textFieldSearch}
			inputRef={focusSearchInputField}
			label="Search"
			variant="filled"
			onChange={handleSearchChange}
			value={searchValue}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchIcon className={classes.searchIcon} />
					</InputAdornment>
				),
				disableUnderline: true,
			}}
			autoFocus={open}
			fullWidth
		/>
	);

	const tokenListContentMemomized = useMemo(
		() => {
			if (chainId === undefined) return [];
			// Standard required list height: 562px that need for fixed height: 700px
			let height = MIN_REQUIRED_HEIGHT_LIST;
			// Calculate correct height, if window innerHeight is smallest than required list fixed height: 700px
			if (!minHeightScreenSize) {
				height = window.innerHeight - FREE_REQUIRED_SPACE_LIST;
			}
			// Note: in extra small screen size, the list height gets 100%
			// Calculate correct height, if in extra small screen size and window innerHeight is greatest than required list fixed height: 700px
			if (minHeightScreenSize && !smallScreenSize) {
				height = window.innerHeight - FREE_REQUIRED_SPACE_LIST;
			}
			const filteredTokenList = generateFilteredList(
				chainId,
				tokenList,
				tokenFrom,
				expertMode,
			);
			const newListFound = filteredTokenList.filter(token => {
				const name = token.isCoin
					? getMainName(token.name, token.symbol)
					: token.name;
				const symbol = token.isCoin
					? getMainSymbol(token.symbol)
					: token.symbol;

				const query = searchQuery.toLowerCase();

				const tokenFound =
					name.toLowerCase().includes(query) ||
					symbol.toLowerCase().includes(query);

				return tokenFound;
			});
			const itemCount = newListFound.length;
			return (
				<List
					className={classes.tokenListContainer}
					width={WIDTH}
					height={height}
					itemSize={ITEM_SIZE}
					itemCount={itemCount}
				>
					{({ index, style }) => {
						const token = newListFound[index];
						const name = token.isCoin
							? getMainName(token.name, token.symbol)
							: token.name;
						const symbol = token.isCoin
							? getMainSymbol(token.symbol)
							: token.symbol;
						const balance = token.isCoin ? token.coinBalance : token.balance;
						const { image, logoURI } = token;
						// const balanceProgressIndicator = balance ? (
						// 	formatBN(balance, {
						// 		assetDigits: token.decimals,
						// 		minDigits: 5,
						// 		decimals: token.visualDecimals,
						// 	})
						// ) : (
						// 	<CircularProgress
						// 		className={classes.circularProgress}
						// 		size={20}
						// 	/>
						// );
						const balanceProgressIndicator = formatBN(balance || BN_ZERO, {
							assetDigits: token.decimals,
							minDigits: 5,
							decimals: token.visualDecimals,
						});

						return (
							<ListItem
								button
								className={classes.item}
								style={style}
								selected={selectedToken?.symbol === symbol}
								onClick={() => handleSelectClick(token)}
								key={token.isCoin ? 'COIN' : token.address}
							>
								<TokenImg className={classes.tokenImg} alt={name} src={image}>
									<TokenImg
										className={classes.tokenImgUnknown}
										alt={name}
										src={logoURI}
									>
										?
									</TokenImg>
								</TokenImg>
								<Box className={classes.itemSymbol}>
									{symbol}
									<Box className={classes.itemName}>{name}</Box>
								</Box>
								<Box className={classes.itemAmount}>
									{balanceProgressIndicator}
								</Box>
							</ListItem>
						);
					}}
				</List>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[chainId, tokenList, tokenFrom, searchQuery, selectedToken],
	);

	let toTokenBox;
	if (selectedToken) {
		const name = selectedToken.isCoin
			? getMainName(selectedToken.name, selectedToken.symbol)
			: selectedToken.name;
		const symbol = selectedToken.isCoin
			? getMainSymbol(selectedToken.symbol)
			: selectedToken.symbol;
		toTokenBox = (
			<Box component="span" display="flex" alignItems="center">
				<TokenImg
					className={classes.selectedItemIcon}
					alt={name}
					src={selectedToken.image}
				>
					<TokenImg className={classes.selectedUnknownIcon} alt={name}>
						?
					</TokenImg>
				</TokenImg>
				<Box className={classes.selectedItemSymbol}>{symbol}</Box>
				<ArrowDropDownIcon className={classes.buttonArrowDown} />
			</Box>
		);
	} else {
		toTokenBox = (
			<Box component="span" display="flex" alignItems="center">
				<Box>{t('select_token_list.select_token')}</Box>
				<ArrowDropDownIcon className={classes.buttonArrowDown} />
			</Box>
		);
	}

	const buttonContent = (
		<Button
			className={clsx(classes.button, className)}
			disabled={disabled}
			size="small"
			variant="contained"
			color="secondary"
			onClick={handleOpen}
			disableElevation
		>
			<Box>{toTokenBox}</Box>
		</Button>
	);

	const backdropContent = (
		<MuiBackdrop
			className={classes.backdrop}
			open={open}
			onClick={handleClose}
		/>
	);

	if (open) {
		tokenListContent = tokenListContentMemomized;
	}

	const listContent = (
		<div
			className={clsx(classes.root, {
				[classes.hidden]: !open,
			})}
		>
			<div className={classes.wrapper}>
				<div className={classes.headerWrap}>
					<div className={classes.header}>
						<div className={classes.title}>
							{t('select_token_list.search_token')}
						</div>
						<IconButton onClick={handleClose} className={classes.closeIcon}>
							<CloseIcon />
						</IconButton>
					</div>
					{searchField}
				</div>
				{tokenListContent}
			</div>
		</div>
	);

	return (
		<>
			{backdropContent}
			{buttonContent}
			{listContent}
		</>
	);
};

SelectTokenList.defaultProps = {
	className: null,
};

SelectTokenList.propTypes = {
	callBackResult: PropTypes.func.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool.isRequired,
};

export default SelectTokenList;
