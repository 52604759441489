const getRemainingDays = (t, time) => {
	const timeMS = time.getTime();
	const now = Date.now();
	const minutesBlocks = Math.round(Math.max(0, timeMS - now) / (1000 * 60));
	let hours = Math.floor(minutesBlocks / 60);
	let minutes = minutesBlocks - 60 * hours;
	const days = Math.floor(hours / 24);
	hours -= days * 24;
	let daysString = '';
	if (days === 1) {
		daysString = `1 ${t('time.day')} `;
	} else if (days > 1) {
		daysString = `${days} ${t('time.days')} `;
	}
	minutes = minutes.toString().padStart(2, '0');
	return `${daysString}${hours}:${minutes} ${t('time.hrs')}`;
};

export default getRemainingDays;
