import { makeStyles, alpha } from '@itsa.io/ui';
import { FONT_SIZE_DECIMALS } from 'config/constants';

export default makeStyles(theme => ({
	root: {
		maxWidth: 500,
		width: '100%',
		textAlign: 'center',
		'& .MuiFormHelperText-root': {
			height: '1rem',
		},
	},
	buttonFirstRow: {
		marginTop: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(5),
		},
	},
	buttonHorizontalGroup: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: 500,
		gap: theme.spacing(4),
		'& button': {
			width: '100%',
			maxWidth: '100%',
			margin: theme.spacing(0.75, 0),
			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(1.5, 0),
			},
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			'& button': {
				width: '100%',
			},
		},
		[theme.breakpoints.down('sm')]: {
			width: '85%',
			display: 'inline-flex',
			gap: theme.spacing(1),
			flexDirection: 'column-reverse',
		},
	},
	logo: {
		width: 96,
		height: 96,
		fontSize: 40,
		margin: theme.spacing(0, 'auto'),
		[theme.breakpoints.down('sm')]: {
			width: 80,
			height: 80,
		},
	},
	logoUnknown: {
		fontSize: 48,
		fontWeight: 700,
		color: theme.palette.secondary.light,
		margin: theme.spacing(0, 'auto'),
	},
	exchangeLogo: {
		position: 'absolute',
		right: theme.spacing(1.5),
		width: 22,
		height: 22,
	},
	exchangeLogoDisabled: {
		opacity: 0.5,
	},
	tokenDescription: {
		fontSize: 30,
		marginTop: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			fontSize: 35,
			marginTop: theme.spacing(1.5),
		},
	},
	textFieldAmount: {
		marginTop: theme.spacing(10),
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	textFieldToAmount: {
		marginTop: theme.spacing(3),
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	helperTextError: {
		fontSize: '0.75rem',
		fontWeight: 500,
		textAlign: 'left',
		color: theme.palette.red[600],
		margin: theme.spacing(0.5, 0, 0, 2),
	},
	helperTextWarning: {
		fontSize: '0.75rem',
		fontWeight: 500,
		textAlign: 'left',
		margin: theme.spacing(0.5, 0, 0, 2),
	},
	percentageButtonGroup: {
		display: 'flex',
		marginLeft: 'auto',
	},
	percentageButton: {
		display: 'inline-block',
		width: '100%',
		fontSize: 14,
		borderWidth: 1,
		borderRadius: 0,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.default.light,
		border: `1px solid ${theme.palette.border.main}`,
		marginTop: theme.spacing(0.5),
		'&:hover': {
			backgroundColor: theme.palette.default.light,
			border: `1px solid ${alpha(theme.palette.default.main, 0.8)}`,
		},
		'& + &': {
			marginLeft: theme.spacing(0.5),
		},
	},
	percentageButtonFirst: {
		borderTopLeftRadius: 6,
		borderBottomLeftRadius: 6,
	},
	percentageButtonLast: {
		borderTopRightRadius: 6,
		borderBottomRightRadius: 6,
	},
	balance: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		fontWeight: 500,
		textAlign: 'right',
	},
	symbol: {
		fontSize: 20,
		fontWeight: 500,
		margin: theme.spacing(3, 0, 0, 0),
		textAlign: 'right',
	},
	selectTokenButton: {
		margin: theme.spacing(3, 0, 0, 0),
	},
	approvingButton: {
		width: 'auto',
		'& .MuiButton-label': {
			width: 'auto',
			'&::after': {
				position: 'relative',
				top: -5,
				left: 5,
				content: '" ."',
				animation: `$dotAnim 1s steps(5, end) infinite`,
				fontSize: 25,
				lineHeight: 1,
			},
		},
	},
	warningIcon: {
		fontSize: '1.2rem',
		color: theme.palette.primary.main,
		marginRight: theme.spacing(1),
	},
	criticalIcon: {
		fontSize: '1.2rem',
		color: theme.palette.red[500],
		marginRight: theme.spacing(1),
	},
	warningDescription: {
		display: 'flex',
		fontSize: '0.85rem',
		textAlign: 'left',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(2),
	},
	settingWrapper: {
		display: 'inline',
		color: theme.palette.primary.main,
	},
	settingButton: {
		fontWeight: 700,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	pendingTransaction: {
		animation: `$blink 1.5s infinite`,
	},
	'@keyframes blink': {
		'50%': {
			opacity: 0.5,
		},
	},
	'@keyframes dotAnim': {
		'0%, 20%': {
			color: 'rgba(0,0,0,0)',
			textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
		},
		'40%': {
			color: theme.palette.primary.main,
			textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
		},
		'60%': {
			textShadow: `.25em 0 0 ${theme.palette.primary.main}, .5em 0 0 rgba(0,0,0,0)`,
		},
		'80%, 100%': {
			textShadow: `.25em 0 0 ${theme.palette.primary.main}, .5em 0 0 ${theme.palette.primary.main}`,
		},
	},
}));
