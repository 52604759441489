/* eslint-disable react/no-danger */
import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
// import { useToggle } from 'react-use';
import selectedtokenCtx from 'context/selectedtoken';
import navigatorCtx from 'context/navigator';
import currencyCtx from 'context/currency';
import expertModeCtx from 'context/expertmode';
import disclaimermodalCtx from 'context/disclaimermodal';
import wrappedtokenCtx from 'context/wrappedtoken';
import pendingtransactionsCtx from 'context/pendingtransactions';
import { Box, Button, Avatar as TokenImg, Fade } from '@itsa.io/ui';
import {
	AccountTreeOutlined as AccountTreeOutlinedIcon,
	SyncAlt as SyncAltIcon,
	CardMembership as CardMembershipIcon,
	// Receipt as ReceiptIcon,
} from '@itsa.io/ui/icons';
import { useIntl, formatBN, toBN, cryptowalletCtx } from '@itsa.io/web3utils';
import {
	PAGES_NAMES,
	WRAPPED_ADDRESSES,
	IS_ENERGI_CHAINID,
	BN_1MNRG,
	getMainName,
	getMainSymbol,
} from 'config/constants';
import { utils } from 'web3';
import { toFiat, toFiatFromDexPrices } from 'utils/tokenpriceToFiat';
// import Transactions from 'components/common/Transactions';
import TransactionHistory from 'components/common/TransactionHistory';
// import TransactionModal from 'components/common/modals/Modal';
import QRCodeModal from 'components/common/modals/QRCodeModal';
import DisclaimerBox from 'components/common/DisclaimerBox';

import useStyles from 'styles/pages/TokenPage';
import * as addressMatch from 'utils/address-match';

const BN_ZERO = toBN('0');
const { isBN } = utils;

const TokenPage = (/* props */) => {
	const classes = useStyles();
	const { t } = useIntl();
	const { chainId, address } = useContext(cryptowalletCtx);
	const { selectedToken } = useContext(selectedtokenCtx);
	const { setPage } = useContext(navigatorCtx);
	const { currency } = useContext(currencyCtx);
	const { expertMode } = useContext(expertModeCtx);
	const { complied } = useContext(disclaimermodalCtx);
	const pendingtransactions = useContext(pendingtransactionsCtx);
	const wrappedtoken = useContext(wrappedtokenCtx);
	// const [showTransactions, toggleShowTransactions] = useToggle(false);
	const [openQRCodeModal, setOpenQRCodeModal] = useState(false);
	const [compliedAtMount, setCompliedAtMount] = useState(true); // initially true, to prevent a quick show and hide
	const wrappedTokenAddress = WRAPPED_ADDRESSES[chainId]?.toLowerCase();
	const isCoin = selectedToken.address.toLowerCase() === wrappedTokenAddress;

	const logo = (
		<Fade timeout={500} in>
			<TokenImg
				className={clsx(classes.logo, {
					[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
				})}
				alt={getMainName(selectedToken.name, selectedToken.symbol)}
				src={selectedToken.image}
			>
				<TokenImg
					className={clsx(classes.logoUnknown, {
						[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
					})}
					alt={getMainName(selectedToken.name, selectedToken.symbol)}
					src={selectedToken.logoURI}
				>
					?
				</TokenImg>
			</TokenImg>
		</Fade>
	);

	useEffect(() => {
		setCompliedAtMount(complied);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleExchange = () => {
		setPage(PAGES_NAMES.EXCHANGE);
	};

	const handleDeposits = () => {
		setPage(PAGES_NAMES.COLLATERAL);
	};

	const setSendCoinPage = () => {
		setPage(PAGES_NAMES.SENDCOIN);
	};

	const setSendTokenPage = () => {
		setPage(PAGES_NAMES.SENDTOKEN);
	};

	const setWrapPage = () => {
		setPage(PAGES_NAMES.WRAP);
	};

	const handleQRCodeModalOpen = assetType => {
		setOpenQRCodeModal(assetType);
	};

	const handleQRCodeModalClose = () => {
		setOpenQRCodeModal(false);
	};

	const balance = isBN(selectedToken.balance)
		? selectedToken.balance
		: toBN('0');
	let totalBalance = balance;
	let wrapUnwrap;
	let collateralization;
	let balanceDetails;
	let nrgDetails;
	let wrappedDetails;
	let masternodeDetails;
	let valueMsg;
	let sendAndReceive;
	let sendAndReceiveCoin;
	let coinSymbol;

	if (isCoin) {
		if (balance.gt(BN_ZERO)) {
			coinSymbol = ` ${selectedToken.symbol.substr(1)}`;
		}
		sendAndReceiveCoin = (
			<div
				className={clsx(classes.buttonHorizontalGroup, classes.buttonFirstRow)}
			>
				<Button
					disabled={!complied}
					variant="outlined"
					color="primary"
					size="large"
					disableElevation
					onClick={() => handleQRCodeModalOpen('coin')}
				>
					{t('page.tokenpage.receive')}
					{coinSymbol}
				</Button>
				<Button
					disabled={
						!selectedToken.coinBalance ||
						selectedToken.coinBalance.toString() === '0' ||
						!complied
					}
					variant="outlined"
					color="primary"
					size="large"
					disableElevation
					onClick={setSendCoinPage}
				>
					{t('page.tokenpage.send')}
					{coinSymbol}
				</Button>
			</div>
		);
	}

	if (!isCoin || expertMode || (isCoin && balance.gt(BN_ZERO))) {
		sendAndReceive = (
			<div
				className={clsx(classes.buttonHorizontalGroup, {
					[classes.buttonFirstRow]: !isCoin,
				})}
			>
				<Button
					disabled={!complied}
					variant="outlined"
					color="primary"
					size="large"
					disableElevation
					onClick={() => handleQRCodeModalOpen('token')}
				>
					{t('page.tokenpage.receive') +
						(isCoin ? ` ${selectedToken.symbol}` : '')}
				</Button>
				<Button
					disabled={balance.toString() === '0' || !complied}
					variant="outlined"
					color="primary"
					size="large"
					disableElevation
					onClick={setSendTokenPage}
				>
					{t('page.tokenpage.send') +
						(isCoin ? ` ${selectedToken.symbol}` : '')}
				</Button>
			</div>
		);
	}

	if (isCoin) {
		const coinBalance = isBN(selectedToken.coinBalance)
			? selectedToken.coinBalance
			: toBN('0');
		const hasWrapped = balance.gt(BN_ZERO);
		totalBalance = totalBalance.add(coinBalance);

		if (hasWrapped) {
			wrappedDetails = (
				<div className={classes.wrappedDetails}>
					<span
						dangerouslySetInnerHTML={{
							__html: formatBN(balance, {
								assetDigits: selectedToken.decimals,
								minDigits: 2,
								decimals: 4,
								formatHTML: true,
							}),
						}}
					/>{' '}
					<div className={classes.tokenSymbolDetail}>
						{selectedToken.symbol}
					</div>
				</div>
			);
		}
		if (hasWrapped || expertMode) {
			wrapUnwrap = (
				<Button
					disabled={!complied}
					className={classes.buttonTextIcon}
					onClick={setWrapPage}
					variant="outlined"
					color="secondary"
					disableElevation
				>
					<span className={classes.iconWrap}>
						<CardMembershipIcon fontSize="large" />
					</span>
					{t('page.tokenpage.wraporunwrap')}
				</Button>
			);
		}
		const hasCollateral = selectedToken.collateral?.gt(BN_ZERO);
		if (IS_ENERGI_CHAINID(chainId)) {
			const hideCollateralButton =
				(selectedToken.coinBalance.lt(BN_1MNRG) || !expertMode) &&
				!hasCollateral;
			if (!hideCollateralButton) {
				collateralization = (
					<Button
						className={classes.buttonTextIcon}
						disabled={!complied}
						onClick={handleDeposits}
						variant="outlined"
						color="secondary"
						disableElevation
						disableFocusRipple
					>
						<span className={classes.iconWrap}>
							<AccountTreeOutlinedIcon fontSize="large" />
						</span>
						{t('page.tokenpage.masternode')}
					</Button>
				);
			}
		}
		if (hasCollateral) {
			totalBalance = totalBalance.add(selectedToken.collateral);
			masternodeDetails = (
				<div className={classes.masternodeDetails}>
					{formatBN(selectedToken.collateral, {
						assetDigits: 18,
						decimals: 0,
					})}{' '}
					<div className={classes.tokenSymbolDetailMN}>
						{t('page.tokenpage.mnrg')}
					</div>
				</div>
			);
		}
		if (hasWrapped || hasCollateral) {
			nrgDetails = (
				<div className={classes.coinDetails}>
					<span
						dangerouslySetInnerHTML={{
							__html: formatBN(coinBalance, {
								assetDigits: selectedToken.decimals,
								minDigits: 2,
								decimals: 4,
								formatHTML: true,
							}),
						}}
					/>{' '}
					<div className={classes.tokenSymbolDetail}>
						{getMainSymbol(selectedToken.symbol)}
					</div>
				</div>
			);
			balanceDetails = (
				<div className={classes.detailContainer}>
					{nrgDetails}
					{wrappedDetails}
					{masternodeDetails}
				</div>
			);
			valueMsg = (
				<span className={classes.valueMsg}>
					{t('page.tokenpage.total_value')}
				</span>
			);
		}
	}

	const balanceToken = (
		<div className={classes.tokenAmountWrapper}>
			{valueMsg}
			<span
				className={classes.totalBalance}
				dangerouslySetInnerHTML={{
					__html: formatBN(totalBalance, {
						assetDigits: selectedToken.decimals,
						minDigits: 5,
						decimals: selectedToken.visualDecimals,
						formatHTML: true,
					}),
				}}
			/>
			<span className={classes.tokenSymbol}>
				{getMainSymbol(selectedToken.symbol)}
			</span>
		</div>
	);

	const fiatBN = selectedToken.calculateTotalAssetPriceFromDexPrice
		? toFiatFromDexPrices(
				totalBalance,
				selectedToken.reserves0BN,
				selectedToken.reserves1BN,
				selectedToken.currencyprices,
				currency,
				selectedToken.decimals,
				wrappedtoken,
		  )
		: toFiat(
				totalBalance,
				selectedToken.prices,
				currency,
				selectedToken.decimals,
		  );
	const balanceFiat = (
		<Box className={classes.fiatAmount} color="text.secondary">
			<span
				dangerouslySetInnerHTML={{
					__html: formatBN(fiatBN, {
						assetDigits: 18, // toFiat generates 18 assetDigits
						minDigits: 3,
						decimals: 2,
						formatHTML: true,
					}),
				}}
			/>{' '}
			<Box
				component="span"
				color="text.secondary"
				className={classes.tokenSymbolFiat}
			>
				{currency}
			</Box>
		</Box>
	);

	const exchangeButton = (
		<Button
			disabled={
				((selectedToken.coinBalance?.toString() || '0') === '0' &&
					balance.toString() === '0') ||
				!complied
			}
			className={classes.buttonTextIcon}
			onClick={handleExchange}
			variant="outlined"
			color="secondary"
			disableElevation
			disableFocusRipple
		>
			<span className={classes.iconWrap}>
				<SyncAltIcon fontSize="large" />
			</span>
			{t('page.tokenpage.exchange')}
		</Button>
	);

	// const transactionsButton = (
	// 	<Button
	// 		className={classes.buttonTextIcon}
	// 		disabled={!complied}
	// 		onClick={toggleShowTransactions}
	// 		disableElevation
	// 	>
	// 		<span className={classes.iconWrap}>
	// 			<ReceiptIcon fontSize="large" />
	// 		</span>
	// 		{t('page.tokenpage.transactions')}
	// 	</Button>
	// );

	const otherActions = (
		<div className={classes.buttonVerticalGroup}>
			{wrapUnwrap}
			{exchangeButton}
			{collateralization}
			{/* {transactionsButton} */}
		</div>
	);

	const TokenDescriptionName = isCoin
		? getMainName(selectedToken.name, selectedToken.symbol)
		: selectedToken.name;

	const tokenDescription = (
		<Box className={classes.tokenDescription}>{TokenDescriptionName}</Box>
	);

	const qrCodeModal = (
		<QRCodeModal
			title={`${
				openQRCodeModal === 'coin'
					? getMainName(selectedToken.name, selectedToken.symbol)
					: selectedToken.name
			} (${
				openQRCodeModal === 'coin'
					? getMainSymbol(selectedToken.symbol)
					: selectedToken.symbol
			})`}
			address={address}
			symbol={
				openQRCodeModal === 'coin'
					? `${getMainSymbol(selectedToken.symbol)} ${t(
							'page.tokenpage.coins',
					  )}`
					: `${selectedToken.symbol} ${t('page.tokenpage.tokens')}`
			}
			handleClose={handleQRCodeModalClose}
			show={!!openQRCodeModal}
		/>
	);

	let pendingTransactions;
	const pendingtransactionsSelectedToken = pendingtransactions.filter(item =>
		isCoin
			? item.coinimpact ||
			  addressMatch(item.tokenimpact, selectedToken?.address) ||
			  addressMatch(item.tokenimpact2, selectedToken?.address)
			: addressMatch(item.tokenimpact, selectedToken?.address) ||
			  addressMatch(item.tokenimpact2, selectedToken?.address),
	);

	if (selectedToken.hasPendingTransaction) {
		pendingTransactions = (
			<TransactionHistory
				className={classes.transactionHistoryTable}
				address={address}
				selectedToken={selectedToken}
				data={pendingtransactionsSelectedToken}
				pending
				symbol={getMainSymbol(selectedToken.symbol)}
			/>
		);
	}

	let transactions;
	/*
	if (showTransactions) {
		// transactions = (
		// 	<TransactionModal
		// 		title="Transactions"
		// 		handleClose={toggleShowTransactions}
		// 		show={showTransactions}
		// 	>
		// 		<TransactionHistory
		// 			className={classes.transactionHistoryTable}
		// 			headers={dataHeader}
		// 			data={dataSet}
		// 		/>
		// 	</TransactionModal>
		// );
		transactions = (
			<TransactionHistory
				className={classes.transactionHistoryTable}
				headers={dataHeader}
				data={dataSet}
			/>
		);
	}
	*/

	let disclaimerBox;
	if (!compliedAtMount) {
		disclaimerBox = <DisclaimerBox />;
	}

	return (
		<>
			{logo}
			{tokenDescription}
			{balanceDetails}
			{balanceToken}
			{balanceFiat}
			{otherActions}
			{sendAndReceiveCoin}
			{sendAndReceive}
			{disclaimerBox}
			{/* {transactionsButton} */}
			{pendingTransactions}
			{/* <TransactionHistory
				className={classes.transactionHistoryTable}
				data={dataSet}
			/> */}
			{transactions}
			{qrCodeModal}
		</>
	);
};

export default TokenPage;
