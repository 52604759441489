import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		marginTop: theme.spacing(3),
	},
	formControlLabel: {
		marginRight: theme.spacing(1),
	},
}));
