/**
 * Find the group and decimal separator of the current browser
 */

const DEFAULT_SEPARATORS = {
	groupSeparator: ',',
	decimalSeparator: '.',
};

// eslint-disable-next-line import/no-mutable-exports
let getNumberSeparators;

// eslint-disable-next-line func-names
(function (win) {
	// define default separator
	if (win.Intl && win.navigator?.language) {
		// create a dummy INTL number, by using 42069.1337 as an example -> this should lead into
		// a number with a thousand and decimal separator
		const parts = win.Intl.NumberFormat(win.navigator.language).formatToParts(
			42069.1337,
		);
		const groupSeparator = parts.find(part => part.type === 'group');
		const decimalSeparator = parts.find(part => part.type === 'decimal');

		if (groupSeparator && decimalSeparator) {
			getNumberSeparators = {
				groupSeparator: groupSeparator.value,
				decimalSeparator: decimalSeparator.value,
			};
		}
	}

	if (!getNumberSeparators) {
		getNumberSeparators = DEFAULT_SEPARATORS;
	}
})(typeof global !== 'undefined' ? global : /* istanbul ignore next */ this);

export default getNumberSeparators;
