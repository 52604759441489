/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import selectedtokenCtx from 'context/selectedtoken';
import basetradetokenCtx from 'context/basetradetoken';
import wrappedtokenCtx from 'context/wrappedtoken';
import navigatorCtx from 'context/navigator';
import currencyCtx from 'context/currency';
import subscriptionCtx from 'context/subscription';
import expertModeCtx from 'context/expertmode';
import slippageCtx from 'context/slippage';
import blockHeightCtx from 'context/blockheight';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import gaspriceCtx from 'context/gasprice';
import promoformCtx from 'context/promoform';
import securedsendtxCtx from 'context/securedsendtx';
import { getReserves } from 'utils/smartcontracts/pair';
import addressMatch from 'utils/address-match';

import {
	Box,
	Button,
	TextField as MuiTextField,
	InputAdornment,
	Avatar as TokenImg,
	Tooltip,
} from '@material-ui/core';
import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from '@itsa.io/ui/icons';
import { useIntl, formatBN, toBN, cryptowalletCtx } from '@itsa.io/web3utils';
import useAlert from 'hooks/useAlert';
import {
	PAGES_NAMES,
	getMainName,
	ROUTER_ADDRESSES,
	WRAPPED_ADDRESSES,
	NETWORK_NAMES,
	CHAIN_SYMBOLS,
	BN_3,
	BN_100,
	BN_1000,
	BN_GAS_TRADE,
	BN_ZERO,
	CALCULATE_MINIMUM_TRADE_AMOUNT,
	GAS_PERCENTAGES,
	WARN_PRICEIMPACT_ABOVE,
	DEXES_NAMES,
	DEXES_ICON,
	ETHEREUM_POW_CHAINIDS,
} from 'config/constants';
import amountToWei from 'utils/amount-to-wei';
import getNumberSeparators from 'utils/get-number-separators';
import { isValidFloat } from 'utils/validate-numeric-input';
import { getDexTokenPrice } from 'utils/get-tokenprice';
import { hasApproval, approveMax } from 'utils/smartcontracts/token';
import {
	sellTokens,
	buyTokens,
	buyTokensFromCoin,
	sellCoins,
	buyCoins,
	sellTokensForCoins,
	getMinimumAmountOut,
	getMaximumAmountIn,
} from 'utils/smartcontracts/trade';
import GasSlider from 'components/common/GasSlider';
import SelectTokenList from 'components/common/SelectTokenList';
import SelectNativeOrWrapped from 'components/common/SelectNativeOrWrapped';
import { utils } from 'web3';
import useStyles from 'styles/pages/ExchangePage';

const { isBN } = utils;

const REGEXP_ONLYZEROS = /^0*[,|.]?0*$/;

const ExchangePage = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const { address, chainId, hardwareWallet, hardwareStatus } =
		useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const networkName = NETWORK_NAMES[chainId];
	const coinSymbol = CHAIN_SYMBOLS[chainId];
	const alert = useAlert();
	const { selectedToken } = useContext(selectedtokenCtx);
	const baseTradeToken = useContext(basetradetokenCtx);
	const wrappedToken = useContext(wrappedtokenCtx);
	const { setPage } = useContext(navigatorCtx);
	const { currency } = useContext(currencyCtx);
	const { expertMode } = useContext(expertModeCtx);
	const { slippage } = useContext(slippageCtx);
	const { blockheight } = useContext(blockHeightCtx);
	const { gasprice } = useContext(gaspriceCtx);
	const subscription = useContext(subscriptionCtx);
	const { subscriptionChain, hasFullAccess } = subscription;
	const isSubscriptionExpired = subscriptionChain && !hasFullAccess;
	const { open: promoformOpen } = useContext(promoformCtx);
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
	const [currentGas, setCurrentGas] = useState(BN_ZERO);
	const [amount, setAmount] = useState('');
	const [amountTo, setAmountTo] = useState('');
	const [selectedToToken, setSelectedToToken] = useState();
	const [calcExactTarget, setCalcExactTarget] = useState(false);
	const [tradeSteps, setTradeSteps] = useState(1);

	const [priceImpact, setPriceImpact] = useState(0);
	const [, /* fee */ setFee] = useState(0);
	const [needsApproval, setNeedsApproval] = useState(false);

	const isMounted = useRef(false);
	const hardwareStatusRef = useRef(hardwareStatus);
	const wrappedTokenAddress = WRAPPED_ADDRESSES[chainId]?.toLowerCase();

	// coinPage defines if we want to exchange the Native Coin OR its Wrapped Token:
	const coinPage = addressMatch(selectedToken.address, wrappedTokenAddress);

	// swapFromCoin defines which one we want to swap: Native Coin OR its Wrapped Token, olny relevant if coinPage === true
	const [swapFromCoin, setSwapFromCoin] = useState(coinPage);
	const usedBalance =
		coinPage && swapFromCoin
			? selectedToken.coinBalance
			: selectedToken.balance;
	const balance = isBN(usedBalance) ? usedBalance : toBN('0');
	const tokenBalance = isBN(selectedToken.balance)
		? selectedToken.balance
		: toBN('0');
	const usedBalanceTo = selectedToToken?.isCoin
		? selectedToToken?.coinBalance
		: selectedToToken?.balance;
	const balanceTo = isBN(usedBalanceTo) ? usedBalanceTo : toBN('0');
	const formatBNOptions = {
		assetDigits: selectedToken?.decimals,
		formatHTML: true,
	};
	const formatBNOptionsTo = {
		assetDigits: selectedToToken?.decimals,
		minDigits: 5,
		decimals: selectedToToken?.visualDecimals,
		formatHTML: true,
	};
	const formatBNOptionsInput = {
		assetDigits: selectedToken?.decimals,
		minDigits: 7,
		decimals: selectedToken?.visualDecimals,
		withThousandSeparator: false,
	};
	const formatBNOptionsInputTo = {
		assetDigits: selectedToToken?.decimals,
		minDigits: 7,
		decimals: selectedToToken?.visualDecimals,
		withThousandSeparator: false,
	};

	const amountWithoutDecimals = amount.replaceAll(
		getNumberSeparators.decimalSeparator,
		'',
	);

	let validAmount = !REGEXP_ONLYZEROS.test(amountWithoutDecimals);
	let enoughBalance = true;
	let preparedAmount = amount;
	let preparedAmountTo = amountTo;
	let notEnoughBalanceText;
	let warningBalanceText;

	if (validAmount) {
		// check is amount is not bigger than available
		preparedAmount = amountToWei(preparedAmount, selectedToken.decimals);
		// preparing preparedAmountTo
		if (selectedToToken) {
			preparedAmountTo = amountToWei(
				preparedAmountTo,
				selectedToToken?.decimals,
			);
		}

		let maxAvailable = balance.sub(currentGas);
		if (maxAvailable.lt(BN_ZERO)) {
			maxAvailable = BN_ZERO;
		}
		enoughBalance = toBN(preparedAmount).lte(
			coinPage && swapFromCoin ? maxAvailable : balance,
		);
		const enoughBalanceDisregardingGas = toBN(preparedAmount).lte(balance);
		validAmount = enoughBalance;
		if (!enoughBalance) {
			notEnoughBalanceText = enoughBalanceDisregardingGas
				? t('page.exchangepage.not_enough_balance_for_gas')
				: t('page.exchangepage.not_enough_balance');
		}
	}

	if (wrappedToken) {
		const criticalBalance = wrappedToken.belowCriticalBalance(
			coinPage && swapFromCoin && validAmount
				? toBN(amountToWei(amount, selectedToken.decimals)).add(currentGas)
				: currentGas,
			gasprice,
		);

		if (criticalBalance && enoughBalance) {
			warningBalanceText = (
				<div className={classes.warningDescription}>
					<ErrorOutlineOutlinedIcon className={classes.warningIcon} />
					<div>
						{t('page.exchangepage.careful_exchanging_not_enough_balance', {
							values: { networkName, coinSymbol },
						})}
					</div>
				</div>
			);
		}
	}

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	const defineCurrentGas = async () => {
		try {
			if (gasprice) {
				let gasLimitBN = BN_GAS_TRADE[chainId].ONE_STEP;
				if (tradeSteps > 1) {
					gasLimitBN = gasLimitBN.add(
						toBN(tradeSteps.toString()).mul(BN_GAS_TRADE[chainId].EXTRA_STEP),
					);
				}
				let gasBN = gasprice.mul(gasLimitBN);
				if (hardwareWallet) {
					gasBN = gasBN
						.mul(toBN((100 + getExtraPercentageGas()).toString()))
						.mul(toBN('22')) // an extra 2.2 because of GAS_LIMIT_SAFETY_MULTIPLIER_HARDWARE_DEVICES which is 2, and it seems we need a bit more
						.div(toBN('1000'));
				}
				if (isMounted.current) {
					setCurrentGas(gasBN);
				}
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	const checkApproval = async () => {
		if (chainId && selectedToken && address && !swapFromCoin) {
			let isApproved;
			if (swapFromCoin) {
				isApproved = true;
			} else {
				// check approval of a bit more than we want to spend
				// because "from" may be estimated and we may end up needing more
				isApproved = await hasApproval(
					chainId,
					selectedToken.address,
					address,
					ROUTER_ADDRESSES[chainId],
					toBN(preparedAmount || '0')
						.add(toBN('0xffffffffffffffffffff'))
						.toString(), // in wei
				);
			}
			if (isMounted.current) {
				setNeedsApproval(!isApproved);
			}
		} else if (isMounted.current) {
			// No approval needed for a native coin
			setNeedsApproval(false);
		}
	};

	useEffect(() => {
		if (isMounted.current) {
			hardwareStatusRef.current = hardwareStatus;
		}
	}, [hardwareStatus]);

	useEffect(() => {
		if (isMounted.current) {
			setSwapFromCoin(coinPage);
		}
	}, [coinPage]);

	useEffect(() => {
		if (isMounted.current) {
			defineCurrentGas();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeSteps, gasprice, gaspriceIndex]);

	useEffect(() => {
		checkApproval();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [swapFromCoin]);

	useEffect(() => {
		// on componentDidMount, we will check if the Token has approval to swap
		isMounted.current = true;
		checkApproval();
		defineCurrentGas();
		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (needsApproval && !selectedToken.isBeingApproved) {
			checkApproval();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedToken]);

	useEffect(() => {
		// preload the reserves of tokenFrom <-> WRAPPED_ADDRESSES[chainId], to speed up pricecalculations:
		// and tokenTo <-> WRAPPED_ADDRESSES[chainId]
		// to speed up pricecalculations:
		if (chainId && isMounted.current) {
			getReserves(chainId, selectedToken, baseTradeToken, false, blockheight);
			if (selectedToToken) {
				getReserves(
					chainId,
					selectedToToken,
					baseTradeToken,
					false,
					blockheight,
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, blockheight]);

	const estimateAmount = async valueTo => {
		if (isMounted.current) {
			setAmount('');
			setPriceImpact({ from: 0, to: 0 });
			setFee(0);
			if (
				chainId &&
				selectedToToken &&
				valueTo &&
				!REGEXP_ONLYZEROS.test(valueTo)
			) {
				const weiAmountTo = amountToWei(valueTo, selectedToToken?.decimals);
				let estimatedAmount;
				try {
					estimatedAmount = await getMaximumAmountIn(
						chainId,
						baseTradeToken,
						selectedToken,
						selectedToToken,
						weiAmountTo,
						slippage,
						blockheight,
					);
					if (isMounted.current) {
						setTradeSteps(estimatedAmount.tradeSteps);
						setAmount(
							estimatedAmount.amountInMax.toString() === '0'
								? ''
								: formatBN(estimatedAmount.amountInMax, formatBNOptionsInput),
						);
						setPriceImpact(estimatedAmount.priceImpact);
						setFee(
							estimatedAmount.amountInMax
								.mul(toBN(estimatedAmount.tradeSteps))
								.mul(BN_1000)
								.div(BN_3),
						);
					}
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error(err);
					alert(err.message, 'error');
					if (isMounted.current) {
						setAmount('');
						setPriceImpact({ from: 0, to: 0 });
						setFee(0);
					}
				}
			}
		}
	};

	const estimateAmountTo = async valueFrom => {
		if (isMounted.current) {
			setAmountTo('');
			setPriceImpact({ from: 0, to: 0 });
			setFee(0);
			if (
				chainId &&
				selectedToToken &&
				valueFrom &&
				!REGEXP_ONLYZEROS.test(valueFrom)
			) {
				const weiAmountFrom = amountToWei(valueFrom, selectedToken.decimals);
				let estimatedAmount;
				try {
					estimatedAmount = await getMinimumAmountOut(
						chainId,
						baseTradeToken,
						selectedToken,
						selectedToToken,
						weiAmountFrom,
						slippage,
						blockheight,
					);
					if (isMounted.current) {
						setTradeSteps(estimatedAmount.tradeSteps);
						setAmountTo(
							estimatedAmount.amountOutMin.toString() === '0'
								? ''
								: formatBN(
										estimatedAmount.amountOutMin,
										formatBNOptionsInputTo,
								  ),
						);
						setPriceImpact(estimatedAmount.priceImpact);
						setFee(
							toBN(weiAmountFrom)
								.mul(toBN(estimatedAmount.tradeSteps))
								.mul(BN_1000)
								.div(BN_3),
						);
					}
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error(err);
					alert(err.message, 'error');
					if (isMounted.current) {
						setAmountTo('');
						setPriceImpact({ from: 0, to: 0 });
						setFee(0);
					}
				}
			}
		}
	};

	useEffect(() => {
		// check undefined variables
		if (chainId === undefined || address === undefined) return;
		// At the moment, selectedToToken will not change from within the exchange page
		// but in case it does in the future, we need to recheck approval
		if (isMounted.current) {
			if (calcExactTarget) {
				estimateAmount(amountTo);
			} else {
				estimateAmountTo(amount);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [swapFromCoin, selectedToToken, chainId, address]);

	const logo = (
		<TokenImg
			className={clsx(classes.logo, {
				[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
			})}
			alt={getMainName(selectedToken.name, selectedToken.symbol)}
			src={selectedToken.image}
		>
			<TokenImg
				className={clsx(classes.logoUnknown, {
					[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
				})}
				alt={getMainName(selectedToken.name, selectedToken.symbol)}
				src={selectedToken.logoURI}
			>
				?
			</TokenImg>
		</TokenImg>
	);

	const getAmountWhileRemainingEnoughGas = newAmount => {
		if (coinPage && swapFromCoin) {
			let maxAvailable = balance.sub(currentGas);
			if (maxAvailable.lt(BN_ZERO)) {
				maxAvailable = BN_ZERO;
			}
			if (newAmount.gt(maxAvailable)) {
				newAmount = maxAvailable;
			}
		}
		return newAmount;
	};

	const defineAmount = async value => {
		setAmount(value);
		setCalcExactTarget(false);
		estimateAmountTo(value);
	};

	const defineToAmount = async value => {
		setAmountTo(value);
		setCalcExactTarget(true);
		estimateAmount(value);
	};

	const changeAmount = (value, reverse) => {
		if (value.endsWith(getNumberSeparators.groupSeparator)) {
			value = `${value.substr(0, value.length - 1)}${
				getNumberSeparators.decimalSeparator
			}`;
		}
		if (value === getNumberSeparators.decimalSeparator) {
			value = `0${getNumberSeparators.decimalSeparator}`;
		}
		const decimalIndex = value.indexOf(getNumberSeparators.decimalSeparator);
		if (
			isValidFloat(value, {
				onlyPositive: true,
				acceptTrailingDecimal: true,
			}) &&
			(decimalIndex === -1 ||
				decimalIndex >= value.length - selectedToken.decimals - 1)
		) {
			if (reverse) {
				defineToAmount(value);
			} else {
				defineAmount(value);
			}
		}
	};

	const handleChangeAmount = e => {
		changeAmount(e.target.value.trim(), false);
	};

	const handleChangeToAmount = e => {
		changeAmount(e.target.value.trim(), true);
	};

	const handleFocusAmount = () => {
		setCalcExactTarget(false);
		estimateAmountTo(amount);
	};

	const handleFocusAmountTo = () => {
		setCalcExactTarget(true);
		estimateAmount(amountTo);
	};

	const handleBlurAmount = () => {
		if (amount.endsWith(getNumberSeparators.decimalSeparator)) {
			setAmount(amount.substr(0, amount.length - 1));
		}
	};

	const handleAmountPercentage = percentage => {
		if (currentGas) {
			let newAmount = toBN(balance.toString());
			newAmount = newAmount.mul(toBN(percentage.toString())).div(BN_100);
			newAmount = getAmountWhileRemainingEnoughGas(newAmount);
			defineAmount(
				formatBN(newAmount, {
					assetDigits: selectedToken?.decimals,
				}).replaceAll(getNumberSeparators.groupSeparator, ''),
			);
		}
	};

	const handleBlurToAmount = () => {
		if (amountTo.endsWith(getNumberSeparators.decimalSeparator)) {
			setAmountTo(amountTo.substr(0, amountTo.length - 1));
		}
	};

	let fromToken;
	if (coinPage && tokenBalance.toString() !== '0') {
		fromToken = (
			<SelectNativeOrWrapped
				className={classes.selectTokenButton}
				disabled={!sendButtonEnabled}
				callBackResult={setSwapFromCoin}
				wrappedToken={selectedToken}
				coinSelected={swapFromCoin}
			/>
		);
	} else {
		fromToken = swapFromCoin
			? selectedToken.symbol.substr(1)
			: selectedToken.symbol;
	}

	let helperText;
	let classNameHelpText;
	if (!enoughBalance) {
		helperText = notEnoughBalanceText;
		classNameHelpText = classes.helperTextError;
	} else if (expertMode) {
		if (amount && amountTo && selectedToToken) {
			classNameHelpText = classes.helperTextWarning;
			if (typeof priceImpact.to === 'number') {
				if (priceImpact.from) {
					const priceBefore = getDexTokenPrice(selectedToken, currency, {
						minDigits: 3,
						decimals: 'auto',
					});
					const priceAfter = getDexTokenPrice(selectedToken, currency, {
						minDigits: 3,
						decimals: 'auto',
						priceImpactPercent: priceImpact.from,
					});
					const approximatelyPrice = priceImpact.notExactFrom ? '~' : '';
					helperText = `${t('page.exchangepage.price_impact_on')} ${getMainName(
						selectedToken.name,
						selectedToken.symbol,
					)}: ${priceBefore} ${currency.toUpperCase()} -> ${approximatelyPrice}${priceAfter} ${currency.toUpperCase()} (${priceImpact.from.toFixed(
						2,
					)}%)`;
				} else {
					helperText = `${t(
						'page.exchangepage.no_price_impact_on',
					)} ${getMainName(selectedToken.name, selectedToken.symbol)}`;
				}
			} else if (priceImpact.to === 'infinite') {
				helperText = `${getMainName(
					selectedToken.name,
					selectedToken.symbol,
				)} ${t('page.exchangepage.gets_infinite')}`;
			}
		} else {
			helperText = ' ';
		}
	}

	if (helperText) {
		helperText = (
			<div className={clsx('MuiFormHelperText-root', classNameHelpText)}>
				{helperText}
			</div>
		);
	}

	const balanceText = `${t('convert_token.balance')} ${formatBN(
		balance,
		formatBNOptions,
	)}`;

	const textFieldAmount = (
		<MuiTextField
			id="amount"
			className={classes.textFieldAmount}
			label={t(
				calcExactTarget
					? CALCULATE_MINIMUM_TRADE_AMOUNT
						? 'page.exchangepage.maximum_spent'
						: 'page.exchangepage.estimated_amount'
					: 'page.exchangepage.amount',
			)}
			disabled={balance.toString() === '0' || !sendButtonEnabled}
			placeholder={`0${getNumberSeparators.decimalSeparator}0`}
			variant="filled"
			autoFocus
			value={amount}
			onBlur={handleBlurAmount}
			onChange={handleChangeAmount}
			onFocus={handleFocusAmount}
			fullWidth
			error={!enoughBalance}
			// helperText={helperText}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Box
							className={classes.balance}
							color="text.secondary"
							dangerouslySetInnerHTML={{ __html: balanceText }}
						/>
						<Box className={classes.symbol} color="text.secondary">
							{fromToken}
						</Box>
					</InputAdornment>
				),
				disableUnderline: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);

	let toBalance;
	if (selectedToToken) {
		toBalance = `${t('convert_token.balance')} ${formatBN(
			balanceTo,
			formatBNOptionsTo,
		)}`;
	}

	let helperTextTo;
	if (expertMode) {
		if (amount && amountTo && selectedToToken) {
			if (typeof priceImpact.to === 'number') {
				if (priceImpact.to) {
					const priceBefore = getDexTokenPrice(selectedToToken, currency, {
						minDigits: 3,
						decimals: 'auto',
					});
					const priceAfter = getDexTokenPrice(selectedToToken, currency, {
						minDigits: 3,
						decimals: 'auto',
						priceImpactPercent: priceImpact.to,
					});
					const approximatelyPrice = priceImpact.notExactTo ? '~' : '';
					helperTextTo = `${t(
						'page.exchangepage.price_impact_on',
					)} ${getMainName(
						selectedToToken?.name,
						selectedToToken?.symbol,
					)}: ${priceBefore} ${currency.toUpperCase()} -> ${approximatelyPrice}${priceAfter} ${currency.toUpperCase()} (${priceImpact.to.toFixed(
						2,
					)}%)`;
				} else {
					helperTextTo = `${t(
						'page.exchangepage.no_price_impact_on',
					)} ${getMainName(selectedToToken?.name, selectedToToken?.symbol)}`;
				}
			} else if (priceImpact.to === 'infinite') {
				helperTextTo = `${getMainName(
					selectedToToken?.name,
					selectedToToken?.symbol,
				)} ${t('page.exchangepage.gets_infinite')}`;
			}
		} else {
			helperTextTo = ' ';
		}
	}

	const textFieldToAmount = (
		<MuiTextField
			id="est_amount"
			className={classes.textFieldToAmount}
			label={t(
				calcExactTarget
					? 'page.exchangepage.amount'
					: CALCULATE_MINIMUM_TRADE_AMOUNT
					? 'page.exchangepage.minimum_received'
					: 'page.exchangepage.estimated_amount',
			)}
			disabled={balance.toString() === '0' || !sendButtonEnabled}
			placeholder={`0${getNumberSeparators.decimalSeparator}0`}
			variant="filled"
			value={amountTo}
			onBlur={handleBlurToAmount}
			onChange={handleChangeToAmount}
			onFocus={handleFocusAmountTo}
			fullWidth
			error={!enoughBalance}
			helperText={helperTextTo}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Box
							className={classes.balance}
							color="text.secondary"
							dangerouslySetInnerHTML={{ __html: toBalance }}
						/>
						<SelectTokenList
							className={classes.selectTokenButton}
							callBackResult={setSelectedToToken}
							disabled={balance.toString() === '0' || !sendButtonEnabled}
						/>
					</InputAdornment>
				),
				disableUnderline: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);

	const percentagesButtons = (
		<div className={classes.percentageButtonGroup}>
			<Button
				className={clsx(
					classes.percentageButton,
					classes.percentageButtonFirst,
				)}
				disabled={balance.toString() === '0' || !sendButtonEnabled}
				variant="contained"
				onClick={() => handleAmountPercentage(25)}
				disableElevation
			>
				{t('convert_token.25%')}
			</Button>
			<Button
				className={classes.percentageButton}
				disabled={balance.toString() === '0' || !sendButtonEnabled}
				variant="contained"
				onClick={() => handleAmountPercentage(50)}
				disableElevation
			>
				{t('convert_token.50%')}
			</Button>
			<Button
				className={classes.percentageButton}
				disabled={balance.toString() === '0' || !sendButtonEnabled}
				variant="contained"
				onClick={() => handleAmountPercentage(75)}
				disableElevation
			>
				{t('convert_token.75%')}
			</Button>
			<Button
				className={clsx(classes.percentageButton, classes.percentageButtonLast)}
				disabled={balance.toString() === '0' || !sendButtonEnabled}
				variant="contained"
				onClick={() => handleAmountPercentage(100)}
				disableElevation
			>
				{t('convert_token.100%')}
			</Button>
		</div>
	);

	const handleAbort = () => {
		setPage(PAGES_NAMES.TOKEN);
	};

	const handleApprove = async () => {
		let tx;
		setSendButtonEnabled(false);
		try {
			tx = await approveMax(
				chainId,
				selectedToken.address,
				address,
				ROUTER_ADDRESSES[chainId],
				sendTx,
				gasprice,
				getExtraPercentageGas(),
				hardwareWallet,
			);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
			alert(err.message, 'error');
		}
		if (isMounted.current) {
			setSendButtonEnabled(true);
		}
		return tx;
	};

	const handleExchange = async () => {
		let tx;
		let scenario;
		if (coinPage && swapFromCoin) {
			scenario = 'CoinToToken';
		} else {
			scenario = selectedToToken?.isCoin ? 'tokenToCoin' : 'tokenToToken';
		}
		setSendButtonEnabled(false);
		try {
			if (scenario === 'tokenToToken') {
				if (calcExactTarget) {
					tx = await buyTokens(
						chainId,
						address,
						baseTradeToken,
						selectedToken,
						selectedToToken,
						preparedAmountTo,
						slippage,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				} else {
					tx = await sellTokens(
						chainId,
						address,
						baseTradeToken,
						selectedToken,
						selectedToToken,
						preparedAmount,
						slippage,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				}
			} else if (scenario === 'CoinToToken') {
				if (calcExactTarget) {
					tx = await buyTokensFromCoin(
						chainId,
						address,
						baseTradeToken,
						selectedToToken,
						preparedAmountTo,
						slippage,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				} else {
					tx = await sellCoins(
						chainId,
						address,
						baseTradeToken,
						selectedToToken,
						preparedAmount,
						slippage,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				}
			} else if (scenario === 'tokenToCoin') {
				if (calcExactTarget) {
					tx = await buyCoins(
						chainId,
						address,
						selectedToken,
						baseTradeToken,
						preparedAmountTo,
						slippage,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				} else {
					tx = await sellTokensForCoins(
						chainId,
						address,
						selectedToken,
						baseTradeToken,
						preparedAmount,
						slippage,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				}
			}
			// if (!hardwareWallet || hardwareStatusRef.current <= 1) {
			// 	setPage(PAGES_NAMES.TOKEN);
			// }
		} catch (err) {
			setSendButtonEnabled(true);
			let newError = err;
			if (typeof newError.message === 'string') {
				// this is weird: err.message can be a message (String) that looks like this:
				// "JSON-RPC error.... { code: xxx, message: ''}"
				// so we need to extract the error and errorcode manually:
				const indexCurlyBracket = newError.message.indexOf('{');
				if (indexCurlyBracket !== -1) {
					newError = newError.message.substring(indexCurlyBracket);
					try {
						newError = JSON.parse(newError);
					} catch (err2) {
						newError = err; // revert
						// eslint-disable-next-line no-console
						console.error(err2);
						alert(err2.message, 'error');
					}
				}
			}
			if (newError.code === -32000) {
				alert(
					'Swap reverted: someone else made a swap just before you',
					'error',
				); // reset
			} else {
				alert(err.message, 'error');
			}
		}
		if (isMounted.current) {
			setSendButtonEnabled(true);
		}
		return tx;
	};

	const exchangeButtonDisabled =
		!validAmount ||
		!selectedToToken ||
		selectedToken?.isBeingApproved ||
		wrappedToken?.exchangeButtonDisabled ||
		!amountTo ||
		(!hasFullAccess && wrappedToken?.isGoingToGetFullAccess) ||
		REGEXP_ONLYZEROS.test(amountTo);

	let approveText;
	let exchangeLabel;
	if (!hasFullAccess) {
		if (wrappedToken?.isGoingToGetFullAccess) {
			exchangeLabel = t('page.exchangepage.please_wait');
		} else {
			exchangeLabel = t('page.exchangepage.exchange');
		}
	} else if (selectedToken?.isBeingApproved) {
		exchangeLabel = t('page.exchangepage.pending');
	} else if (needsApproval && (!coinPage || !swapFromCoin)) {
		if (exchangeButtonDisabled) {
			exchangeLabel = t('page.exchangepage.exchange');
		} else {
			exchangeLabel = t('page.exchangepage.approve');
			approveText = (
				<div className={classes.warningDescription}>
					<ErrorOutlineOutlinedIcon className={classes.warningIcon} />
					<div className={classes.settingWrapper}>
						{t('page.exchangepage.approve_message', {
							values: {
								tokenname: selectedToken?.name,
								symbol: selectedToken?.symbol,
								exchange: DEXES_NAMES[chainId],
							},
						})}
					</div>
				</div>
			);
		}
	} else {
		exchangeLabel = t('page.exchangepage.exchange');
	}

	const handleExchangeButton = () => {
		// Show PromoPage if user has not a subscription
		if (!hasFullAccess) {
			promoformOpen(chainId);
		} else if (needsApproval && (!coinPage || !swapFromCoin)) {
			handleApprove();
		} else {
			handleExchange();
		}
	};

	const exchangeLogo = (
		<Tooltip
			title={DEXES_NAMES[chainId]}
			aria-label={DEXES_NAMES[chainId]}
			placement="top"
		>
			<TokenImg
				className={clsx(classes.exchangeLogo, {
					[classes.exchangeLogoDisabled]:
						exchangeButtonDisabled || !sendButtonEnabled,
				})}
				alt={DEXES_NAMES[chainId]}
				src={DEXES_ICON[chainId]}
			/>
		</Tooltip>
	);

	const abortOrExchange = (
		<div
			className={clsx(classes.buttonHorizontalGroup, classes.buttonFirstRow)}
		>
			<Button
				disabled={!sendButtonEnabled}
				variant="outlined"
				color="primary"
				size="large"
				disableElevation
				onClick={handleAbort}
			>
				{t('page.exchangepage.cancel')}
			</Button>
			<Button
				className={clsx({
					[classes.approvingButton]:
						selectedToken?.isBeingApproved ||
						wrappedToken?.isGoingToGetFullAccess,
				})}
				disabled={
					exchangeButtonDisabled || !sendButtonEnabled
					// (chainId === 10001 && ENVIRONMENT !== 'development')
				}
				onClick={handleExchangeButton}
				variant="outlined"
				color="primary"
				size="large"
				disableElevation
			>
				{exchangeLogo}
				{exchangeLabel}
			</Button>
		</div>
	);

	const TokenDescriptionName =
		coinPage && swapFromCoin
			? getMainName(selectedToken.name, selectedToken.symbol)
			: selectedToken.name;

	const tokenDescription = (
		<div className={classes.tokenDescription}>{TokenDescriptionName}</div>
	);

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider
				disabled={!sendButtonEnabled}
				value={gaspriceIndex}
				onChange={changeGaspriceIndex}
			/>
		);
	}

	const handleSettings = () => {
		setPage('SettingsPage');
	};

	let warningExpiredText;
	if (isSubscriptionExpired) {
		warningExpiredText = (
			<div className={classes.warningDescription}>
				<ErrorOutlineOutlinedIcon className={classes.criticalIcon} />
				<div className={classes.settingWrapper}>
					{t('page.exchangepage.warning_plan_has_expired')}{' '}
					<button
						type="button"
						className={classes.settingButton}
						onClick={handleSettings}
					>
						{t('page.exchangepage.setting')}
					</button>{' '}
					{t('page.exchangepage.section')}
				</div>
			</div>
		);
	}

	let warningTooMuchPriceMove;
	if (
		priceImpact?.from > WARN_PRICEIMPACT_ABOVE ||
		priceImpact?.to > WARN_PRICEIMPACT_ABOVE
	) {
		warningTooMuchPriceMove = (
			<div className={classes.warningDescription}>
				<ErrorOutlineOutlinedIcon className={classes.criticalIcon} />
				<div className={classes.settingWrapper}>
					{t('page.exchangepage.warning_too_much_price_movement')}
				</div>
			</div>
		);
	}

	let warningExchangeText;
	if (ETHEREUM_POW_CHAINIDS[chainId]) {
		warningExchangeText = (
			<div className={classes.warningDescription}>
				<ErrorOutlineOutlinedIcon className={classes.criticalIcon} />
				<div className={classes.settingWrapper}>
					{t('page.exchangepage.warning_tokens_ethereumpow')}
				</div>
			</div>
		);
	}

	return (
		<Box
			className={classes.root}
			autoComplete="off"
			component="form"
			noValidate
		>
			{logo}
			{tokenDescription}
			{textFieldAmount}
			{percentagesButtons}
			{helperText}
			{textFieldToAmount}
			{gaspriceSlider}
			{approveText}
			{warningExchangeText}
			{warningBalanceText}
			{warningExpiredText}
			{warningTooMuchPriceMove}
			{abortOrExchange}
		</Box>
	);
};

export default ExchangePage;
