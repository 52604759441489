import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Modal as MuiModal,
	IconButton,
	Fade,
	Backdrop as MuiBackdrop,
} from '@material-ui/core';
import {
	Close as CloseIcon,
	FileCopy as FileCopyIcon,
} from '@material-ui/icons';
import { copyToClipboard, useIntl } from '@itsa.io/web3utils';
import { TIMEOUT_COPY_MESSAGE } from 'config/constants';
import QRCode from 'qrcode.react';
import useAlert from 'hooks/useAlert';

import useStyles from 'styles/components/common/modals/QRCodeModal';

const QRCodeModal = ({ handleClose, show, title, address, symbol }) => {
	const { t } = useIntl();
	const alert = useAlert();
	const classes = useStyles();

	const qrCode = (
		<Box className={classes.qrCodeWrapper}>
			<QRCode value={address} size={150} />
		</Box>
	);

	const closeButton = (
		<IconButton
			className={classes.closeButton}
			color="primary"
			onClick={handleClose}
		>
			<CloseIcon color="primary" />
		</IconButton>
	);

	const header = (
		<header className={classes.header}>
			<h2 className={classes.title}>
				{t('receivetoaddress.heading', {
					values: { title, symbol },
				})}
			</h2>
		</header>
	);

	const copyAddress = () => {
		copyToClipboard(address);
		alert(t('page.sendtokenpage.copied'), {
			severity: 'info',
			timeout: TIMEOUT_COPY_MESSAGE,
		});
	};

	const copyBtn = (
		<IconButton
			className={classes.closeButton}
			color="primary"
			onClick={copyAddress}
		>
			<FileCopyIcon color="primary" />
		</IconButton>
	);

	return (
		<MuiModal
			open={show}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={MuiBackdrop}
			BackdropProps={{
				className: classes.backdrop,
			}}
		>
			<Fade
				timeout={{
					enter: 500,
					exit: 300,
				}}
				in={show}
			>
				<Box className={classes.container} color="text.primary">
					{closeButton}
					{header}
					<div className={classes.body}>
						{qrCode}
						<div className={classes.address}>
							{address}
							{copyBtn}
						</div>
					</div>
				</Box>
			</Fade>
		</MuiModal>
	);
};

QRCodeModal.defaultProps = {
	show: false,
	handleClose: null,
	address: '',
};

QRCodeModal.propTypes = {
	show: PropTypes.bool,
	handleClose: PropTypes.func,
	title: PropTypes.string.isRequired,
	symbol: PropTypes.string.isRequired,
	address: PropTypes.string,
};

export default QRCodeModal;
