import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	backdrop: {},
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 500,
		transform: 'translate(-50%, -50%)',
		outline: 0,
		background: theme.palette.background.light,
		borderRadius: 6,
		boxShadow: `rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;`,
		padding: theme.spacing(2),
	},
	header: {
		textAlign: 'center',
		outline: 0,
	},
	title: {
		fontSize: 20,
		fontWeight: 500,
		margin: 0,
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 14,
		fontWeight: 500,
		textAlign: 'center',
		// color: theme.palette.grey[500],
	},
	closeButton: {
		position: 'relative',
		marginBottom: 'auto',
		marginLeft: 'auto',
		zIndex: 2,
	},
	qrReaderWrapper: {
		maxWidth: 300,
		width: '100%',
		borderRadius: 6,
		padding: theme.spacing(2),
		// backgroundColor: theme.palette.common.white,
	},
	qrReader: {
		width: '100%',
	},
	qrReaderText: {
		margin: theme.spacing(2, 0, 0, 0),
	},
	qrReaderResult: {
		fontWeight: 500,
		textAlign: 'center',
		// color: theme.palette.common.white,
		fontSize: '75%',
		[theme.breakpoints.up('sm')]: {
			fontSize: 16,
		},
	},
}));
