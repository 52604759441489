import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		width: '100%',
		alignSelf: 'self-start',
	},
	title: {
		fontSize: 28,
		fontWeight: 500,
	},
	description: {
		fontSize: 14,
		marginBottom: theme.spacing(3),
	},
	separateLine: {
		position: 'relative',
		top: -1,
		borderColor: theme.palette.separate.main,
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	sectionTitle: {
		fontSize: 18,
		fontWeight: 500,
		textTransform: 'uppercase',
		color: theme.palette.grey[200],
		marginTop: theme.spacing(3),
	},
	gaspriceSlider: {
		width: '10rem',
		alignSelf: 'end',
		textAlign: 'right',
	},
	buttonIcon: {
		marginTop: theme.spacing(3),
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:hover $icon': {
			borderColor: theme.palette.secondary.dark,
			backgroundColor: theme.palette.default.light,
		},
		'&.Mui-disabled $icon': {
			opacity: 0.25,
		},
		fontSize: 14,
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column',
			textTransform: 'uppercase',
		},
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(0),
		},
	},
	buttonIconActive: {
		backgroundColor: 'transparent',
		'& $icon': {
			borderColor: theme.palette.secondary.dark,
			backgroundColor: theme.palette.default.light,
		},
	},
	icon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 50,
		height: 50,
		backgroundColor: theme.palette.default.light,
		border: `1px solid ${theme.palette.default.light}`,
		borderRadius: 12,
		padding: theme.spacing(1),
		margin: theme.spacing(0, 1),
	},
	iconImgUnknown: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.secondary.light,
	},
	subscriptionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginBottom: theme.spacing(3),
	},
	networkInfo: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '1rem',
		// marginBottom: theme.spacing(3),
		// color: theme.palette.red[300],
		color: theme.palette.text.primary,
	},
	subscriptionInfo: {
		display: 'flex',
		// justifyContent: 'center',
		// alignItems: 'center',
		fontSize: '1.2rem',
		marginTop: theme.spacing(3),
		color: theme.palette.red[300],
		// backgroundColor: theme.palette.primary.main,
		// boxShadow: `inset 0 0 6px ${alpha(theme.palette.common.black, 0.5)}`,
	},
	optionContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: 400,
		padding: theme.spacing(3),
		backgroundColor: theme.palette.default.light,
		border: `1px solid ${alpha(theme.palette.default.main, 0.5)}`,
		borderRadius: 6,
		marginTop: theme.spacing(3),
	},
	optionLabel: {
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: '1rem',
		fontWeight: 500,
	},
	upgradeButton: {
		marginLeft: 'auto',
		borderWidth: 1,
		marginTop: theme.spacing(2),
	},
	price: {
		fontSize: '1rem',
		fontWeight: 500,
		textAlign: 'right',
		// fontWeight: 500,
	},
	priceFiat: {
		fontSize: '0.9rem',
		fontWeight: 400,
		color: theme.palette.text.secondary,
	},
	currency: {
		fontSize: '0.8em',
		textTransform: 'uppercase',
		marginLeft: '0.15rem',
		fontWeight: 400,
	},
	planDescription: {
		fontSize: '1rem',
		color: theme.palette.grey[200],
	},
	planPriceChange: {
		fontSize: '1rem',
		color: theme.palette.grey[200],
	},
	extendDays: {
		fontSize: '1rem',
		fontWeight: 400,
		color: theme.palette.primary.main,
	},
}));
