import React, { useContext, useState, useEffect, useRef } from 'react';
import {
	FormControlLabel,
	Checkbox,
	TextField,
	InputAdornment,
} from '@itsa.io/ui';
import {
	Visibility as VisibilityIcon,
	VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { useToggle } from 'react-use';
import { useIntl } from '@itsa.io/web3utils';
import locktimerCtx from 'context/locktimer';
import useStyles from 'styles/components/settings/ScreenLock';

const REG_EXP_NUMBER = /^\d{0,3}$/;

const WalletLock = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const mounted = useRef(false);
	const locktimeRef = useRef();
	const lockpasswordRef = useRef();
	const [visible, toggleVisible] = useToggle(false);
	const {
		activated,
		setActivated,
		locktimeMin,
		setLocktimeMin,
		password,
		setPassword,
	} = useContext(locktimerCtx);
	const [locktime, setLocktime] = useState(locktimeMin);
	const locktimeValueRef = useRef(locktime);

	const saveLockTime = forcedTime => {
		const newTime =
			typeof forcedTime === 'string' || typeof forcedTime === 'number'
				? forcedTime
				: locktime;

		if (newTime) {
			// only saving when not empty
			setLocktimeMin(
				typeof newTime === 'string' ? parseInt(newTime, 10) : newTime,
			);
		} else if (mounted.current) {
			// empty string: reset local locktime:
			setLocktime(locktimeMin);
		}
	};

	const handleBlurPassword = () => {
		if (visible) {
			toggleVisible();
		}
	};

	const handleChangeChecked = event => {
		const { checked } = event.target;
		setActivated(checked);
	};

	const handleChangePassword = event => {
		const { value } = event.target;
		setPassword(value);
	};

	const handleChangeLocktime = event => {
		const { value } = event.target;
		if (REG_EXP_NUMBER.test(value)) {
			setLocktime(value);
		}
	};

	const handleKeyDownLocktime = e => {
		if (e.keyCode === 13) {
			saveLockTime();
			const inputNode = locktimeRef.current.querySelector('input');
			if (inputNode) {
				inputNode.blur();
			}
		}
	};

	const handleKeyDownPassword = e => {
		if (e.keyCode === 13) {
			const inputNode = lockpasswordRef.current.querySelector('input');
			if (inputNode) {
				inputNode.blur();
			}
		}
	};

	useEffect(() => {
		locktimeValueRef.current = locktime;
	}, [locktime]);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
			saveLockTime(locktimeValueRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let inputType;
	let visibility;
	if (visible) {
		visibility = <VisibilityIcon />;
		inputType = 'text';
	} else {
		visibility = <VisibilityOffIcon />;
		inputType = 'password';
	}

	return (
		<>
			<div className={classes.lockControl}>
				<FormControlLabel
					className={classes.formControlLabel}
					control={
						<Checkbox
							checked={activated}
							onChange={handleChangeChecked}
							color="primary"
						/>
					}
					label={t('settings.screen_lock.lock_after')}
				/>

				<TextField
					className={classes.textField}
					disabled={!activated}
					variant="outlined"
					onBlur={saveLockTime}
					onChange={handleChangeLocktime}
					onKeyDown={handleKeyDownLocktime}
					ref={locktimeRef}
					value={locktime}
				/>
				{t('settings.screen_lock.minutes')}
			</div>
			<TextField
				className={classes.passwordField}
				variant="outlined"
				onBlur={handleBlurPassword}
				onChange={handleChangePassword}
				onKeyDown={handleKeyDownPassword}
				type={inputType}
				value={password}
				ref={lockpasswordRef}
				InputProps={{
					endAdornment: (
						<InputAdornment
							className={classes.eye}
							onClick={toggleVisible}
							position="end"
						>
							{visibility}
						</InputAdornment>
					),
				}}
			/>
		</>
	);
};

export default WalletLock;
