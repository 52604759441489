import React from 'react';
import { Slider } from '@itsa.io/ui';
import useStyles from 'styles/components/common/SliderSteps';

const SliderSteps = props => {
	const classes = useStyles();

	return <Slider className={classes.root} {...props} />;
};

SliderSteps.defaultProps = {};

SliderSteps.propTypes = {};

export default SliderSteps;
