import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		width: '100%',
		minHeight: 445,
		margin: 'auto',
		padding: theme.spacing(3),
		background: theme.palette.background.light,
		borderRadius: 6,
		boxShadow: `rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;`,
		overflow: 'hidden',
		// zIndex: theme.zIndex.drawer + 1,
		[theme.breakpoints.up('sm')]: {
			maxWidth: 570,
			minHeight: 700,
		},
	},
	red: {
		color: theme.palette.red[900],
	},
	green: {
		color: theme.palette.green[900],
	},
	nftContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: 16,
	},
	title: {
		fontSize: 20,
		fontWeight: 500,
		color: theme.palette.grey[50],
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	separateLine: {
		borderColor: theme.palette.default.light,
	},
	hiddenItem: {
		display: 'none!important',
	},
	item: {
		padding: theme.spacing(0, 1, 0, 0),
		// border: `1px solid ${theme.palette.common.white}`,
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent!important',
		},
		'&:focus': {
			backgroundColor: 'transparent!important',
		},
		'&.Mui-selected': {
			backgroundColor: 'transparent!important',
		},
	},
	criticalDescription: {
		display: 'flex',
		fontSize: '0.85rem',
		textAlign: 'left',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(3),
	},
	criticalIcon: {
		fontSize: '1.2rem',
		color: theme.palette.red[500],
		marginRight: theme.spacing(1),
	},
	warningIcon: {
		fontSize: '1.2rem',
		color: theme.palette.primary.main,
		marginRight: theme.spacing(1),
	},
	addToken: {
		border: 0,
		borderRadius: 3,
		// maxWidth: 507,
		margin: theme.spacing(0, 0, 2, 0),
	},
	itemSymbol: {
		fontWeight: 500,
		color: theme.palette.grey[500],
	},
	iconButton: {
		marginLeft: 'auto',
		color: theme.palette.grey[200],
		'&:hover': {
			color: theme.palette.grey[300],
			backgroundColor: `${alpha(theme.palette.grey[50], 0.8)}`,
		},
	},
	containerItem: {
		display: 'grid',
		gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
		padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
		border: `1px solid ${theme.palette.common.white}`,
		columnGap: '0.75rem',
		borderRadius: 3,
		backgroundColor: theme.palette.grey[50],
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		'&:hover': {
			backgroundColor: `${alpha(theme.palette.grey[50], 0.8)}!important`,
		},
		'&:hover $iconButton': {
			color: theme.palette.grey[300],
		},
		'&:focus': {
			backgroundColor: `${alpha(theme.palette.grey[50], 0.8)}!important`,
		},
		'&.Mui-selected': {
			backgroundColor: `${alpha(theme.palette.grey[50], 0.8)}!important`,
		},
	},
	editTextFieldAddress: {
		'& .MuiInputBase-input': {
			color: theme.palette.grey[50],
		},
		'& .MuiInputBase-input.Mui-disabled': {
			cursor: 'pointer',
		},
	},
	editContainerItem: {
		display: 'flex',
	},
	editIconsWrap: {
		display: 'flex',
		marginLeft: 'auto',
		paddingLeft: theme.spacing(1),
		columnGap: theme.spacing(1),
	},
	editIconButton: {
		color: theme.palette.grey[200],
		'&:hover': {
			color: theme.palette.grey[50],
		},
	},
	textFieldAddress: {
		gridColumn: 'span 4 / span 4',
		color: theme.palette.grey[500],
		'& .MuiInputBase-root': {
			'& input': {
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
		},
		'& .MuiInputBase-input.Mui-disabled': {
			cursor: 'pointer',
		},
	},
	textFieldSearch: {
		margin: theme.spacing(0, 0, 4, 0),
		'& .MuiFormLabel-root': {
			color: theme.palette.grey[100],
		},
		'& .MuiInputBase-root, & .MuiInputBase-root:hover': {
			border: `1px solid ${alpha(theme.palette.grey[500], 0.5)}`,
			backgroundColor: alpha(theme.palette.grey[500], 0.3),
			'&.Mui-focused': {
				border: `1px solid ${alpha(theme.palette.grey[500], 0.5)}`,
				backgroundColor: alpha(theme.palette.grey[500], 0.3),
			},
		},
		'& .MuiFilledInput-input': {
			fontSize: 16,
			fontWeight: 500,
			color: theme.palette.grey[50],
		},
	},
	textFieldDisabled: {
		'& .MuiInputBase-root': {
			color: theme.palette.grey[400],
			backgroundColor: 'transparent',
		},
		'& .MuiFilledInput-input': {
			cursor: 'pointer',
			fontWeight: 500,
		},
	},
	searchIcon: {
		color: theme.palette.grey[50],
	},
	busy: {
		paddingRight: 24,
		'& .MuiButton-label': {
			width: 'auto',
			'&::after': {
				position: 'relative',
				content: '" ."',
				animation: `$dotAnim 1s steps(5, end) infinite`,
				fontSize: 25,
				lineHeight: 1,
			},
		},
	},
	'@keyframes dotAnim': {
		'0%, 20%': {
			color: 'rgba(0,0,0,0)',
			textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
		},
		'40%': {
			color: theme.palette.primary.main,
			textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
		},
		'60%': {
			textShadow: `.25em 0 0 ${theme.palette.primary.main}, .5em 0 0 rgba(0,0,0,0)`,
		},
		'80%, 100%': {
			textShadow: `.25em 0 0 ${theme.palette.primary.main}, .5em 0 0 ${theme.palette.primary.main}`,
		},
	},
}));
