/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import selectedtokenCtx from 'context/selectedtoken';
import wrappedtokenCtx from 'context/wrappedtoken';
import navigatorCtx from 'context/navigator';
import gaspriceCtx from 'context/gasprice';
import {
	Box,
	Button,
	IconButton,
	TextField as MuiTextField,
	InputAdornment,
	Avatar as TokenImg,
} from '@material-ui/core';
import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from '@itsa.io/ui/icons';
import {
	CropFree as CropFreeIcon,
	ContactMail as ContactMailIcon,
} from '@material-ui/icons';
import { useIntl, formatBN, toBN, cryptowalletCtx } from '@itsa.io/web3utils';
import contactsCtx from 'context/contacts';
import securedsendtxCtx from 'context/securedsendtx';
import useAlert from 'hooks/useAlert';
import {
	PAGES_NAMES,
	NETWORK_NAMES,
	CHAIN_SYMBOLS,
	getMainName,
	BN_ZERO,
	BN_GAS_SEND_COIN,
	WRAPPED_ADDRESSES,
	GAS_PERCENTAGES,
} from 'config/constants';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import getNumberSeparators from 'utils/get-number-separators';
import { isValidFloat } from 'utils/validate-numeric-input';
import { transfer } from 'utils/smartcontracts/token';
import transferCoin from 'utils/transferCoins';
import GasSlider from 'components/common/GasSlider';
import ContactManager from 'components/common/ContactManager';
import QRCodeReaderModal from 'components/common/modals/QRCodeReaderModal';
import { utils } from 'web3';
import addressMatch from 'utils/address-match';

import useStyles from 'styles/pages/SendPage';

const { isBN } = utils;

const REGEXP_ONLYZEROS = /^0*[,|.]?0*$/;
const REGEXP_ADDRESS_TYPING = /^0x[0-9a-fA-F]*$/;

const hasSendPermission = (chainId, coinPage, selectedToken) => {
	if (chainId === 1) {
		return (
			coinPage ||
			!addressMatch(selectedToken.address, WRAPPED_ADDRESSES[chainId])
		);
	}
	if (chainId === 56) {
		return false;
	}
	if (chainId === 39797) {
		return coinPage;
	}
	return false;
};

const needsSendWarning = (chainId, coinPage, selectedToken) => {
	if (chainId === 1) {
		return (
			!coinPage &&
			addressMatch(selectedToken.address, WRAPPED_ADDRESSES[chainId])
		);
	}
	if (chainId === 56) {
		return true;
	}
	if (chainId === 10001) {
		return !coinPage;
	}
	if (chainId === 39797) {
		return !coinPage;
	}
	return false;
};

const SendPage = ({ coinPage }) => {
	const classes = useStyles();
	const { t } = useIntl();
	const { chainId, address, hardwareWallet } = useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const networkName = NETWORK_NAMES[chainId];
	const coinSymbol = CHAIN_SYMBOLS[chainId];
	const alert = useAlert();
	const { selectedToken } = useContext(selectedtokenCtx);
	const wrappedToken = useContext(wrappedtokenCtx);
	const { gasprice } = useContext(gaspriceCtx);
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const { setPage } = useContext(navigatorCtx);
	const [amount, setAmount] = useState('');
	const [recipient, setRecipient] = useState('');
	const [currentGas, setCurrentGas] = useState(BN_ZERO);
	const [showContacts, setShowContacts] = useState(false);
	const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
	const [openQrReaderModal, setOpenQrReaderModal] = useState(false);
	const isMounted = useRef(false);
	const { findContactName, isSecuredAddress, addContact } =
		useContext(contactsCtx);
	const validRecipient = utils.isAddress(recipient);
	const usedBalance = coinPage
		? selectedToken.coinBalance
		: selectedToken.balance;
	const balance = isBN(usedBalance) ? usedBalance : toBN('0');
	const formatBNOptions = {
		assetDigits: selectedToken?.decimals,
		formatHTML: true,
	};
	const canSendToCentralizedExchange = hasSendPermission(
		chainId,
		coinPage,
		selectedToken,
	);
	const sendWarning = needsSendWarning(chainId, coinPage, selectedToken);
	const isWrappedToken = addressMatch(
		selectedToken.address,
		WRAPPED_ADDRESSES[chainId],
	);
	let validAmount = !REGEXP_ONLYZEROS.test(amount);
	let enoughBalance = true;
	let targetPermitted = true;
	let notEnoughBalanceText;
	let helperRecipientText;
	let warningBalanceText;
	let warningExchangeText;
	let securedAddress;

	const TokenDescriptionName = coinPage
		? getMainName(selectedToken.name, selectedToken.symbol)
		: selectedToken.name;

	const tokenDescription = (
		<Box className={classes.tokenDescription}>{TokenDescriptionName}</Box>
	);

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	const getPreparedAmount = () => {
		let preparedAmount = validAmount ? amount : '0';
		if (!preparedAmount.includes(getNumberSeparators.decimalSeparator)) {
			preparedAmount = preparedAmount.padEnd(
				preparedAmount.length + selectedToken.decimals,
				'0',
			);
		} else {
			// append with '0'
			const decimalIndex = preparedAmount.indexOf(
				getNumberSeparators.decimalSeparator,
			);
			preparedAmount = preparedAmount.padEnd(
				selectedToken.decimals + decimalIndex + 1,
				'0',
			);
			preparedAmount =
				preparedAmount.substr(0, decimalIndex) +
				preparedAmount.substr(decimalIndex + 1);
			// remove prepended zeros:
			while (preparedAmount[0] === '0') {
				preparedAmount = preparedAmount.substring(1);
			}
		}
		return preparedAmount;
	};

	if (validAmount) {
		// check is amount is not bigger than available
		const preparedAmount = getPreparedAmount();
		let maxAvailable = balance.sub(currentGas);
		if (maxAvailable.lt(BN_ZERO)) {
			maxAvailable = BN_ZERO;
		}
		enoughBalance = toBN(preparedAmount).lte(coinPage ? maxAvailable : balance);
		const enoughBalanceDisregardingGas = toBN(preparedAmount).lte(balance);
		validAmount = enoughBalance;
		if (!enoughBalance) {
			notEnoughBalanceText = enoughBalanceDisregardingGas
				? t('page.sendtokenpage.not_enough_balance_for_gas')
				: t('page.sendtokenpage.not_enough_balance');
		}
	}

	if (wrappedToken) {
		const criticalBalance = wrappedToken.belowCriticalBalance(
			coinPage && validAmount
				? toBN(getPreparedAmount()).add(currentGas)
				: currentGas,
			gasprice,
		);

		if (criticalBalance && enoughBalance) {
			warningBalanceText = (
				<div className={classes.criticalBalanceDescription}>
					<ErrorOutlineOutlinedIcon className={classes.warningIcon} />
					<div>
						{t('page.sendtokenpage.careful_sending_not_enough_balance', {
							values: { networkName, coinSymbol },
						})}
					</div>
				</div>
			);
		}
	}

	if (sendWarning) {
		const { name, symbol: tokenSymbol } = selectedToken;
		let tokenName = '';
		let warningMessage = '';
		let wrappedSymbol = '';
		let coinSymbol = '';

		if (isWrappedToken) {
			tokenName = TokenDescriptionName;
			coinSymbol = CHAIN_SYMBOLS[chainId];
			wrappedSymbol = `W${coinSymbol}`;
			if (coinPage) {
				warningMessage = `page.sendtokenpage.careful_not_send_coin_to_exchange_${chainId}`;
			} else {
				warningMessage =
					'page.sendtokenpage.careful_not_send_wrapped_coin_to_exchange';
			}
		} else {
			tokenName = getMainName(name, tokenSymbol);
			warningMessage = `page.sendtokenpage.careful_not_send_token_to_exchange_chainid_${chainId}`;
		}

		warningExchangeText = (
			<div className={classes.sendWarningDescription}>
				<ErrorOutlineOutlinedIcon className={classes.warningIcon} />
				<div>
					{t(warningMessage, {
						values: {
							tokenName,
							tokenSymbol,
							networkName,
							coinSymbol,
							wrappedSymbol,
						},
					})}
				</div>
			</div>
		);
	}

	const defineCurrentGas = async () => {
		try {
			if (gasprice) {
				let gasBN = gasprice.mul(BN_GAS_SEND_COIN);
				if (hardwareWallet) {
					gasBN = gasBN
						.mul(toBN((100 + getExtraPercentageGas()).toString()))
						.mul(toBN('22')) // an extra 2.2 because of GAS_LIMIT_SAFETY_MULTIPLIER_HARDWARE_DEVICES which is 2, and it seems we need a bit more
						.div(toBN('1000'));
				}
				if (isMounted.current) {
					setCurrentGas(gasBN);
				}
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		defineCurrentGas();
		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		defineCurrentGas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gasprice, gaspriceIndex]);

	const logo = (
		<TokenImg
			className={clsx(classes.logo, {
				[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
			})}
			alt={getMainName(selectedToken.name, selectedToken.symbol)}
			src={selectedToken.image}
		>
			<TokenImg
				className={clsx(classes.logoUnknown, {
					[classes.pendingTransaction]: selectedToken.hasPendingTransaction,
				})}
				alt={getMainName(selectedToken.name, selectedToken.symbol)}
				src={selectedToken.logoURI}
			>
				?
			</TokenImg>
		</TokenImg>
	);

	const handleChangeAmount = e => {
		let value = e.target.value.trim();
		if (value.endsWith(getNumberSeparators.groupSeparator)) {
			value = `${value.substr(0, value.length - 1)}${
				getNumberSeparators.decimalSeparator
			}`;
		}
		if (value === getNumberSeparators.decimalSeparator) {
			value = `0${getNumberSeparators.decimalSeparator}`;
		}
		const decimalIndex = value.indexOf(getNumberSeparators.decimalSeparator);
		if (
			isValidFloat(value, {
				onlyPositive: true,
				acceptTrailingDecimal: true,
			}) &&
			(decimalIndex === -1 ||
				decimalIndex >= value.length - selectedToken.decimals - 1)
		) {
			// just "number" type for Input, will also accept 'e'; needed finer restriction
			setAmount(value);
		}
	};

	const handleBlurAmount = () => {
		if (amount.endsWith(getNumberSeparators.decimalSeparator)) {
			setAmount(amount.substr(0, amount.length - 1));
		}
	};

	const handleMaxAmountClick = async () => {
		if (currentGas) {
			let amount = coinPage ? balance.sub(currentGas) : balance;
			if (amount.lt(BN_ZERO)) {
				amount = BN_ZERO;
			}
			setAmount(
				formatBN(amount, { assetDigits: selectedToken?.decimals }).replaceAll(
					getNumberSeparators.groupSeparator,
					'',
				),
			);
		}
	};

	const handleChangeRecipient = e => {
		const value = e.target.value.trim();
		if (value === '' || value === '0' || REGEXP_ADDRESS_TYPING.test(value)) {
			setRecipient(value);
		}
	};

	const handleGetCameraQR = () => {
		setOpenQrReaderModal(true);
	};

	const handleQrReaderModalClose = () => {
		setOpenQrReaderModal(false);
	};

	const handleQrReaderCode = qrCode => {
		setRecipient(qrCode);
		handleQrReaderModalClose();
	};

	const textFieldAmount = (
		<MuiTextField
			id="amount"
			className={classes.textFieldAmount}
			label={t('page.sendtokenpage.amount')}
			disabled={balance.toString() === '0'}
			placeholder={`0${getNumberSeparators.decimalSeparator}0`}
			variant="filled"
			autoFocus
			value={amount}
			onBlur={handleBlurAmount}
			onChange={handleChangeAmount}
			fullWidth
			error={!enoughBalance}
			helperText={notEnoughBalanceText}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Box className={classes.balance} color="text.secondary">
							{t('convert_token.balance')}{' '}
							<span
								dangerouslySetInnerHTML={{
									__html: formatBN(balance, formatBNOptions),
								}}
							/>
						</Box>
						<Box className={classes.symbolMaxAmount}>
							<Box color="secondary.dark">
								{coinPage
									? selectedToken.symbol.substr(1)
									: selectedToken.symbol}
							</Box>
							<Button
								className={classes.buttonMax}
								disabled={balance.toString() === '0'}
								variant="contained"
								color="primary"
								size="small"
								disableElevation
								onClick={handleMaxAmountClick}
							>
								{t('convert_token.button_max')}
							</Button>
						</Box>
					</InputAdornment>
				),
				disableUnderline: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);

	if (validRecipient) {
		securedAddress = isSecuredAddress(recipient);
		targetPermitted = canSendToCentralizedExchange || !securedAddress;
		if (!targetPermitted) {
			helperRecipientText = t(
				'page.sendtokenpage.shielded_address_no_permission',
			);
		} else {
			helperRecipientText = findContactName(recipient) || recipient;
		}
	}

	const handleOpenContacts = () => {
		setShowContacts(true);
	};

	const handleCloseContacts = () => {
		setShowContacts(false);
	};

	const textFieldRecipient = (
		<MuiTextField
			id="recipient"
			className={classes.textFieldRecipient}
			label={t('page.sendtokenpage.recipient')}
			disabled={balance.toString() === '0'}
			placeholder="0x..."
			variant="filled"
			value={recipient}
			onChange={handleChangeRecipient}
			fullWidth
			error={!targetPermitted}
			helperText={helperRecipientText}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							className={classes.iconButton}
							color="primary"
							onClick={handleOpenContacts}
						>
							<ContactMailIcon color="primary" />
						</IconButton>
						<IconButton
							className={classes.iconButton}
							color="primary"
							disabled={balance.toString() === '0'}
							onClick={handleGetCameraQR}
						>
							<CropFreeIcon color="primary" />
						</IconButton>
					</InputAdornment>
				),
				disableUnderline: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);

	const handleAbort = () => {
		setPage(PAGES_NAMES.TOKEN);
	};

	const handleSend = async () => {
		let tx;
		addContact(recipient);
		setSendButtonEnabled(false);
		try {
			const preparedAmount = getPreparedAmount();
			if (coinPage) {
				tx = await transferCoin(
					chainId,
					address,
					recipient,
					preparedAmount,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
			} else {
				tx = await transfer(
					chainId,
					selectedToken.address,
					address,
					recipient,
					preparedAmount,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
			}
		} catch (err) {
			alert(err.message, 'error');
		}
		if (isMounted.current) {
			setSendButtonEnabled(true);
		}
		return tx;
	};

	const abortOrSend = (
		<Box
			className={clsx(classes.buttonHorizontalGroup, classes.buttonFirstRow)}
		>
			<Button
				disabled={!sendButtonEnabled}
				variant="outlined"
				size="large"
				color="primary"
				onClick={handleAbort}
			>
				{t('page.sendtokenpage.cancel')}
			</Button>
			<Button
				disabled={
					!validAmount ||
					!validRecipient ||
					!targetPermitted ||
					!sendButtonEnabled
				}
				onClick={handleSend}
				variant="outlined"
				size="large"
				color="primary"
			>
				{t('page.sendtokenpage.send')}
			</Button>
		</Box>
	);

	const handleSelectContact = selectedContact => {
		setRecipient(selectedContact.address);
	};

	const contactManager = (
		<Box mb={1.5}>
			<ContactManager
				onClose={handleCloseContacts}
				onSelect={handleSelectContact}
				open={showContacts}
			/>
		</Box>
	);

	const qrReaderModal = (
		<QRCodeReaderModal
			handleClose={handleQrReaderModalClose}
			show={openQrReaderModal}
			callBackResult={handleQrReaderCode}
		/>
	);

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider value={gaspriceIndex} onChange={changeGaspriceIndex} />
		);
	}

	return (
		<Box
			className={classes.root}
			autoComplete="off"
			component="form"
			noValidate
		>
			{logo}
			{tokenDescription}
			{textFieldAmount}
			{textFieldRecipient}
			{gaspriceSlider}
			{warningBalanceText}
			{warningExchangeText}
			{abortOrSend}
			{contactManager}
			{qrReaderModal}
		</Box>
	);
};

SendPage.propTypes = {
	coinPage: PropTypes.bool.isRequired,
};

export default SendPage;
