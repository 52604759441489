import React, { useState, useEffect, useContext, useRef } from 'react';
import { later } from '@itsa.io/web3utils/lib/timers';
import DonutChart from 'components/common/DonutChart';
// import TransactionHistory from 'components/common/TransactionHistory';
import showpossiblespamtokensCtx from 'context/showpossiblespamtokens';
import tokenListCtx from 'context/tokenlist';
import currencyCtx from 'context/currency';
import wrappedtokenCtx from 'context/wrappedtoken';
import { toFiat, toFiatFromDexPrices } from 'utils/tokenpriceToFiat';
import addressMatch from 'utils/address-match';
import { useIntl, cryptowalletCtx, toBN, formatBN } from '@itsa.io/web3utils';
import { utils } from 'web3';
import { WRAPPED_ADDRESSES, getMainSymbol } from 'config/constants';
import useStyles from 'styles/pages/DashboardPage';
import { cloneDeep, isEqual } from 'lodash';

const { isBN } = utils;
const BN_ZERO = toBN('0');
const BN_10000 = toBN('10000');
const UPDATE_DELAY_MS = 500; // to prevent the chart from rerendering while multiple data gets generated sequentially

const legendHeaders = ['rect', 'name', 'value', 'info'];

const coinHasBalance = (token, wrappedTokenAddress) => {
	if (!token) {
		return false;
	}
	const isCoin = addressMatch(token.address.toLowerCase(), wrappedTokenAddress);
	if (!isCoin) {
		return false;
	}

	// check coin balance
	let balance = isBN(token.coinBalance) ? token.coinBalance : BN_ZERO;
	if (balance.toString() !== '0') {
		return true;
	}

	// check wrapped token balance
	balance = isBN(token.balance) ? token.balance : BN_ZERO;
	if (balance.toString() !== '0') {
		return true;
	}

	// check collateral balance
	balance = isBN(token.collateral) ? token.collateral : BN_ZERO;
	if (balance.toString() !== '0') {
		return true;
	}
	return false;
};

const extractPrices = list => {
	return list.map(item => {
		return {
			coin: item.coin ? item.coin.toString() : null,
			coinMn: item.coinMn ? item.coinMn.toString() : null,
			coinWrap: item.coinWrap ? item.coinWrap.toString() : null,
			valueBN: item.valueBN ? item.valueBN.toString() : null,
		};
	});
};

const DashboardPage = () => {
	const classes = useStyles();
	// const theme = useTheme();
	const { t } = useIntl();
	// const mediumScreenSize = useMediaQuery(theme.breakpoints.up('md'));
	const { chainId } = useContext(cryptowalletCtx);
	const {
		data: tokenListWitSpamTokens,
		// error,
		isLoading: loading,
	} = useContext(tokenListCtx);
	const { showPossibleSpamTokens } = useContext(showpossiblespamtokensCtx);
	const { currency } = useContext(currencyCtx);
	const wrappedtoken = useContext(wrappedtokenCtx);
	const pricesRef = useRef([]);
	const timerRef = useRef();
	const timer2Ref = useRef();
	const isMounted = useRef(false);
	const [chartData, setChartData] = useState([]);
	const [chartTotalAccBalance, setChartTotalAccBalance] = useState(BN_ZERO);

	let totalAccBalance = BN_ZERO;

	const tokenList = showPossibleSpamTokens
		? tokenListWitSpamTokens
		: tokenListWitSpamTokens.filter(token => !token.possibleSpam);

	// const qrCodeSize = mediumScreenSize ? 130 : 85;

	// const qrCode = address && (
	// 	<div className={classes.qrCodeWrapper}>
	// 		<QRCode value={address} size={qrCodeSize} />
	// 	</div>
	// );

	// convert tokens in percent
	const getTokensInPercent = data => {
		return data.map(token => {
			const newData = cloneDeep(token);
			const { valueBN, isCoin } = newData;
			const tokenPercent2Dec =
				totalAccBalance.toString() === '0'
					? BN_10000
					: BN_10000.mul(valueBN).div(totalAccBalance);
			const tokenPercentRounded2Dec = formatBN(tokenPercent2Dec, {
				assetDigits: 2, // toFiat generates 18 assetDigits
				minDigits: 3,
				decimals: 2,
			});
			newData.value = parseFloat(tokenPercentRounded2Dec.replaceAll(',', '.'));

			if (isCoin) {
				const { coin, coinWrap, coinMn } = token;
				let tokenPercent2Dec;
				let tokenPercentRounded2Dec;

				if (coin.value.toString() !== '0') {
					tokenPercent2Dec =
						totalAccBalance.toString() === '0'
							? BN_10000
							: BN_10000.mul(coin.valueBN).div(totalAccBalance);
					tokenPercentRounded2Dec = formatBN(tokenPercent2Dec, {
						assetDigits: 2, // toFiat generates 18 assetDigits
						minDigits: 3,
						decimals: 2,
					});
					newData.coin.value = parseFloat(
						tokenPercentRounded2Dec.replaceAll(',', '.'),
					);
				}

				if (coinWrap.value.toString() !== '0') {
					tokenPercent2Dec =
						totalAccBalance.toString() === '0'
							? BN_10000
							: BN_10000.mul(coinWrap.valueBN).div(totalAccBalance);
					tokenPercentRounded2Dec = formatBN(tokenPercent2Dec, {
						assetDigits: 2, // toFiat generates 18 assetDigits
						minDigits: 3,
						decimals: 2,
					});
					newData.coinWrap.value = parseFloat(
						tokenPercentRounded2Dec.replaceAll(',', '.'),
					);
				}

				if (coinMn.value.toString() !== '0') {
					tokenPercent2Dec =
						totalAccBalance.toString() === '0'
							? BN_10000
							: BN_10000.mul(coinMn.valueBN).div(totalAccBalance);
					tokenPercentRounded2Dec = formatBN(tokenPercent2Dec, {
						assetDigits: 2, // toFiat generates 18 assetDigits
						minDigits: 3,
						decimals: 2,
					});
					newData.coinMn.value = parseFloat(
						tokenPercentRounded2Dec.replaceAll(',', '.'),
					);
				}
			}
			return newData;
		});
	};

	const setChartTotalAccBalanceDelayed = totalBalance => {
		if (timerRef.current) {
			timerRef.current.cancel();
		}
		timerRef.current = later(() => {
			setChartTotalAccBalance(totalBalance);
		}, UPDATE_DELAY_MS);
	};

	const setChartDataDelayed = chartdata => {
		if (timer2Ref.current) {
			timer2Ref.current.cancel();
		}
		timer2Ref.current = later(() => {
			setChartData(chartdata);
		}, UPDATE_DELAY_MS);
	};

	const createChartData = newTokenList => {
		const data = [];
		const wrappedTokenAddress = WRAPPED_ADDRESSES[chainId]?.toLowerCase();
		const tokensFilter = newTokenList.filter(
			token =>
				(isBN(token.balance) && token.balance.gt(BN_ZERO)) ||
				addressMatch(token.address, wrappedTokenAddress),
		);

		tokensFilter.forEach(token => {
			const coin = {
				value: 0,
				valueBN: BN_ZERO,
				symbol: '',
			};
			const coinWrap = {
				value: 0,
				valueBN: BN_ZERO,
				symbol: '',
			};
			const coinMn = {
				value: 0,
				valueBN: BN_ZERO,
				symbol: '',
			};
			const prices = token.prices || {};
			const symbol = getMainSymbol(token.symbol);
			const isCoin = addressMatch(token.address, wrappedTokenAddress);
			let totalBalance = isBN(token.balance) ? token.balance : BN_ZERO;

			if (isCoin) {
				const coinBalance = isBN(token.coinBalance)
					? token.coinBalance
					: BN_ZERO;

				coin.valueBN = toFiat(coinBalance, prices, currency, token.decimals);
				coin.value = formatBN(coin.valueBN, {
					assetDigits: 18, // toFiat generates 18 assetDigits
					minDigits: 3,
					decimals: 2,
				});
				coin.symbol = symbol;

				const hasCollateral = token.collateral?.gt(BN_ZERO);
				totalBalance = totalBalance.add(coinBalance);

				const balance = isBN(token.balance) ? token.balance : BN_ZERO;
				coinWrap.valueBN = toFiat(balance, prices, currency, token.decimals);
				coinWrap.value = formatBN(coinWrap.valueBN, {
					assetDigits: 18, // toFiat generates 18 assetDigits
					minDigits: 3,
					decimals: 2,
				});
				coinWrap.symbol = token.symbol;

				if (hasCollateral) {
					const collateral = isBN(token.collateral)
						? token.collateral
						: BN_ZERO;
					coinMn.valueBN = toFiat(collateral, prices, currency, token.decimals);
					coinMn.value = formatBN(coinMn.valueBN, {
						assetDigits: 18, // toFiat generates 18 assetDigits
						minDigits: 3,
						decimals: 2,
					});
					coinMn.symbol = 'MNRG';
					totalBalance = totalBalance.add(token.collateral);
				}
			}

			if (totalBalance.toString() !== '0') {
				const totalBalanceFiat = token.calculateTotalAssetPriceFromDexPrice
					? toFiatFromDexPrices(
							totalBalance,
							token.reserves0BN,
							token.reserves1BN,
							token.currencyprices,
							currency,
							token.decimals,
							wrappedtoken,
					  )
					: toFiat(totalBalance, prices, currency, token.decimals);
				totalAccBalance = totalAccBalance.add(totalBalanceFiat);

				data.push({
					name: symbol,
					value: formatBN(totalBalanceFiat, {
						assetDigits: 18, // toFiat generates 18 assetDigits
						minDigits: 3,
						decimals: 2,
					}),
					valueBN: totalBalanceFiat,
					path: token.image,
					coin,
					coinWrap,
					coinMn,
					isCoin,
					tokenaddress: token.address,
				});
			}
		});

		setChartTotalAccBalanceDelayed(totalAccBalance);
		return getTokensInPercent(data);
	};

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
			if (timerRef.current) {
				timerRef.current.cancel();
			}
			if (timer2Ref.current) {
				timer2Ref.current.cancel();
			}
		};
	}, []);

	useEffect(() => {
		const wrappedTokenAddress = WRAPPED_ADDRESSES[chainId]?.toLowerCase();
		const pricesKnown =
			!loading &&
			(tokenList.length > 1 ||
				coinHasBalance(tokenList[0], wrappedTokenAddress));

		if (pricesKnown) {
			const newData = createChartData(tokenList);
			const prices = extractPrices(newData);

			if (isMounted.current && !isEqual(prices, pricesRef.current)) {
				pricesRef.current = prices;
				setChartDataDelayed(newData);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenList, loading, chainId]);

	let donutChart;

	if (loading) {
		donutChart = (
			<div className={classes.chartLoadingText}>
				{t('page.dashboardpage.loading')}
			</div>
		);
	} else if (chartData.length > 0) {
		donutChart = (
			<DonutChart
				legendHeaders={legendHeaders}
				data={chartData}
				sum={formatBN(chartTotalAccBalance, {
					assetDigits: 18,
					minDigits: 3,
					decimals: 2,
				})}
			/>
		);
	}

	const balanceText = donutChart
		? 'page.dashboardpage.current_balance'
		: 'page.dashboardpage.no_balance';

	return (
		<div className={classes.root}>
			<div className={classes.balanceTitle}>{t(balanceText)}</div>
			<hr className={classes.separateLine} />
			<div className={classes.detailsWrapper}>
				{donutChart}
				{/* {qrCode} */}
			</div>
			{/* <div className={classes.lastTransaction}>
				{t('page.dashboardpage.latest_transaction')}
			</div>
			<TransactionHistory headers={transHeaders} data={transData} /> */}
		</div>
	);
};

export default DashboardPage;
