import React, { useContext, useState, useRef, useEffect, memo } from 'react';
import clsx from 'clsx';
import { utils } from 'web3';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import {
	Button,
	ListItem,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
	useMediaQuery,
} from '@itsa.io/ui';
import {
	Search as SearchIcon,
	Done as DoneIcon,
	DeleteForeverOutlined as DeleteForeverOutlinedIcon,
	Cancel as CancelIcon,
} from '@material-ui/icons';
import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from '@itsa.io/ui/icons';
import {
	getGoldSubscriptions,
	getPlatinaSubscriptions,
	subscribeGoldAddress,
	subscribePlatinaAddress,
	unsubscribeGoldAddress,
	unsubscribePlatinaAddress,
	resetGoldSubscriptions,
	resetPlatinaSubscriptions,
	setAllGoldSubscriptionAddresses,
	setAllPlatinaSubscriptionAddresses,
	getMaxGoldSubscriptions,
	getMaxPlatinaSubscriptions,
} from 'utils/smartcontracts/itsa-wallet-nft';
import GasSlider from 'components/common/GasSlider';
import { FixedSizeList as List } from 'react-window';
import {
	SHOW_SEARCHFIELD_ON_CUSTOMTOKENS_COUNT,
	GAS_PERCENTAGES,
	NETWORK_NAMES,
	GENERAL_MESSAGE_TIMEOUT,
	PAGES_NAMES,
	NFT_EXTRA_ADDRESSES_ENABLED,
} from 'config/constants';
import useAlert from 'hooks/useAlert';
import gaspriceCtx from 'context/gasprice';
import navigatorCtx from 'context/navigator';
import nftcardCtx from 'context/nftcard';
import blockHeightCtx from 'context/blockheight';
import wrappedtokenCtx from 'context/wrappedtoken';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import securedsendtxCtx from 'context/securedsendtx';
import NftCard from 'components/common/NftCard';
import { cloneDeep, isEqual } from 'lodash';
import addressMatch from 'utils/address-match';
import useStyles from 'styles/pages/NftExtraWalletsPage';

const { isAddress } = utils;

const WAIT_INTERVAL = 200;
const WIDTH = '100%';
const MAX_REQUIRED_HEIGHT_LIST = 700;
const MIN_REQUIRED_HEIGHT_LIST = 562;
const FREE_REQUIRED_SPACE_LIST = 138;
const ITEM_SIZE = 50;

const sortFn = (a, b) => {
	const aLower = a.toLowerCase();
	const bLower = b.toLowerCase();
	if (aLower < bLower) {
		return -1;
	}
	if (bLower < aLower) {
		return 1;
	}
	return 0;
};

const hasOnlyOneNewAddress = (prevAddresses, newAddresses) => {
	try {
		if (newAddresses.length !== prevAddresses.length + 1) {
			return null;
		}
		if (newAddresses.length === 1) {
			return newAddresses[0];
		}
		const possibleNewAddress = newAddresses.find(
			address => !prevAddresses.includes(address),
		);
		if (!possibleNewAddress) {
			return null;
		}
		const newAddressesFiltered = newAddresses.filter(
			address => !addressMatch(address, possibleNewAddress),
		);
		return isEqual(newAddressesFiltered, prevAddresses)
			? possibleNewAddress
			: null;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
	}
	return null;
};

const onlyOneAddressRemoved = (prevAddresses, newAddresses) => {
	try {
		if (newAddresses.length !== prevAddresses.length - 1) {
			return null;
		}
		if (newAddresses.length === 0) {
			return prevAddresses[0];
		}
		const possibleRemovedAddress = prevAddresses.find(
			address => !newAddresses.includes(address),
		);
		if (!possibleRemovedAddress) {
			return null;
		}
		const prevAddressesFiltered = prevAddresses.filter(
			address => !addressMatch(address, possibleRemovedAddress),
		);
		return isEqual(prevAddressesFiltered, newAddresses)
			? possibleRemovedAddress
			: null;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
	}
	return null;
};

const NftExtraWalletsPage = () => {
	const classes = useStyles();
	const { chainId, address, hardwareWallet } = useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const { t } = useIntl();
	const theme = useTheme();
	const smallScreenSize = useMediaQuery(theme.breakpoints.up('sm'));
	const minHeightScreenSize = useMediaQuery(
		`(min-height:${MAX_REQUIRED_HEIGHT_LIST}px)`,
	);
	const [searchValue, setSearchValue] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [editWalletAddress, setEditWalletAddress] = useState('');
	const [maxAddresses, setMaxAddresses] = useState(0);
	const timeoutRef = useRef();
	const isMounted = useRef();
	const [newWalletAddress, setNewWalletAddress] = useState('');
	const [addingWalletAddress, setAddingWalletAddress] = useState(false);
	const wrappedtoken = useContext(wrappedtokenCtx);
	const { setPage } = useContext(navigatorCtx);
	const { selectedNftCard } = useContext(nftcardCtx);
	const { gasprice } = useContext(gaspriceCtx);
	const { blockheight } = useContext(blockHeightCtx);
	const inputRef = useRef();
	const warningChangesMsg = useRef();
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const alert = useAlert();
	const [addresses, setAddresses] = useState([]);
	const [initialAddresses, setInitialAddresses] = useState([]);
	const isTransferringNftOwnership =
		wrappedtoken?.isTransferringNftOwnership[
			`${selectedNftCard.chainid}-${selectedNftCard.type}-${selectedNftCard.nftId}`
		];
	const isChangingNftUnlockedWallets =
		wrappedtoken?.isChangingNftUnlockedWallets[
			`${selectedNftCard.chainid}-${selectedNftCard.type}-${selectedNftCard.nftId}`
		];
	const nftHasPendingTransaction =
		isTransferringNftOwnership || isChangingNftUnlockedWallets;

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	const showWarningChanges = () => {
		if (!warningChangesMsg.current) {
			warningChangesMsg.current = true;
			alert(t('page.extrawalletspage.make_sure_to_save_the_changes'), {
				severity: 'warning',
				timeout: GENERAL_MESSAGE_TIMEOUT,
			});
		}
	};

	const addNewAddress = address => {
		if (!addresses.includes(address)) {
			const newAddresses = cloneDeep(addresses);
			newAddresses.push(address);
			newAddresses.sort(sortFn);
			setAddresses(newAddresses);
			showWarningChanges();
		}
	};

	const removeAddress = address => {
		const index = addresses.indexOf(address);
		if (index !== -1) {
			const newTokens = cloneDeep(addresses);
			newTokens.splice(index, 1);
			setAddresses(newTokens);
			showWarningChanges();
		}
	};

	const getInitialAndMaxAddresses = async () => {
		let response;
		if (selectedNftCard.type === 'gold') {
			response = await Promise.all([
				getGoldSubscriptions(selectedNftCard.chainid, selectedNftCard.nftId),
				getMaxGoldSubscriptions(selectedNftCard.chainid),
			]);
		} else if (selectedNftCard.type === 'platina') {
			response = await Promise.all([
				getPlatinaSubscriptions(selectedNftCard.chainid, selectedNftCard.nftId),
				getMaxPlatinaSubscriptions(selectedNftCard.chainid),
			]);
		}
		if (response) {
			const addresses = response[0];
			const newMaxAddresses = response[1];
			if (isMounted.current) {
				setInitialAddresses(addresses);
				setAddresses(cloneDeep(addresses));
				setMaxAddresses(newMaxAddresses);
			}
		}
	};

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	useEffect(() => {
		isMounted.current = true;
		if (selectedNftCard.type !== 'silver') {
			getInitialAndMaxAddresses();
		}
		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getInitialAndMaxAddresses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, address, blockheight]);

	// temporarely disabled: seems to be invoked too many times
	// TODO: fix autofocus
	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			if (isMounted.current) {
				setSearchQuery(searchValue);
			}
		}, WAIT_INTERVAL);

		return () => clearTimeout(timeoutRef.current);
	}, [searchValue]);

	const transferOwnership = () => {
		if (selectedNftCard.chainid === chainId) {
			setPage(PAGES_NAMES.TRANSFERNFT);
		} else {
			alert(
				t('page.extrawalletspage.select_network_for_transfer_ownership', {
					values: {
						networkname: NETWORK_NAMES[selectedNftCard.chainid],
					},
				}),
				{
					severity: 'warning',
					timeout: GENERAL_MESSAGE_TIMEOUT,
				},
			);
		}
	};

	const saveAddresses = async () => {
		try {
			if (addresses.length === 0) {
				if (selectedNftCard.type === 'gold') {
					await resetGoldSubscriptions(
						chainId,
						selectedNftCard.nftId,
						address,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				} else if (selectedNftCard.type === 'platina') {
					await resetPlatinaSubscriptions(
						chainId,
						selectedNftCard.nftId,
						address,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				}
			} else {
				const oneNewAddress = hasOnlyOneNewAddress(initialAddresses, addresses);
				if (oneNewAddress) {
					if (selectedNftCard.type === 'gold') {
						await subscribeGoldAddress(
							chainId,
							selectedNftCard.nftId,
							oneNewAddress,
							address,
							sendTx,
							gasprice,
							getExtraPercentageGas(),
							hardwareWallet,
						);
					} else if (selectedNftCard.type === 'platina') {
						await subscribePlatinaAddress(
							chainId,
							selectedNftCard.nftId,
							oneNewAddress,
							address,
							sendTx,
							gasprice,
							getExtraPercentageGas(),
							hardwareWallet,
						);
					}
				} else {
					const oneAddressRemoved = onlyOneAddressRemoved(
						initialAddresses,
						addresses,
					);
					if (oneAddressRemoved) {
						if (selectedNftCard.type === 'gold') {
							await unsubscribeGoldAddress(
								chainId,
								selectedNftCard.nftId,
								oneAddressRemoved,
								address,
								sendTx,
								gasprice,
								getExtraPercentageGas(),
								hardwareWallet,
							);
						} else if (selectedNftCard.type === 'platina') {
							await unsubscribePlatinaAddress(
								chainId,
								selectedNftCard.nftId,
								oneAddressRemoved,
								address,
								sendTx,
								gasprice,
								getExtraPercentageGas(),
								hardwareWallet,
							);
						}
					} else if (selectedNftCard.type === 'gold') {
						await setAllGoldSubscriptionAddresses(
							chainId,
							selectedNftCard.nftId,
							addresses,
							address,
							sendTx,
							gasprice,
							getExtraPercentageGas(),
							hardwareWallet,
						);
					} else if (selectedNftCard.type === 'platina') {
						await setAllPlatinaSubscriptionAddresses(
							chainId,
							selectedNftCard.nftId,
							addresses,
							address,
							sendTx,
							gasprice,
							getExtraPercentageGas(),
							hardwareWallet,
						);
					}
				}
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	const walletsListChanged = !isEqual(initialAddresses, addresses);

	const getSaveButton = () => {
		return (
			<Button
				className={clsx(classes.saveButton, {
					[classes.busy]: isChangingNftUnlockedWallets,
				})}
				disabled={!walletsListChanged || nftHasPendingTransaction}
				variant="contained"
				color="primary"
				onClick={saveAddresses}
				disableElevation
			>
				{t('page.extrawalletspage.save_addresses')}
			</Button>
		);
	};

	let title;
	let saveButton;
	let walletsListChangedMessage;

	if (walletsListChanged) {
		walletsListChangedMessage = (
			<div className={classes.warningDescription}>
				<ErrorOutlineOutlinedIcon className={classes.warningIcon} />
				<div>{t('page.extrawalletspage.make_sure_to_save_the_changes')}</div>
			</div>
		);
	}

	const canHaveExtraUnlockedWallets =
		NFT_EXTRA_ADDRESSES_ENABLED && selectedNftCard.type !== 'silver';
	if (canHaveExtraUnlockedWallets) {
		saveButton = getSaveButton();
		title = (
			<div className={classes.extraWalletWrap}>
				<span>
					{t('page.extrawalletspage.title', {
						values: {
							addresses: maxAddresses,
						},
					})}
				</span>
				{saveButton}
			</div>
		);
	}

	const nftRow = (
		<div>
			<div className={classes.nftContainer}>
				<NftCard
					chainid={selectedNftCard.chainid}
					className={clsx(classes.nftCard, {
						[classes.blinking]: nftHasPendingTransaction,
					})}
					nftId={selectedNftCard.nftId}
					type={selectedNftCard.type}
				/>
				<div className={classes.nftDescriptionContainer}>
					<div>{selectedNftCard.metadata.description}</div>
					<Button
						className={clsx(classes.transferOwnershipButton, {
							[classes.busy]: isTransferringNftOwnership,
							[classes.busyCorrectedPosition]: isTransferringNftOwnership,
						})}
						disabled={nftHasPendingTransaction}
						variant="contained"
						color="primary"
						onClick={transferOwnership}
						disableElevation
					>
						{t('page.extrawalletspage.transfer_ownership')}
					</Button>
				</div>
			</div>
		</div>
	);

	const tryAddUnlockWallet = () => {
		if (selectedNftCard.chainid === chainId) {
			setAddingWalletAddress(true);
		} else {
			alert(
				t('page.extrawalletspage.select_network', {
					values: {
						networkname: NETWORK_NAMES[selectedNftCard.chainid],
					},
				}),
				{
					severity: 'warning',
					timeout: GENERAL_MESSAGE_TIMEOUT,
				},
			);
		}
	};

	const handleSearchChange = e => {
		setSearchValue(e.target.value);
	};

	const handleChangeAddress = e => {
		setEditWalletAddress(e.target.value);
	};

	const handleBlur = customToken => {
		customToken.address = editWalletAddress;
		if (addressMatch(address, customToken.address)) {
			alert(t('page.extrawalletspage.cannot_set_own_address'), {
				severity: 'info',
				timeout: GENERAL_MESSAGE_TIMEOUT,
			});
		} else if (isAddress(customToken.address)) {
			addNewAddress(customToken.address);
		}
		setEditWalletAddress('');
		setAddingWalletAddress(false);
	};

	const handleKeyDown = (address, e) => {
		if (e.keyCode === 27) {
			setEditWalletAddress('');
		}
		if (e.keyCode === 13) {
			handleBlur(address);
		}
	};

	const handleDeleteAddress = (e, address) => {
		e.stopPropagation();
		removeAddress(address);
	};

	const cancelNewAddress = () => {
		setNewWalletAddress('');
		setAddingWalletAddress(false);
	};

	let searchField;
	if (addresses.length >= SHOW_SEARCHFIELD_ON_CUSTOMTOKENS_COUNT) {
		searchField = (
			<TextField
				className={classes.textFieldSearch}
				label="Search"
				variant="filled"
				onChange={handleSearchChange}
				value={searchValue}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon className={classes.searchIcon} />
						</InputAdornment>
					),
					disableUnderline: true,
				}}
				fullWidth
			/>
		);
	}

	const newListFound = addresses.filter(token => {
		const { name, symbol, address } = token;
		const query = searchQuery.toLowerCase();
		const tokenFound =
			query === '' ||
			name.toLowerCase().includes(query) ||
			address.toLowerCase().includes(query) ||
			symbol.toLowerCase().includes(query);
		return tokenFound;
	});

	const itemCount = newListFound.length;
	const listItemsRender = ({ index, style }) => {
		const addressItem = newListFound[index];

		const isEditField = editWalletAddress === addressItem;

		let ref;
		if (isEditField) {
			ref = inputRef;
		}

		return (
			<ListItem
				component="div"
				className={clsx(classes.item)}
				style={style}
				key={addressItem || index}
			>
				<div className={classes.containerItem}>
					<TextField
						className={clsx(classes.textFieldAddress, {
							[classes.textFieldDisabled]: !isEditField,
						})}
						placeholder="0x..."
						color="primary"
						value={addressItem}
						onBlur={() => handleBlur(addressItem)}
						onChange={handleChangeAddress}
						onKeyDown={e => handleKeyDown(addressItem, e)}
						disabled={!isEditField}
						ref={ref}
						fullWidth
						InputProps={{
							disableUnderline: !isEditField,
						}}
					/>
					<IconButton
						className={classes.iconButton}
						onClick={e => handleDeleteAddress(e, addressItem)}
						variant="contained"
						color="primary"
						size="small"
					>
						<DeleteForeverOutlinedIcon />
					</IconButton>
				</div>
			</ListItem>
		);
	};

	// Standard required list height: 562px that need for fixed height: 700px
	let height = MIN_REQUIRED_HEIGHT_LIST;
	// Calculate correct height, if window innerHeight is smallest than required list fixed height: 700px
	if (!minHeightScreenSize) {
		height = window.innerHeight - FREE_REQUIRED_SPACE_LIST;
	}
	// Note: in extra small screen size, the list height gets 100%
	// Calculate correct height, if in extra small screen size and window innerHeight is greatest than required list fixed height: 700px
	if (minHeightScreenSize && !smallScreenSize) {
		height = window.innerHeight - FREE_REQUIRED_SPACE_LIST;
	}

	const listContent = (
		<List
			className={classes.tokenListContainer}
			width={WIDTH}
			height={height}
			itemSize={ITEM_SIZE}
			itemCount={itemCount}
		>
			{listItemsRender}
		</List>
	);

	// item for adding a new token:
	let addWalletAddressButton;
	if (addingWalletAddress) {
		const addressItem = {
			address: newWalletAddress,
			chainId,
		};

		addWalletAddressButton = (
			<div
				className={clsx(classes.item, classes.addToken)}
				key="newwalletaddress"
			>
				<div className={classes.editContainerItem}>
					<TextField
						className={clsx(classes.editTextFieldAddress, {
							[classes.textFieldDisabled]: !addingWalletAddress,
						})}
						placeholder={t('page.extrawalletspage.add_walletaddress')}
						color="primary"
						value={editWalletAddress}
						onBlur={() => handleBlur(addressItem)}
						onChange={handleChangeAddress}
						onKeyDown={e => handleKeyDown(addressItem, e)}
						disabled={!addingWalletAddress}
						ref={inputRef}
						fullWidth
						InputProps={{
							disableUnderline: !addingWalletAddress,
						}}
					/>
					<div className={classes.editIconsWrap}>
						<IconButton
							className={classes.editIconButton}
							onClick={() => handleBlur(addressItem)}
							size="small"
						>
							<DoneIcon />
						</IconButton>
						<IconButton
							className={classes.editIconButton}
							onClick={cancelNewAddress}
							size="small"
						>
							<CancelIcon />
						</IconButton>
					</div>
				</div>
			</div>
		);
	} else {
		addWalletAddressButton = (
			<Button
				className={classes.button}
				disabled={addresses.length >= maxAddresses || nftHasPendingTransaction}
				variant="contained"
				color="primary"
				onClick={tryAddUnlockWallet}
				disableElevation
			>
				{t('page.extrawalletspage.unlock_extra_wallet')}
			</Button>
		);
	}

	let unlockedwallets;
	if (canHaveExtraUnlockedWallets) {
		unlockedwallets = (
			<>
				{searchField}
				{addWalletAddressButton}
				{listContent}
			</>
		);
	}

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider value={gaspriceIndex} onChange={changeGaspriceIndex} />
		);
	}

	return (
		<>
			<div className={classes.root}>
				{nftRow}
				{title}
				{walletsListChangedMessage}
				{gaspriceSlider}
				{unlockedwallets}
			</div>
		</>
	);
};

export default memo(NftExtraWalletsPage);
