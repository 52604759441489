import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		width: '100%',
		alignSelf: 'self-start',
	},
	title: {
		fontSize: 28,
		fontWeight: 500,
	},
	separateLine: {
		position: 'relative',
		top: -theme.spacing(0.5),
		borderColor: theme.palette.separate.main,
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	optionWrapper: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexBasis: '50%',
		flexGrow: 0,
		width: 'auto',
		gap: theme.spacing(3),
		marginTop: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			width: 750,
		},
	},
	optionContainer: {
		width: '100%',
		padding: theme.spacing(3),
		backgroundColor: theme.palette.default.light,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: 6,
	},
	optionLabel: {
		fontSize: 16,
		fontWeight: 500,
		marginBottom: theme.spacing(3),
	},
}));
