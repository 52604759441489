import React from 'react';
import { useIntl } from '@itsa.io/web3utils';
import useNewFeatures from 'hooks/useNewFeatures';

import useStyles from 'styles/pages/NewFeaturesPage';

const NewFeaturesPage = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const newFeatures = useNewFeatures();

	const items = newFeatures.map(item => {
		return (
			<div className={classes.optionContainer} key={item.id}>
				<div className={classes.optionLabel}>{item.title}</div>
				{item.content}
			</div>
		);
	});

	return (
		<div className={classes.root}>
			<div className={classes.title}>{t('page.newfeaturespage.title')}</div>
			<hr className={classes.separateLine} />
			<div className={classes.optionWrapper}>{items}</div>
		</div>
	);
};

export default NewFeaturesPage;
