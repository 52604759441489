import { makeStyles, alpha } from '@itsa.io/ui';
import { FONT_SIZE_DECIMALS } from 'config/constants';

export default makeStyles(theme => ({
	buttonFirstRow: {
		marginTop: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(5),
		},
	},
	circleButtons: {
		display: 'flex',
		width: '100%',
		maxWidth: 500,
		justifyContent: 'center',
		flexWrap: 'wrap',
		borderBottom: `1px solid ${theme.palette.default.light}`,
		marginTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'space-around',
			marginTop: theme.spacing(5),
		},
		[theme.breakpoints.up('md')]: {
			justifyContent: 'space-evenly',
		},
	},
	buttonVerticalGroup: {
		display: 'flex',
		width: '100%',
		maxWidth: 500,
		justifyContent: 'space-evenly',
		alignItems: 'flex-start',
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(5),
			paddingBottom: theme.spacing(2),
		},
	},
	buttonHorizontalGroup: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: 500,
		gap: theme.spacing(4),
		'& button': {
			width: '100%',
			maxWidth: '100%',
			margin: theme.spacing(0.75, 0),
			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(1.5, 0),
			},
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			'& button': {
				width: '100%',
			},
		},
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
			width: '85%',
			gap: theme.spacing(1),
			flexDirection: 'column-reverse',
		},
	},
	logo: {
		width: 96,
		height: 96,
		[theme.breakpoints.down('sm')]: {
			width: 80,
			height: 80,
		},
		fontSize: 40,
		border: `2px solid ${alpha(theme.palette.primary.main, 0)}`,
		margin: theme.spacing(0, 'auto'),
	},
	logoUnknown: {
		fontSize: 48,
		fontWeight: 500,
		color: theme.palette.secondary.light,
		margin: theme.spacing(0, 'auto'),
	},
	tokenDescription: {
		fontSize: 30,
		marginTop: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			fontSize: 35,
			marginTop: theme.spacing(1.5),
		},
	},
	tokenAmountWrapper: {
		display: 'flex',
		alignItems: 'baseline',
		justifyContent: 'center',
		marginTop: theme.spacing(5),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(3),
		},
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	totalBalance: {
		fontSize: 35,
		[theme.breakpoints.up('md')]: {
			fontSize: 65,
		},
	},
	fiatAmount: {
		fontSize: 28,
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	tokenSymbol: {
		fontSize: 20,
		marginLeft: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			fontSize: 35,
		},
	},
	tokenSymbolFiat: {
		fontSize: 18,
		textTransform: 'uppercase',
	},
	tokenSymbolDetail: {
		fontSize: '0.65em',
		display: 'inline-block',
	},
	tokenSymbolDetailMN: {
		fontSize: '0.65em',
		display: 'inline-block',
		// marginLeft: '3em',
	},
	valueMsg: {
		display: 'none',
		fontSize: 35,
		marginRight: theme.spacing(4),
		color: theme.palette.text.secondary,
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	wrappedDetails: {
		fontSize: 25,
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	masternodeDetails: {
		fontSize: 25,
	},
	coinDetails: {
		fontSize: 25,
		marginRight: '0.6em',
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	detailContainer: {
		marginTop: theme.spacing(5),
		textAlign: 'right',
	},
	transactionHistoryTable: {
		border: `1px solid ${theme.palette.default.main}`,
		backgroundColor: theme.palette.default.dark,
	},

	buttonTextIcon: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
		},
	},

	iconWrap: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 60,
		height: 60,
		borderRadius: 12,
		marginBottom: theme.spacing(1),
	},
	pendingTransaction: {
		animation: `$blink 1.5s infinite`,
	},
	'@keyframes blink': {
		'50%': {
			opacity: 0.5,
		},
	},
}));
