import React, { useContext } from 'react';
import { Select as MuiSelect, MenuItem as MuiMenuItem } from '@itsa.io/ui';
import { useIntl } from '@itsa.io/web3utils';
import { LISTINFOS } from 'config/constants';
import listinfoCtx from 'context/listinfo';

import useStyles from 'styles/components/settings/ListInfo';

const ListInfo = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const { listinfoSettings, setListinfo } = useContext(listinfoCtx);

	const handleChange = event => {
		const { value } = event.target;
		setListinfo(value);
	};

	const menuItemItems = LISTINFOS.map(item => (
		<MuiMenuItem className={classes.menuItem} key={item} value={item}>
			{t(`settings.list_info.${item}`)}
		</MuiMenuItem>
	));

	return (
		<MuiSelect
			className={classes.root}
			value={listinfoSettings}
			onChange={handleChange}
			variant="outlined"
			displayEmpty
		>
			{menuItemItems}
		</MuiSelect>
	);
};

export default ListInfo;
