/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	TableContainer as MuiTableContainer,
	Table as MuiTable,
	TableBody as MuiTableBody,
	TableCell as MuiTableCell,
	TableHead as MuiTableHead,
	TableRow as MuiTableRow,
	Chip,
} from '@itsa.io/ui';
import {
	CheckCircleOutlined as CheckCircleOutlinedIcon,
	WatchLaterOutlined as WatchLaterOutlinedIcon,
	CheckCircleOutline as CheckCircleOutlineIcon,
	CardMembership as CardMembershipIcon,
	CardGiftcard as CardGiftcardIcon,
	ArrowRightAlt as ArrowRightAltIcon,
	SwapHoriz as SwapHorizIcon,
	WrapText as WrapTextIcon,
	AccountBalance as AccountBalanceIcon,
	AttachMoney as AttachMoneyIcon,
	AccountTreeOutlined as AccountTreeOutlinedIcon,
	Link as LinkIcon,
	LinkOff as LinkOffIcon,
	// AddCard as AddCardIcon,
	// CreditCardOff as CreditCardOffIcon,
	// AddTask as AddTaskIcon,
	// RemoveDone as RemoveDoneIcon,
} from '@material-ui/icons';
import { useIntl, later } from '@itsa.io/web3utils';
import tokenListCtx from 'context/tokenlist';
import wrappedtokenCtx from 'context/wrappedtoken';
import transformTransactions from 'utils/transform-transactions';
import addressMatch from 'utils/address-match';
import shortenAddress from 'utils/shorten-address';
import getElapsedTime from 'utils/get-elapsed-time';
import useStyles from 'styles/components/common/TransactionHistory';

const NO_TX_SYMBOL = {
	energiMasternodeAnnounce: true,
	energiMasternodeDenounce: true,
	itsaNftAddressSubscription: true,
	itsaNftAddressUnsubscription: true,
	itsaNftAllAddressesSubscription: true,
	itsaNftSubscriptionsReset: true,
	itsaSubscriptionBindToSubscription: true,
	itsaSubscriptionRemoveBoundSubscription: true,
	itsaSubscriptionRemoveBoundSubscriptionApproval: true,
	itsaSubscriptionApproveBoundSubscription: true,
	itsaSubscriptionSetApprovedMultipleBoundSubscriptions: true,
};

const NO_ARROW_TYPES = {
	tokenapproval: true,
	itsaSubscriptionTrial: true,
	itsaSubscriptionBindToSubscription: true,
	itsaSubscriptionApproveBoundSubscription: true,
	itsaSubscriptionRemoveBoundSubscriptionApproval: true,
	itsaSubscriptionSetApprovedMultipleBoundSubscriptions: true,
};

const ICONS = {
	tokenswap: <SwapHorizIcon />,
	stakingrewards: <AttachMoneyIcon />,
	masternoderewards: <AccountBalanceIcon />,
	removeliquidity: <AttachMoneyIcon />,
	addliquidity: <AccountBalanceIcon />,
	energiMasternodeWithdraw: <AttachMoneyIcon />,
	energiMasternodeDeposit: <AccountBalanceIcon />,
	energiMasternodeAnnounce: <AccountTreeOutlinedIcon />,
	energiMasternodeDenounce: <AccountTreeOutlinedIcon />,
	tokenapproval: <CheckCircleOutlineIcon />,
	itsaSubscriptionSubscription: <CardMembershipIcon />,
	itsaSubscriptionTrial: <CardGiftcardIcon />,

	itsaNftAddressSubscription: <LinkIcon />,
	itsaNftAddressUnsubscription: <LinkIcon />,
	itsaNftAllAddressesSubscription: <LinkIcon />,
	itsaNftSubscriptionsReset: <LinkOffIcon />,

	itsaSubscriptionBindToSubscription: <LinkIcon />,
	itsaSubscriptionRemoveBoundSubscription: <LinkOffIcon />,
	itsaSubscriptionApproveBoundSubscription: <LinkIcon />,
	itsaSubscriptionRemoveBoundSubscriptionApproval: <LinkIcon />,
	itsaSubscriptionSetApprovedMultipleBoundSubscriptions: <LinkIcon />,

	tokenwrap: <WrapTextIcon />,
	tokenunwrap: <WrapTextIcon />,
};

const TransactionHistory = ({
	address,
	selectedToken,
	headers,
	data,
	className,
	symbol,
	pending,
}) => {
	const { t, formatDate, formatTime /* formatBN */ } = useIntl();
	const { getTokenInfo } = useContext(tokenListCtx);
	const wrappedtoken = useContext(wrappedtokenCtx);
	const timerRef = useRef();
	const [, forceUpdate] = useReducer(x => x + 1, 0);
	const classes = useStyles();

	useEffect(() => {
		timerRef.current = later(forceUpdate, 1000, true);
		return () => {
			timerRef.current.cancel();
		};
	}, []);

	let tableHead;
	if (headers) {
		tableHead = (
			<MuiTableHead>
				<MuiTableRow>
					{headers.map(name => (
						<MuiTableCell key={name}>
							{
								// t(`trade.transaction_history.${name}`)
							}
							{name}
						</MuiTableCell>
					))}
				</MuiTableRow>
			</MuiTableHead>
		);
	}

	const createTableRow = (transaction, i) => {
		const row = transformTransactions(
			transaction,
			pending,
			address,
			selectedToken,
			wrappedtoken,
			t,
		);
		const negativeAmount = row.amount?.startsWith('-');
		let txSymbol;

		if (NO_TX_SYMBOL[row.type]) {
			txSymbol = null;
		} else if (NO_ARROW_TYPES[row.type]) {
			txSymbol = symbol;
		} else if (row.type === 'itsaNftTransfer') {
			if (row.nftSent) {
				txSymbol = (
					<div className={classes.swappedSymbolBox}>
						{row.otherSymbol}
						<ArrowRightAltIcon className={classes.arrowRightAltIcon} />
					</div>
				);
			} else {
				txSymbol = (
					<div className={classes.swappedSymbolBox}>
						<ArrowRightAltIcon className={classes.arrowRightAltIcon} />
						{row.otherSymbol}
					</div>
				);
			}
		} else if (row.type === 'energiMasternodeWithdraw') {
			txSymbol = (
				<div className={classes.swappedSymbolBox}>
					MNRG
					<ArrowRightAltIcon className={classes.arrowRightAltIcon} />
					NRG
				</div>
			);
		} else if (row.type === 'energiMasternodeDeposit') {
			txSymbol = (
				<div className={classes.swappedSymbolBox}>
					NRG
					<ArrowRightAltIcon className={classes.arrowRightAltIcon} />
					MNRG
				</div>
			);
		} else if (row.type === 'removeliquidity' || row.type === 'addliquidity') {
			let symbolA;
			let symbolB;
			if (row.tokenA === 'coin') {
				symbolA = wrappedtoken?.symbol.substr(1);
			} else {
				const token = addressMatch(row.tokenA, selectedToken.address)
					? selectedToken
					: getTokenInfo(row.tokenA);
				symbolA = token ? token.symbol : shortenAddress(row.tokenA, 5, 3);
			}
			if (row.tokenB === 'coin') {
				symbolB = wrappedtoken?.symbol.substr(1);
			} else {
				const token = addressMatch(row.tokenB, selectedToken.address)
					? selectedToken
					: getTokenInfo(row.tokenB);
				symbolB = token ? token.symbol : shortenAddress(row.tokenB, 5, 3);
			}
			if (row.type === 'addliquidity') {
				txSymbol = (
					<div className={classes.swappedSymbolBox}>
						{symbolA} + {symbolB}
						<ArrowRightAltIcon className={classes.arrowRightAltIcon} />{' '}
					</div>
				);
			} else {
				txSymbol = (
					<div className={classes.swappedSymbolBox}>
						<ArrowRightAltIcon className={classes.arrowRightAltIcon} />{' '}
						{symbolA} + {symbolB}
					</div>
				);
			}
		} else if (row.type === 'tokenunwrap') {
			txSymbol = (
				<div className={classes.swappedSymbolBox}>
					{wrappedtoken?.symbol}{' '}
					<ArrowRightAltIcon className={classes.arrowRightAltIcon} />{' '}
					{wrappedtoken?.symbol.substr(1)}
				</div>
			);
		} else if (row.type === 'tokenwrap') {
			txSymbol = (
				<div className={classes.swappedSymbolBox}>
					{wrappedtoken?.symbol.substr(1)}
					<ArrowRightAltIcon className={classes.arrowRightAltIcon} />{' '}
					{wrappedtoken?.symbol}
				</div>
			);
		} else if (row.type === 'tokenswap') {
			let symbolFrom;
			let symbolTo;
			if (row.tokenfrom === 'coin') {
				symbolFrom = wrappedtoken?.symbol.substr(1);
			} else {
				const token = getTokenInfo(row.tokenfrom);
				symbolFrom = token ? token.symbol : shortenAddress(row.tokenfrom, 5, 3);
			}
			if (row.tokento === 'coin') {
				symbolTo = wrappedtoken?.symbol.substr(1);
			} else {
				const token = getTokenInfo(row.tokento);
				symbolTo = token ? token.symbol : shortenAddress(row.tokento, 5, 3);
			}

			txSymbol = (
				<div className={classes.swappedSymbolBox}>
					{symbolFrom}{' '}
					<ArrowRightAltIcon className={classes.arrowRightAltIcon} /> {symbolTo}
				</div>
			);
		} else if (negativeAmount) {
			txSymbol = (
				<div className={classes.swappedSymbolBox}>
					{symbol} <ArrowRightAltIcon className={classes.arrowRightAltIcon} />{' '}
				</div>
			);
		} else {
			txSymbol = (
				<div className={classes.swappedSymbolBox}>
					<ArrowRightAltIcon className={classes.arrowRightAltIcon} /> {symbol}{' '}
				</div>
			);
		}
		const directionIcon = ICONS[row.type];
		let statusClassName = classes.statusCompleted;
		let statusIcon;
		if (pending) {
			statusClassName = classes.statusIncoming;
			statusIcon = (
				<WatchLaterOutlinedIcon className={classes.watchLaterIcon} />
			);
		} else {
			statusIcon = (
				<CheckCircleOutlinedIcon className={classes.checkCircleIcon} />
			);
		}

		let estimated = '';
		if (row.estimatedAmount) {
			estimated = <div className={classes.estimated}>~</div>;
		}

		let elapsedTime = '';
		if (row.updated) {
			elapsedTime = getElapsedTime(t, row.updated);
		}

		let firstSymbol;
		if (row.showFirstSymbol) {
			firstSymbol = (
				<span className={classes.firstSymbol}>{row.otherSymbol || symbol}</span>
			);
		}

		return (
			<MuiTableRow hover tabIndex={-1} key={row.txHash || i}>
				<MuiTableCell scope="row">
					{formatDate(row.updated)} {formatTime(row.updated)}
				</MuiTableCell>
				<MuiTableCell align="right">
					<span
						className={clsx({
							[classes.red]: negativeAmount,
							[classes.green]: !negativeAmount,
						})}
						dangerouslySetInnerHTML={{ __html: row.amount }}
					/>{' '}
					{firstSymbol}
					{estimated}
				</MuiTableCell>
				<MuiTableCell>
					<Chip
						className={classes.chipDirection}
						icon={directionIcon}
						label={row.description}
						size="small"
						variant="outlined"
					/>
				</MuiTableCell>
				<MuiTableCell>{shortenAddress(row.address, 7, 5)}</MuiTableCell>
				<MuiTableCell>{txSymbol}</MuiTableCell>
				<MuiTableCell>
					<Chip
						className={statusClassName}
						icon={statusIcon}
						label={`${row.status} ${elapsedTime}`}
						size="small"
						variant="outlined"
					/>
				</MuiTableCell>
			</MuiTableRow>
		);
	};

	const createTableRowNotPending = row => {
		let { symbol } = row;
		if (row?.swappedSymbol) {
			symbol = (
				<div className={classes.swappedSymbolBox}>
					{row.symbol}{' '}
					<ArrowRightAltIcon className={classes.arrowRightAltIcon} />{' '}
					{row.swappedSymbol}
				</div>
			);
		}
		let directionIcon = null;
		if (row.direction === 'swapped from' || row.direction === 'swapped to') {
			directionIcon = <SwapHorizIcon />;
		}

		if (row.direction === 'staking rewards') {
			directionIcon = <AttachMoneyIcon />;
		}

		if (row.direction === 'masternode rewards') {
			directionIcon = <AccountBalanceIcon />;
		}

		if (row.direction === 'wrapped') {
			directionIcon = <WrapTextIcon />;
		}

		let statusClassName = classes.statusCompleted;
		let statusIcon = (
			<CheckCircleOutlinedIcon className={classes.checkCircleIcon} />
		);
		if (row.status === 'incoming') {
			statusClassName = classes.statusIncoming;
			statusIcon = (
				<WatchLaterOutlinedIcon className={classes.watchLaterIcon} />
			);
		}

		return (
			<MuiTableRow hover tabIndex={-1} key={row.txHash}>
				<MuiTableCell scope="row">
					{formatDate(row.updated)} {formatTime(row.updated)}
				</MuiTableCell>
				<MuiTableCell>{row.amount}</MuiTableCell>
				<MuiTableCell>{symbol}</MuiTableCell>
				<MuiTableCell>
					<Chip
						className={classes.chipDirection}
						icon={directionIcon}
						label={row.direction}
						size="small"
						variant="outlined"
					/>
				</MuiTableCell>
				<MuiTableCell>{row.address}</MuiTableCell>
				<MuiTableCell>
					<Chip
						className={statusClassName}
						icon={statusIcon}
						label={row.status}
						size="small"
						variant="outlined"
					/>
				</MuiTableCell>
			</MuiTableRow>
		);
	};

	const tableBody = (
		<MuiTableBody>
			{data.map(pending ? createTableRow : createTableRowNotPending)}
		</MuiTableBody>
	);

	return (
		<div className={classes.root}>
			<MuiTableContainer className={clsx(classes.tableContainer, className)}>
				<MuiTable className={classes.table} size="small">
					{tableHead}
					{tableBody}
				</MuiTable>
			</MuiTableContainer>
		</div>
	);
};
TransactionHistory.defaultProps = {
	className: null,
	data: [],
	address: null,
	selectedToken: null,
	headers: null,
	symbol: null,
	pending: false,
};

TransactionHistory.propTypes = {
	address: PropTypes.string,
	selectedToken: PropTypes.object,
	headers: PropTypes.array,
	data: PropTypes.array,
	className: PropTypes.string,
	symbol: PropTypes.string,
	pending: PropTypes.bool,
};

export default TransactionHistory;
