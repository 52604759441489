import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		maxWidth: 500,
		width: '100%',
		textAlign: 'center',
	},
	buttonFirstRow: {
		marginTop: theme.spacing(1),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(5),
		},
	},
	buttonHorizontalGroup: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: 500,
		gap: theme.spacing(4),
		'& button': {
			width: '100%',
			maxWidth: '100%',
			margin: theme.spacing(0.75, 0),
			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(1.5, 0),
			},
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			'& button': {
				width: '100%',
			},
		},
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
			width: '85%',
			gap: theme.spacing(1),
			flexDirection: 'column-reverse',
		},
	},
	logo: {
		width: 96,
		height: 96,
		fontSize: 40,
		margin: theme.spacing(0, 'auto'),
		[theme.breakpoints.down('sm')]: {
			width: 80,
			height: 80,
		},
	},
	logoUnknown: {
		fontSize: 48,
		fontWeight: 500,
		color: theme.palette.secondary.light,
		margin: theme.spacing(0, 'auto'),
	},
	description: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1),
	},
	textFieldAmount: {
		marginTop: theme.spacing(10),
	},
	textFieldSubscribedWallet: {
		marginTop: theme.spacing(3),
	},
	subscribedWalletContainer: {
		fontSize: 28,
		marginTop: theme.spacing(3),
	},
	iconButton: {
		borderRadius: 8,
		border: `1px solid ${theme.palette.primary.main}`,
		'& + &': {
			marginLeft: theme.spacing(1),
		},
	},
	iconButtonDisabled: {
		opacity: 0.3,
	},
	warningIcon: {
		fontSize: '1.2rem',
		color: theme.palette.primary.main,
		marginRight: theme.spacing(1),
	},
	criticalBalanceDescription: {
		display: 'flex',
		fontSize: '0.85rem',
		textAlign: 'left',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(3),
	},
	sendWarningDescription: {
		display: 'flex',
		fontSize: '0.85rem',
		textAlign: 'left',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(3),
	},
	busy: {
		// width: 'auto',
		'& .MuiButton-label': {
			width: 'auto',
			'&::after': {
				position: 'relative',
				top: -5,
				left: 5,
				content: '" ."',
				animation: `$dotAnim 1s steps(5, end) infinite`,
				fontSize: 25,
				lineHeight: 1,
			},
		},
	},
	'@keyframes dotAnim': {
		'0%, 20%': {
			color: 'rgba(0,0,0,0)',
			textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
		},
		'40%': {
			color: theme.palette.primary.main,
			textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
		},
		'60%': {
			textShadow: `.25em 0 0 ${theme.palette.primary.main}, .5em 0 0 rgba(0,0,0,0)`,
		},
		'80%, 100%': {
			textShadow: `.25em 0 0 ${theme.palette.primary.main}, .5em 0 0 ${theme.palette.primary.main}`,
		},
	},
}));
