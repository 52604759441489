import { getWrappedContract } from 'utils/get-contract';
import { utils } from 'web3';
import { WRAPPED_ADDRESSES, HEX0 } from 'config/constants';
import { BN_GAS_LIMIT_UNIT_PRICES } from 'config/constants/transaction-gas-units';
import setGasLimitAndPrice from 'utils/set-gas-limit-and-price';
import { getWeb3 } from 'utils/get-web3';

const errorMessages = {
	WRAP_CONTRACT_EXCEPTION: 'Wrap Contract Exception',
	NOT_ENOUGH_BALANCE: 'Not enough balance',
	INVALID_SMARTCONTRACT_ADDRESS: 'Invalid Wrap SmartContract Address',
};

/**
 * Generates an Exception thrown by a claim error
 *
 * @method MasternodeException
 * @protected
 * @param txResponse {Object} the txResponse that raised the exception
 * @since 0.0.1
 */
// eslint-disable-next-line func-names
const WrapException = function (msg) {
	// note: do not use arrow function, because we need to maintain the right context
	let keys;
	let l;
	let i;
	let key;

	this.name = 'TokenException';
	if (typeof msg === 'string') {
		this.message = msg;
	} else {
		this.message = errorMessages.WRAP_CONTRACT_EXCEPTION;
		keys = Object.keys(msg);
		l = keys.length;
		i = -1;
		// eslint-disable-next-line no-plusplus
		while (++i < l) {
			key = keys[i];
			this[key] = msg[key];
		}
	}
};

export const wrap = async (
	chainId,
	owner,
	amount,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const wrapContract = getWrappedContract(chainId);
	if (utils.isBN(amount)) {
		amount = amount.toString();
	}

	if (amount.toString() === '0') {
		throw new WrapException(errorMessages.NOT_ENOUGH_BALANCE);
	}

	const transactionData = wrapContract.methods.deposit().encodeABI();

	const rawTx = {
		to: WRAPPED_ADDRESSES[chainId],
		data: transactionData,
		from: owner,
		value: utils.toHex(amount),
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new WrapException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.wrapping[chainId].wrap,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const unwrap = async (
	chainId,
	owner,
	amount,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const wrapContract = getWrappedContract(chainId);
	// return tokenContract.methods.transfer(recipient, amount).call();
	if (utils.isBN(amount)) {
		amount = amount.toString();
	}

	if (amount.toString() === '0') {
		throw new WrapException(errorMessages.NOT_ENOUGH_BALANCE);
	}

	const transactionData = wrapContract.methods
		.withdraw(utils.toHex(amount))
		.encodeABI();

	const rawTx = {
		to: WRAPPED_ADDRESSES[chainId],
		data: transactionData,
		from: owner,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new WrapException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}
	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.wrapping[chainId].unwrap,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });

	// Metamask transaction: return tx as a String:
	return tx;
};
