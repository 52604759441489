import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	backdrop: {},
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 500,
		transform: 'translate(-50%, -50%)',
		outline: 0,
		background: theme.palette.background.light,
		borderRadius: 6,
		boxShadow: `rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;`,
		padding: theme.spacing(2),
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 0,
		marginTop: theme.spacing(3),
	},
	logo: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		maxWidth: 441,
		margin: 'auto',
		opacity: 0.5,
	},
	title: {
		position: 'relative',
		fontSize: 20,
		fontWeight: 500,
		margin: 0,
		textAlign: 'center',
		zIndex: 2,
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 16,
		textAlign: 'center',
		padding: theme.spacing(4, 3),
	},
	closeButton: {
		position: 'relative',
		marginBottom: 'auto',
		marginLeft: 'auto',
		zIndex: 2,
	},
	qrCodeWrapper: {
		position: 'relative',
		display: 'inline-block',
		borderRadius: 4,
		height: 174,
		backgroundColor: theme.palette.common.white,
		padding: theme.spacing(1.5),
		zIndex: 2,
	},
	address: {
		display: 'flex',
		alignItems: 'center',
		gap: 2,
		marginTop: theme.spacing(2),
		padding: theme.spacing(0.5, 1),
		fontSize: '75%',

		'& button': {
			top: 'auto',
			right: 30,
		},

		[theme.breakpoints.up('sm')]: {
			fontSize: 16,
			'& button': {
				top: 'auto',
				right: -15,
			},
		},
	},
}));
