import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		overflowX: 'auto',
	},
	carousel: {
		height: `203px!important`,
	},
	upgradeButton: {
		display: 'inline-block',
		borderRadius: 6,
	},
	expireText: {
		// fontWeight: 500,
		color: theme.palette.red[400],
	},
	btnContainer: {
		display: 'flex',
		gap: 12,
	},
	columnDirection: {
		flexDirection: 'column',
	},
	blinking: {
		animation: `$blink 1.5s infinite`,
	},
	busy: {
		'& .MuiButton-label': {
			width: 'auto',
			'&::after': {
				position: 'relative',
				content: '" ."',
				animation: `$dotAnim 1s steps(5, end) infinite`,
				fontSize: 25,
				lineHeight: 0,
			},
		},
	},
	'@keyframes blink': {
		'50%': {
			opacity: 0.5,
		},
	},
	'@keyframes dotAnim': {
		'0%, 20%': {
			color: 'rgba(0,0,0,0)',
			textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
		},
		'40%': {
			color: theme.palette.secondary.main,
			textShadow: '.25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0)',
		},
		'60%': {
			textShadow: `.25em 0 0 ${theme.palette.secondary.main}, .5em 0 0 rgba(0,0,0,0)`,
		},
		'80%, 100%': {
			textShadow: `.25em 0 0 ${theme.palette.secondary.main}, .5em 0 0 ${theme.palette.secondary.main}`,
		},
	},
}));
