import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		width: '100%',
		alignSelf: 'self-start',
	},
	title: {
		fontSize: 28,
		fontWeight: 500,
	},
	description: {
		fontSize: 14,
		marginBottom: theme.spacing(3),
	},
	separateLine: {
		position: 'relative',
		borderColor: theme.palette.separate.main,
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	welcomeBox: {},
	detailsWrapper: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		marginTop: theme.spacing(3),
		[theme.breakpoints.up('lg')]: {
			flexDirection: 'row',
		},
	},
	detailsBox: {
		width: '100%',
		padding: theme.spacing(4),
		// borderRadius: 12,
		// border: `1px solid ${alpha(theme.palette.common.white, 0.5)}`,
		// backgroundColor: alpha(theme.palette.common.white, 0.3),
	},
	balanceTitle: {
		fontSize: 25,
		fontWeight: 500,
	},
	lastTransaction: {
		fontSize: 18,
		fontWeight: 500,
		textTransform: 'uppercase',
		color: theme.palette.grey[200],
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(4),
	},
	qrCodeWrapper: {
		position: 'relative',
		borderRadius: 4,
		width: 101,
		height: 101,
		backgroundColor: theme.palette.common.white,
		marginLeft: 'auto',
		padding: theme.spacing(1),
		zIndex: 2,
		order: 1,
		[theme.breakpoints.up('md')]: {
			width: 162,
			height: 162,
			padding: theme.spacing(2),
		},
		[theme.breakpoints.up('lg')]: {
			marginLeft: 0,
			order: 2,
		},
	},
	chartLoadingText: {
		width: '100%',
	},
}));
