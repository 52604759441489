/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { API_URL } from 'config/constants';

const getNewFeatures = async () => {
	let newfeatures = [];
	try {
		const response = await axios.post(`${API_URL}/newfeatures`);
		if (response.status === 200 && response.data?.success) {
			newfeatures = response.data.data;
			// newfeatures = [
			// 	{ id: 1, title: 'Pending Transactions', content: 'OK YES' },
			// 	{ id: 2, title: 'Transactions', content: 'OK' },
			// ];
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		newfeatures = [];
	}

	return newfeatures;
};

export default getNewFeatures;
