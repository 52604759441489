import React, { useState, useEffect, useContext, useRef } from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, IconButton, Avatar as TokenImg } from '@itsa.io/ui';
import { useIntl, cryptowalletCtx, formatBN, toBN } from '@itsa.io/web3utils';
import maincoinpricesCtx from 'context/maincoinprices';
import subscriptionCtx from 'context/subscription';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import gaspriceCtx from 'context/gasprice';
import navigatorCtx from 'context/navigator';
import securedsendtxCtx from 'context/securedsendtx';
import {
	subscribe,
	enoughFundsToSubscribe,
} from 'utils/smartcontracts/itsa-subscription';
import currencyCtx from 'context/currency';
import tokenListCtx from 'context/tokenlist';
import useAlert from 'hooks/useAlert';
import useConfirm from 'hooks/useConfirm';
import getRemainingDays from 'utils/get-remaining-days';
import GasSlider from 'components/common/GasSlider';
import {
	NETWORK_NAMES,
	NETWORK_ICONS,
	ITSA_SUBSCRIPTION_CHAINS,
	ITSA_SUBSCRIPTION_SC_ADDRESSES,
	SUBSCRIPTIONS_ENABLED,
	CHAIN_SYMBOLS,
	GAS_PERCENTAGES,
	BN_ZERO,
} from 'config/constants';

import useStyles from 'styles/pages/SubscriptionPage';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

const DAYS = {
	30: '30',
	90: '90',
	365: '365',
};

const valueDaysArray = Object.values(DAYS);

const getInitialNetwork = chainId => {
	if (ITSA_SUBSCRIPTION_SC_ADDRESSES[chainId]) {
		return chainId;
	}
	if (ITSA_SUBSCRIPTION_SC_ADDRESSES[1]) {
		return 1;
	}
	if (ITSA_SUBSCRIPTION_SC_ADDRESSES[56]) {
		return 56;
	}
	if (ITSA_SUBSCRIPTION_SC_ADDRESSES[39797]) {
		return 39797;
	}
	return 1;
};

const calculateExtendDays = (t, expiration, nrOfDays = '0') => {
	const ms = parseInt(nrOfDays, 10) * MS_PER_DAY;
	const newExpiration = new Date(expiration.getTime() + ms);
	return getRemainingDays(t, newExpiration);
};

const getDaysToExpand = (expiration, maxSubscriptionDays) => {
	if (!expiration) {
		return maxSubscriptionDays;
	}
	const taken = new Date(expiration.getTime() - Date.now());
	const daysTaken = taken / MS_PER_DAY;
	return maxSubscriptionDays - daysTaken;
};

const SubscriptionPage = () => {
	const { t } = useIntl();
	const { chainId, address, hardwareWallet, switchToNetwork } =
		useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const subscription = useContext(subscriptionCtx);
	const mainCoinPrices = useContext(maincoinpricesCtx);
	const { gasprice } = useContext(gaspriceCtx);
	const { currency } = useContext(currencyCtx);
	const { setPage } = useContext(navigatorCtx);
	const { data: tokenList } = useContext(tokenListCtx);
	const prevChainId = useRef();
	const prevAddress = useRef();

	const {
		subscriptionChain,
		hasTrial,
		hasFreeSubscription,
		isSubscribed,
		expiration,
		trialExpiration,
		maxSubscriptionDays,
		hasValidBindToSubscription,
	} = subscription;

	const classes = useStyles();
	const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const alert = useAlert();
	const confirm = useConfirm();
	const isMounted = useRef(false);
	const realSubscriptionChain = hasValidBindToSubscription
		? null
		: subscriptionChain;
	const currentNetworkIcon = NETWORK_ICONS[realSubscriptionChain];
	const [selectedNetwork, setSelectNetwork] = useState(
		getInitialNetwork(chainId),
	);
	const hasExpirationDate = !!(expiration || trialExpiration);
	const exprirationDate =
		hasExpirationDate && (isSubscribed ? expiration : trialExpiration);
	let networkChoices;
	let subscriptionExpireText;
	let subscriptionExpire;
	let networkSubscribedOn;
	const daysToExpand = getDaysToExpand(expiration, maxSubscriptionDays);
	const canSubscribe = {
		30: daysToExpand >= 30,
		90: daysToExpand >= 90,
		365: daysToExpand >= 365,
	};
	const balance = tokenList[0] ? tokenList[0].coinBalance : BN_ZERO;

	const handleClose = () => {
		setPage('SettingsPage');
	};

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (prevChainId.current && prevAddress.current) {
			handleClose();
		} else {
			if (chainId) {
				prevChainId.current = chainId;
			}
			if (address) {
				prevAddress.current = address;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId, address]);

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	const handleNetwork = id => {
		setSelectNetwork(id);
	};

	const getPriceContent = (nrOfDays = 30) => {
		let dailyFee;
		let fee;
		let feeFiat;
		let feeFiatCurrency;
		const useChainId = realSubscriptionChain || selectedNetwork;
		if (subscription?.chainSubscriptions) {
			const chainSubscription = subscription.chainSubscriptions[useChainId];
			if (chainSubscription) {
				dailyFee = chainSubscription.dailyFee;
			}
		}
		if (dailyFee) {
			fee = `${formatBN(dailyFee.mul(toBN(nrOfDays)), {
				assetDigits: 18,
				minDigits: 2,
			})} ${CHAIN_SYMBOLS[useChainId]}`;
			const coinPrices = mainCoinPrices[useChainId];

			if (coinPrices) {
				const amount = parseFloat(
					formatBN(dailyFee.mul(toBN(nrOfDays)), {
						assetDigits: 18,
						minDigits: 2,
					}).replaceAll(',', '.'),
				);
				const priceCents = Math.round(
					100 * amount * coinPrices[currency.toLowerCase()],
				).toString();
				const price = formatBN(toBN(priceCents), {
					assetDigits: 2,
					minDigits: 2,
					decimals: 2,
				});
				feeFiat = `${t('page.subscriptionpage.about')} ${price}`;
				feeFiatCurrency = currency;
			}
		}
		return (
			<div className={classes.priceWrap}>
				<div className={classes.price}>{fee}</div>{' '}
				<div className={classes.priceFiat}>
					{feeFiat}
					<span className={classes.currency}>{feeFiatCurrency}</span>
				</div>
			</div>
		);
	};

	const handleSubscribe = async (nrOfDays = '30') => {
		let tx;
		let revertToChainId;

		try {
			// convert a string to a number
			nrOfDays = parseInt(nrOfDays, 10);
			const enoughFunds = await enoughFundsToSubscribe(
				chainId,
				nrOfDays,
				balance,
			);
			if (!enoughFunds) {
				alert(
					t('page.subscriptionpage.not_enough_funds_to_subscribe'),
					'error',
				);
				return;
			}
			setSendButtonEnabled(false);
			const useChainId = realSubscriptionChain || selectedNetwork;
			if (useChainId !== chainId) {
				if (hardwareWallet) {
					const confirmed = await confirm(
						`${t('page.promopage.switch_network')} ${
							NETWORK_NAMES[selectedNetwork]
						}?`,
						'info',
					);
					if (!confirmed) {
						if (isMounted.current) {
							setSendButtonEnabled(true);
						}
						return;
					}
					revertToChainId = chainId;
				}
				await switchToNetwork(useChainId);
			}
			// "networkswitch has got its own catch: we don;t want a message when it gets cancelled"
			// the trial will have a catch:
			try {
				// convert a string to a number
				nrOfDays = parseInt(nrOfDays, 10);
				// note that whenever switchToNetwork is called, chainId is not yet updated to useChainId: so we need to use useChainId
				tx = await subscribe(
					useChainId,
					nrOfDays,
					address,
					sendTx,
					gasprice,
					getExtraPercentageGas(),
					hardwareWallet,
				);
				handleClose();
			} catch (err) {
				if (isMounted.current) {
					setSendButtonEnabled(true);
				}
				let newError = err;
				if (typeof newError.message === 'string') {
					// this is weird: err.message can be a message (String) that looks like this:
					// "JSON-RPC error.... { code: xxx, message: ''}"
					// so we need to extract the error and errorcode manually:
					const indexCurlyBracket = newError.message.indexOf('{');
					if (indexCurlyBracket !== -1) {
						newError = newError.message.substring(indexCurlyBracket);
						try {
							newError = JSON.parse(newError);
						} catch (err2) {
							newError = err; // revert
							// eslint-disable-next-line no-console
							console.error(err2);
							alert(err2.message, 'error');
						}
					}
				}
				alert(err.message, 'error');
			}
		} catch (err) {
			if (isMounted.current) {
				setSendButtonEnabled(true);
			}
			// no errormessage on canceling networkswitch
		}
		if (revertToChainId) {
			try {
				if (isMounted.current) {
					await switchToNetwork(revertToChainId);
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		}
		// eslint-disable-next-line consistent-return
		return tx;
	};

	const networkIconItems = Object.keys(ITSA_SUBSCRIPTION_CHAINS).map(
		chainid => {
			const name = NETWORK_NAMES[chainid];
			const icon = NETWORK_ICONS[chainid];
			const value = parseInt(chainid, 10);

			return (
				<IconButton
					className={clsx(classes.buttonIcon, {
						[classes.buttonIconActive]: selectedNetwork === value,
					})}
					disabled={
						!sendButtonEnabled || !ITSA_SUBSCRIPTION_SC_ADDRESSES[chainid]
					}
					onClick={() => handleNetwork(value)}
					key={chainid}
				>
					<TokenImg
						className={classes.icon}
						alt={name}
						width="40"
						height="40"
						src={icon}
					>
						<TokenImg className={classes.iconImgUnknown} alt={name} src="">
							?
						</TokenImg>
					</TokenImg>
				</IconButton>
			);
		},
	);

	if (!isSubscribed && !hasTrial) {
		networkChoices = (
			<>
				<div className={classes.sectionTitle}>
					{t('page.subscriptionpage.network_title')}
				</div>
				{networkIconItems}
			</>
		);
	}

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider
				disabled={!sendButtonEnabled}
				value={gaspriceIndex}
				onChange={changeGaspriceIndex}
				className={classes.gaspriceSlider}
			/>
		);
	}

	const planItems = valueDaysArray.map(plan => {
		let extendText;
		if (isSubscribed) {
			extendText = (
				<div className={classes.extendDays}>
					{t('page.subscriptionpage.extend')}{' '}
					{calculateExtendDays(t, expiration, plan)}{' '}
				</div>
			);
		}
		return (
			<div className={classes.optionContainer} key={plan}>
				<div className={classes.optionLabel}>
					<div>
						{t(`page.subscriptionpage.${plan}_days`)}
						{extendText}
					</div>
					<div className={classes.price}>{getPriceContent(plan)}</div>
				</div>
				{gaspriceSlider}
				<Button
					className={classes.upgradeButton}
					disabled={
						!sendButtonEnabled || !SUBSCRIPTIONS_ENABLED || !canSubscribe[plan]
					}
					size="small"
					variant="outlined"
					onClick={() => handleSubscribe(plan)}
					disableElevation
				>
					{t('page.subscriptionpage.select_plan')}
				</Button>
			</div>
		);
	});

	if (hasTrial) {
		subscriptionExpireText = `${t(
			'page.subscriptionpage.trial_will_expire',
		)} ${getRemainingDays(t, exprirationDate)}`;
	} else if (isSubscribed) {
		if (hasExpirationDate) {
			subscriptionExpireText = `${t(
				'page.subscriptionpage.plan_will_expire',
			)} ${getRemainingDays(t, exprirationDate)}`;
		} else {
			subscriptionExpireText = t('page.subscriptionpage.plan_has_expired');
		}
	}

	if (subscriptionExpireText) {
		subscriptionExpire = (
			<div className={classes.subscriptionInfo}>{subscriptionExpireText}</div>
		);
	}

	if ((isSubscribed || hasTrial) && !hasFreeSubscription) {
		networkSubscribedOn = (
			<>
				<div className={classes.networkInfo}>Subscribed on Network</div>
				<TokenImg
					className={classes.icon}
					alt=""
					width="40"
					height="40"
					src={currentNetworkIcon}
				>
					<TokenImg className={classes.iconImgUnknown} alt="" src="">
						?
					</TokenImg>
				</TokenImg>
			</>
		);
	}

	return (
		<div className={classes.root}>
			<div className={classes.title}>{t('page.subscriptionpage.title')}</div>
			{/* <div className={classes.description}>
				{t('page.subscriptionpage.description')}
			</div> */}
			<hr className={classes.separateLine} />
			<div className={classes.subscriptionContainer}>{networkSubscribedOn}</div>
			{subscriptionExpire}
			{networkChoices}
			<div className={classes.sectionTitle}>
				{t('page.subscriptionpage.plan_title')}
			</div>
			{/* <div className={classes.planDescription}>
				{t('page.subscriptionpage.choose_plan')}
			</div> */}
			<div className={classes.planPriceChange}>
				{t('page.subscriptionpage.prices_will_change')}
			</div>
			{planItems}
		</div>
	);
};

SubscriptionPage.defaultProps = {};

SubscriptionPage.propTypes = {};

export default SubscriptionPage;
