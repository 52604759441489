import React, { useContext } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@itsa.io/ui';
import darkmodeCtx from 'context/darkmode';

import useStyles from 'styles/components/settings/LightTheme';

const LightTheme = () => {
	const classes = useStyles();
	const { darkmode, toggleDarkmode } = useContext(darkmodeCtx);

	const handleRadioButtonsChange = event => {
		// note: typeof event.target.value === 'string'
		toggleDarkmode(event.target.value === 'true');
	};

	return (
		<FormControl component="fieldset">
			<RadioGroup
				aria-label="lightdarkmode"
				className={classes.radioGroup}
				name="Light theme"
				onChange={handleRadioButtonsChange}
				value={darkmode}
				row
			>
				<FormControlLabel
					control={<Radio color="primary" />}
					label="light"
					value={false}
				/>
				<FormControlLabel
					control={<Radio color="primary" />}
					label="dark"
					value
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default LightTheme;
