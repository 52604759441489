/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	getSilverTokenMetadata,
	getGoldTokenMetadata,
	getPlatinaTokenMetadata,
} from 'utils/smartcontracts/itsa-wallet-nft';
import navigatorCtx from 'context/navigator';
import nftcardCtx from 'context/nftcard';
import useStyles from 'styles/components/common/NftCard';
import { PAGES_NAMES } from 'config/constants';
import { cloneDeep } from 'lodash';

const NftCard = ({ chainid, className, clickable, type, nftId }) => {
	const classes = useStyles();
	const nftObject = useRef();
	const [image, setImage] = useState('');
	const { setPage } = useContext(navigatorCtx);
	const { setSelectedNftCard } = useContext(nftcardCtx);

	const loadMetadata = async () => {
		let metadata;
		if (type === 'silver') {
			metadata = await getSilverTokenMetadata(chainid, nftId);
		} else if (type === 'gold') {
			metadata = await getGoldTokenMetadata(chainid, nftId);
		} else if (type === 'platina') {
			metadata = await getPlatinaTokenMetadata(chainid, nftId);
		}
		if (metadata) {
			metadata = cloneDeep(metadata);
			const extraAddressItem = metadata.attributes?.find(
				md => md.trait_type === 'Extra Addresses',
			);
			if (extraAddressItem) {
				metadata.extraAddress = extraAddressItem.value;
			}
			nftObject.current = {
				type,
				chainid,
				nftId,
				metadata,
			};
		}
		if (metadata?.image) {
			setImage(metadata.image);
		}
	};

	const gotoNftPage = () => {
		if (clickable) {
			setSelectedNftCard(nftObject.current);
			setPage(PAGES_NAMES.NFTADRESSES);
		}
	};

	useEffect(() => {
		loadMetadata();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			className={clsx(className, classes.root, {
				[classes.clickable]: clickable,
			})}
			dangerouslySetInnerHTML={{ __html: image }}
			onClick={gotoNftPage}
		/>
	);
};
NftCard.defaultProps = {
	className: null,
	clickable: false,
};

NftCard.propTypes = {
	chainid: PropTypes.number.isRequired,
	className: PropTypes.string,
	clickable: PropTypes.bool,
	type: PropTypes.oneOf(['silver', 'gold', 'platina']).isRequired,
	nftId: PropTypes.number.isRequired,
};

export default NftCard;
