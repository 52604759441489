import React, { useContext, useState, useRef, useEffect, memo } from 'react';
import clsx from 'clsx';
import { utils } from 'web3';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import {
	Box,
	Button,
	ListItem,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
	useMediaQuery,
} from '@itsa.io/ui';
import {
	Search as SearchIcon,
	Done as DoneIcon,
	DeleteForeverOutlined as DeleteForeverOutlinedIcon,
	Cancel as CancelIcon,
} from '@material-ui/icons';
import { FixedSizeList as List } from 'react-window';
import {
	SHOW_SEARCHFIELD_ON_CUSTOMTOKENS_COUNT,
	NETWORK_NAMES,
} from 'config/constants';
import customTokensCtx from 'context/customtokens';
import useStyles from 'styles/pages/CustomTokensPage';

const { isAddress } = utils;

const WAIT_INTERVAL = 200;
const WIDTH = '100%';
const MAX_REQUIRED_HEIGHT_LIST = 700;
const MIN_REQUIRED_HEIGHT_LIST = 562;
const FREE_REQUIRED_SPACE_LIST = 138;
const ITEM_SIZE = 50;

const CustomTokensPage = () => {
	const classes = useStyles();
	const { chainId } = useContext(cryptowalletCtx);
	const { t } = useIntl();
	const theme = useTheme();
	const smallScreenSize = useMediaQuery(theme.breakpoints.up('sm'));
	const minHeightScreenSize = useMediaQuery(
		`(min-height:${MAX_REQUIRED_HEIGHT_LIST}px)`,
	);
	const [searchValue, setSearchValue] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [editAddress, setEditAddress] = useState('');
	const timeoutRef = useRef();
	const isMounted = useRef();
	const [newToken, setNewToken] = useState('');
	const [addingToken, setAddingToken] = useState(false);
	const inputRef = useRef();
	const { customTokens, addCustomToken, removeCustomToken } =
		useContext(customTokensCtx);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	// temporarely disabled: seems to be invoked too many times
	// TODO: fix autofocus
	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			if (isMounted.current) {
				setSearchQuery(searchValue);
			}
		}, WAIT_INTERVAL);

		return () => clearTimeout(timeoutRef.current);
	}, [searchValue]);

	const title = (
		<Box className={classes.title}>
			{NETWORK_NAMES[chainId]} {t('page.customtokenpage.custom_tokens')}
		</Box>
	);

	const handleSearchChange = e => {
		setSearchValue(e.target.value);
	};

	const handleChangeAddress = e => {
		setEditAddress(e.target.value);
	};

	const handleBlur = customToken => {
		customToken.address = editAddress;
		setEditAddress('');
		if (isAddress(customToken.address)) {
			addCustomToken(customToken.address);
		}

		setAddingToken(false);
	};

	const handleKeyDown = (customToken, e) => {
		if (e.keyCode === 27) {
			setEditAddress('');
		}
		if (e.keyCode === 13) {
			handleBlur(customToken);
		}
	};

	const handleDeleteCustomToken = (e, customToken) => {
		e.stopPropagation();
		removeCustomToken(customToken);
	};

	const cancelNewToken = () => {
		setNewToken('');
		setAddingToken(false);
	};

	let searchField;
	if (customTokens.length >= SHOW_SEARCHFIELD_ON_CUSTOMTOKENS_COUNT) {
		searchField = (
			<TextField
				className={classes.textFieldSearch}
				label="Search"
				variant="filled"
				onChange={handleSearchChange}
				value={searchValue}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon className={classes.searchIcon} />
						</InputAdornment>
					),
					disableUnderline: true,
				}}
				fullWidth
			/>
		);
	}

	const newListFound = customTokens.filter(token => {
		const { name, symbol, address } = token;
		const query = searchQuery.toLowerCase();
		const tokenFound =
			query === '' ||
			name.toLowerCase().includes(query) ||
			address.toLowerCase().includes(query) ||
			symbol.toLowerCase().includes(query);
		return tokenFound;
	});

	const itemCount = newListFound.length;
	const listItemsRender = ({ index, style }) => {
		const token = newListFound[index];
		const { address, symbol } = token;

		const isEditField = editAddress === address;

		let ref;
		if (isEditField) {
			ref = inputRef;
		}

		return (
			<ListItem
				component="div"
				className={clsx(classes.item)}
				style={style}
				key={address || index}
			>
				<div className={classes.containerItem}>
					<div className={classes.itemSymbol}>{symbol}</div>
					<TextField
						className={clsx(classes.textFieldAddress, {
							[classes.textFieldDisabled]: !isEditField,
						})}
						placeholder="0x..."
						color="primary"
						value={address}
						onBlur={() => handleBlur(token)}
						onChange={handleChangeAddress}
						onKeyDown={e => handleKeyDown(token, e)}
						disabled={!isEditField}
						ref={ref}
						fullWidth
						InputProps={{
							disableUnderline: !isEditField,
						}}
					/>
					<IconButton
						className={classes.iconButton}
						onClick={e => handleDeleteCustomToken(e, token)}
						size="small"
					>
						<DeleteForeverOutlinedIcon />
					</IconButton>
				</div>
			</ListItem>
		);
	};

	// Standard required list height: 562px that need for fixed height: 700px
	let height = MIN_REQUIRED_HEIGHT_LIST;
	// Calculate correct height, if window innerHeight is smallest than required list fixed height: 700px
	if (!minHeightScreenSize) {
		height = window.innerHeight - FREE_REQUIRED_SPACE_LIST;
	}
	// Note: in extra small screen size, the list height gets 100%
	// Calculate correct height, if in extra small screen size and window innerHeight is greatest than required list fixed height: 700px
	if (minHeightScreenSize && !smallScreenSize) {
		height = window.innerHeight - FREE_REQUIRED_SPACE_LIST;
	}

	const listContent = (
		<List
			className={classes.tokenListContainer}
			width={WIDTH}
			height={height}
			itemSize={ITEM_SIZE}
			itemCount={itemCount}
		>
			{listItemsRender}
		</List>
	);

	// item for adding a new token:
	let addTokenButton;
	if (addingToken) {
		const customToken = {
			address: newToken,
			chainId,
		};

		addTokenButton = (
			<div className={clsx(classes.item, classes.addToken)} key="newtoken">
				<div className={classes.editContainerItem}>
					<TextField
						className={clsx(classes.editTextFieldAddress, {
							[classes.textFieldDisabled]: !addingToken,
						})}
						placeholder={t('page.customtokenpage.add_tokenaddress')}
						color="primary"
						value={editAddress}
						onBlur={() => handleBlur(customToken)}
						onChange={handleChangeAddress}
						onKeyDown={e => handleKeyDown(customToken, e)}
						disabled={!addingToken}
						ref={inputRef}
						fullWidth
						InputProps={{
							disableUnderline: !addingToken,
						}}
					/>
					<div className={classes.editIconsWrap}>
						<IconButton
							className={classes.editIconButton}
							onClick={() => handleBlur(customToken)}
							size="small"
						>
							<DoneIcon />
						</IconButton>
						<IconButton
							className={classes.editIconButton}
							onClick={cancelNewToken}
							size="small"
						>
							<CancelIcon />
						</IconButton>
					</div>
				</div>
			</div>
		);
	} else {
		addTokenButton = (
			<Button
				className={classes.button}
				variant="contained"
				color="primary"
				onClick={() => {
					setAddingToken(true);
				}}
				disableElevation
			>
				{t('page.customtokenpage.add_customtoken')}
			</Button>
		);
	}

	return (
		<>
			<div className={classes.root}>
				{title}
				<hr className={classes.separateLine} />
				{searchField}
				{addTokenButton}
				{listContent}
			</div>
		</>
	);
};

export default memo(CustomTokensPage);
