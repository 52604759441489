import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		width: '100%',
		alignSelf: 'self-start',
	},
	title: {
		fontSize: 28,
		fontWeight: 500,
	},
	description: {
		fontSize: 14,
		marginBottom: theme.spacing(3),
	},
	separateLine: {
		position: 'relative',
		top: -theme.spacing(0.5),
		borderColor: theme.palette.separate.main,
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	optionWrapper: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexBasis: '50%',
		flexGrow: 0,
		width: 'auto',
		gap: theme.spacing(3),
		marginTop: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			width: 800,
		},
	},
	optionContainer: {
		position: 'relative',
		maxWidth: 375,
		width: '100%',
		padding: theme.spacing(3),
		backgroundColor: theme.palette.default.light,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: 6,
	},
	exclamationWrap: {
		display: 'initial',
	},
	expired: {
		backgroundColor: theme.palette.grey[600],
		border: `1px solid ${alpha(theme.palette.red[400], 0.5)}`,
	},
	optionLabel: {
		fontSize: 16,
		fontWeight: 500,
		marginBottom: theme.spacing(3),
	},
	button: {
		display: 'inline-block',
		width: '100%',
	},
}));
