import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@itsa.io/ui';
import { useIntl } from '@itsa.io/web3utils';

import useStyles from 'styles/components/settings/NewVersionApp';

const NewVersionApp = ({ latestVersion, isNewerVersion }) => {
	const classes = useStyles();
	const { t } = useIntl();
	let newerWalletVersionContent = null;

	const reloadPage = () => {
		window.location.reload(true); // `true` is meant for FF to force a reload without its cache
	};

	if (isNewerVersion) {
		const versionText = `${t(
			'settings.new_version_app.available_update',
		)} ${latestVersion}`;
		newerWalletVersionContent = (
			<Button
				className={classes.upgradeButton}
				size="small"
				variant="contained"
				onClick={reloadPage}
				disableElevation
			>
				{versionText}
			</Button>
		);
	}

	return newerWalletVersionContent;
};

NewVersionApp.propTypes = {
	latestVersion: PropTypes.string.isRequired,
};

export default NewVersionApp;
