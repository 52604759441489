import { makeStyles } from '@itsa.io/ui';
import { FONT_SIZE_DECIMALS } from 'config/constants';

export default makeStyles(theme => ({
	root: {
		margin: theme.spacing(7, 0, 5),
	},
	red: {
		color: theme.palette.red[900],
	},
	green: {
		color: theme.palette.green[900],
	},
	pending: {
		color: theme.palette.orange[300],
	},
	chipDirection: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.grey[200],
		borderRadius: 6,
		minWidth: 110,
		'& .MuiChip-icon': {
			color: theme.palette.primary.main,
		},
	},
	tableContainer: {
		'& span.bn-decimal': {
			fontSize: FONT_SIZE_DECIMALS,
		},
	},
	estimated: {
		position: 'absolute',
		display: 'inline-block',
		paddingLeft: 2,
	},
	swappedSymbolBox: {
		display: 'flex',
		alignItems: 'center',
	},
	firstSymbol: {
		marginLeft: '0.25em',
	},
	statusIncoming: {
		// backgroundColor: theme.palette.common.white,
		color: theme.palette.orange[900],
		borderRadius: 6,
		border: `1px solid ${theme.palette.orange[900]}`,
	},
	watchLaterIcon: {
		color: theme.palette.orange[900],
	},
	statusCompleted: {
		// backgroundColor: theme.palette.common.white,
		color: theme.palette.green[900],
		borderRadius: 6,
		border: `1px solid ${theme.palette.green[900]}`,
	},
	checkCircleIcon: {
		color: theme.palette.green[900],
	},
}));
