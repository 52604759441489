import { getMasternodeTokenContract } from 'utils/get-contract';
import { utils } from 'web3';
import { MASTERNODE_TOKEN_ADDRESS, HEX0 } from 'config/constants';
import { BN_GAS_LIMIT_UNIT_PRICES } from 'config/constants/transaction-gas-units';

import { getWeb3 } from 'utils/get-web3';
import setGasLimitAndPrice from 'utils/set-gas-limit-and-price';

const errorMessages = {
	MASTERNODE_CONTRACT_EXCEPTION: 'Master Contract Exception',
	NOT_ENOUGH_BALANCE: 'Not enough balance',
	INVALID_SMARTCONTRACT_ADDRESS: 'Invalid Wrap SmartContract Address',
};

/**
 * Generates an Exception thrown by a claim error
 *
 * @method MasternodeException
 * @protected
 * @param txResponse {Object} the txResponse that raised the exception
 * @since 0.0.1
 */
// eslint-disable-next-line func-names
const MasternodeException = function (msg) {
	// note: do not use arrow function, because we need to maintain the right context
	let keys;
	let l;
	let i;
	let key;

	this.name = 'MasternodeException';
	if (typeof msg === 'string') {
		this.message = msg;
	} else {
		this.message = errorMessages.MASTERNODE_CONTRACT_EXCEPTION;
		keys = Object.keys(msg);
		l = keys.length;
		i = -1;
		// eslint-disable-next-line no-plusplus
		while (++i < l) {
			key = keys[i];
			this[key] = msg[key];
		}
	}
};

export const deposit = async (
	chainId,
	owner,
	amount,
	isAnnouncedMasternode,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const depositContract = getMasternodeTokenContract(chainId);
	if (utils.isBN(amount)) {
		amount = amount.toString();
	}

	if (amount === '0') {
		throw new MasternodeException(errorMessages.NOT_ENOUGH_BALANCE);
	}

	const transactionData = depositContract.methods
		.depositCollateral()
		.encodeABI();

	const rawTx = {
		to: MASTERNODE_TOKEN_ADDRESS,
		data: transactionData,
		from: owner,
		value: utils.toHex(amount),
		// gasLimit: utils.toHex(
		// 	toBN(GAS_LIMIT_MASTERNODE_DEPOSIT)
		// 		.mul(toBN((100 + extraPercentageGas).toString()))
		// 		.div(toBN('100'))
		// 		.toString(),
		// ),
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new MasternodeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}
	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.masternode[chainId][
			isAnnouncedMasternode ? 'depositWhileAnnounced' : 'deposit'
		],
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });

	// Metamask transaction: return tx as a String:
	return tx;
};

export const withdraw = async (
	chainId,
	owner,
	amount,
	withdrawAll,
	isAnnouncedMasternode,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	const withdrawContract = getMasternodeTokenContract(chainId);
	if (utils.isBN(amount)) {
		amount = amount.toString();
	}

	if (amount.toString() === '0') {
		throw new MasternodeException(errorMessages.NOT_ENOUGH_BALANCE);
	}

	const transactionData = withdrawContract.methods
		.withdrawCollateral(amount)
		.encodeABI();

	const rawTx = {
		to: MASTERNODE_TOKEN_ADDRESS,
		data: transactionData,
		from: owner,
		value: HEX0,
		// gasLimit: utils.toHex(
		// 	toBN(GAS_LIMIT_MASTERNODE_WITHDRAW)
		// 		.mul(toBN((100 + extraPercentageGas).toString()))
		// 		.div(toBN('100'))
		// 		.toString(),
		// ),
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new MasternodeException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	let gaslimitBN;
	if (isAnnouncedMasternode) {
		if (withdrawAll) {
			gaslimitBN =
				BN_GAS_LIMIT_UNIT_PRICES.masternode[chainId]
					.withdrawWhileAnnouncedAndDenounce;
		} else {
			gaslimitBN =
				BN_GAS_LIMIT_UNIT_PRICES.masternode[chainId].withdrawWhileAnnounced;
		}
	} else {
		gaslimitBN = BN_GAS_LIMIT_UNIT_PRICES.masternode[chainId].withdraw;
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		gaslimitBN,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });

	// Metamask transaction: return tx as a String:
	return tx;
};
