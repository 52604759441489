import React, { useEffect, useState, useRef, useContext, memo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	Box,
	Popover,
	TableContainer as MuiTableContainer,
	Table as MuiTable,
	TableHead as MuiTableHead,
	TableBody as MuiTableBody,
	TableCell as MuiTableCell,
	TableRow as MuiTableRow,
	TableSortLabel as MuiTableSortLabel,
	MuiThemeProvider,
	createTheme,
	useTheme,
	useMediaQuery,
} from '@itsa.io/ui';
import * as d3 from 'd3';
import { InfoOutlined as InfoOutlinedIcon } from '@itsa.io/ui/icons';
import { useIntl, formatBN } from '@itsa.io/web3utils';
import Color from 'color-thief-react';
import lightTheme from 'styles/light-theme';
import darkTheme from 'styles/dark-theme';
import darkmodeCtx from 'context/darkmode';
import currencyCtx from 'context/currency';
import useStyles from 'styles/components/common/DonutChart';
import { isEqual, cloneDeep } from 'lodash';

const padding = 1.06;
const Slice = ({ data, width, theme }) => {
	const radius = width / 2;
	const pie = d3
		.pie()
		.value(d => d.value)
		.sort(null)
		.padAngle(0.01);
	const dataReady = pie(data);

	return dataReady.map((slice, index) => {
		const key = `slice-${index}`;
		const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, slice);

		return (
			<Path
				radius={radius}
				data={data}
				slice={slice}
				interpolate={interpolate}
				theme={theme}
				key={key}
			/>
		);
	});
};

const Path = ({ radius, slice, interpolate, theme, data }) => {
	const { currency } = useContext(currencyCtx);
	const groupRef = useRef(null);
	const isTransitionEnd = useRef(false);
	const prevData = useRef(cloneDeep(data));
	const [isHovered, setHover] = useState(false);
	const outerRadius = isHovered ? radius * padding : radius;
	const innerRadius = radius * 0.7;
	const shadowWidth = 3;
	const outerRadiusShadow = innerRadius + 1;
	const innerRadiusShadow = innerRadius - shadowWidth;
	const arc1 = d3
		.arc()
		.innerRadius(innerRadius)
		.outerRadius(outerRadius)
		.cornerRadius(2);
	const arc2 = d3
		.arc()
		.innerRadius(innerRadiusShadow)
		.outerRadius(outerRadiusShadow);
	const { name, valueBN, color, isCoin, coin, coinWrap, coinMn } = slice.data;
	const c = d3.hsl(color);
	const fillColorHSL = d3.hsl(c.h + 5, c.s - 0.07, c.l - 0.15);
	const currencySymbol = currency.toUpperCase();
	const fiatValue = `${formatBN(valueBN, {
		assetDigits: 18,
		minDigits: 3,
		decimals: 2,
	})} ${currencySymbol}`;
	const coinDetail = [];
	let coinDetailY = 45;
	if (isCoin) {
		if (coin.value.toString() !== '0') {
			coinDetail.push(
				<text
					textAnchor="middle"
					fill={theme.palette.default.main}
					y={coinDetailY}
					fontSize="14px"
					fontWeight={600}
					key={`coin_${coin.symbol}`}
				>
					{coin.symbol}
					{': '}
					{formatBN(coin.valueBN, {
						assetDigits: 18,
						minDigits: 3,
						decimals: 2,
					})}{' '}
					{currencySymbol}
				</text>,
			);
		}
		if (coinWrap.value.toString() !== '0') {
			coinDetailY += 20;
			coinDetail.push(
				<text
					textAnchor="middle"
					fill={theme.palette.default.main}
					y={coinDetailY}
					fontSize="14px"
					fontWeight={600}
					key={`coin_${coinWrap.symbol}`}
				>
					{coinWrap.symbol}
					{': '}
					{formatBN(coinWrap.valueBN, {
						assetDigits: 18,
						minDigits: 3,
						decimals: 2,
					})}{' '}
					{currencySymbol}
				</text>,
			);
		}

		if (coinMn.value.toString() !== '0') {
			coinDetailY += 20;
			coinDetail.push(
				<text
					textAnchor="middle"
					fill={theme.palette.default.main}
					y={coinDetailY}
					fontSize="14px"
					fontWeight={600}
					key={`coin_${coinMn.symbol}`}
				>
					{coinMn.symbol}
					{': '}
					{formatBN(coinMn.valueBN, {
						assetDigits: 18,
						minDigits: 3,
						decimals: 2,
					})}{' '}
					{currencySymbol}
				</text>,
			);
		}
	}

	let graphDetails;

	const endTransition = () => {
		isTransitionEnd.current = true;
	};

	const onMouseOver = e => {
		if (isTransitionEnd.current) {
			setHover(true);
			d3.select(e.target).transition().duration('50').attr('opacity', '.85');
			d3.select('#graphDetails').transition().duration(0).attr('opacity', '0');
		}
	};

	const onMouseOut = e => {
		if (isTransitionEnd.current) {
			setHover(false);
			d3.select(e.target).transition().duration('50').attr('opacity', '1');
			d3.select('#graphDetails')
				.transition()
				.duration(700)
				.attr('opacity', '1');
		}
	};

	const createArc = () => {
		isTransitionEnd.current = false;
		const group = d3.select(groupRef.current);

		group
			.select('.path1')
			.transition()
			.duration(800)
			.on('end', endTransition)
			.attrTween('d', () => {
				return t => arc1(interpolate(t));
			});

		group
			.select('.path2')
			.transition()
			.duration(800)
			.attrTween('d', () => {
				return t => arc2(interpolate(t));
			});
	};

	useEffect(() => {
		if (!isEqual(data, prevData.current)) {
			prevData.current = cloneDeep(data);
			createArc();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slice]);

	if (isHovered) {
		graphDetails = (
			<>
				<circle r={innerRadius * 0.95} fill={color} opacity={0.2} />
				<text
					textAnchor="middle"
					fill={theme.palette.default.main}
					y={-15}
					fontSize="18px"
					fontWeight={700}
				>
					{name}
				</text>
				<text
					textAnchor="middle"
					fill={theme.palette.default.main}
					y={15}
					fontSize="20px"
					fontWeight={600}
				>
					{fiatValue}
				</text>
				{coinDetail}
			</>
		);
	}

	return (
		<g ref={groupRef} id={`group-arc-${name.toLowerCase()}`}>
			<path
				className="path1"
				fill={color}
				d={arc1(slice)}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
			/>
			<path className="path2" fill={fillColorHSL} d={arc2(slice)} />
			{graphDetails}
		</g>
	);
};

Path.propTypes = {
	interpolate: PropTypes.func.isRequired,
	radius: PropTypes.number.isRequired,
	data: PropTypes.array.isRequired,
	slice: PropTypes.shape({
		data: PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
			value: PropTypes.number,
			valueBN: PropTypes.object,
			color: PropTypes.string,
			isCoin: PropTypes.bool,
			coin: PropTypes.shape({
				value: PropTypes.number,
				valueBN: PropTypes.object,
				symbol: PropTypes.string,
			}),
			coinWrap: PropTypes.shape({
				value: PropTypes.number,
				valueBN: PropTypes.object,
				symbol: PropTypes.string,
			}),
			coinMn: PropTypes.shape({
				value: PropTypes.number,
				valueBN: PropTypes.object,
				symbol: PropTypes.string,
			}),
		}),
		startAngle: PropTypes.number,
		endAngle: PropTypes.number,
		padAngle: PropTypes.number,
		value: PropTypes.number,
		index: PropTypes.number,
	}).isRequired,
	theme: PropTypes.shape({
		palette: PropTypes.shape({
			primary: PropTypes.shape({
				main: PropTypes.string,
			}),
			secondary: PropTypes.shape({
				main: PropTypes.string,
			}),
			default: PropTypes.shape({
				main: PropTypes.string,
			}),
		}),
	}).isRequired,
};

const Legend = ({ headers, data, classes }) => {
	const { t } = useIntl();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const theme = useTheme();
	const smallScreenSize = useMediaQuery(theme.breakpoints.up('sm'));
	const mediumScreenSize = useMediaQuery(theme.breakpoints.down('md'));
	const legendNum = data.length;
	let mainContent;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const popoverId = open ? 'coin-info-popover' : undefined;

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};

	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	};

	const stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map(el => el[0]);
	};

	const createSortHandler = property => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createTableRow = row => {
		const { name, value, color, isCoin, coin, coinWrap, coinMn, tokenaddress } =
			row;
		let popoverCoinInfo = null;

		if (isCoin) {
			const coinContent =
				coin.value.toString() !== '0' && `${coin.value}% ${coin.symbol}`;
			const coinWrapContent =
				coinWrap.value.toString() !== '0' &&
				`${coinWrap.value}% ${coinWrap.symbol}`;
			const coinMnContent =
				coinMn.value.toString() !== '0' && `${coinMn.value}% ${coinMn.symbol}`;
			popoverCoinInfo = (
				<>
					<InfoOutlinedIcon
						className={classes.infoIcon}
						fontSize="small"
						aria-owns={open ? popoverId : undefined}
						aria-haspopup="true"
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					/>
					<Popover
						id={popoverId}
						className={classes.popover}
						classes={{
							paper: classes.popoverPaper,
						}}
						open={open}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						onClose={handlePopoverClose}
						disableRestoreFocus
					>
						<div>
							<div>{coinContent}</div>
							<div>{coinWrapContent}</div>
							<div>{coinMnContent}</div>
						</div>
					</Popover>
				</>
			);
		}

		return (
			<MuiTableRow hover tabIndex={-1} key={tokenaddress || name}>
				<MuiTableCell
					className={clsx(classes.tableRowCell, classes.tableRowCellRect)}
				>
					<Box className={classes.legendRect} bgcolor={color} />
				</MuiTableCell>
				<MuiTableCell
					className={clsx(classes.tableRowCell, classes.tableRowCellName)}
				>
					{name}
				</MuiTableCell>
				<MuiTableCell
					className={clsx(classes.tableRowCell, classes.tableRowCellValue)}
				>{`${value}%`}</MuiTableCell>
				<MuiTableCell
					className={clsx(classes.tableRowCell, classes.tableRowCellInfo)}
					align="right"
				>
					{popoverCoinInfo}
				</MuiTableCell>
			</MuiTableRow>
		);
	};

	const tableHead = (
		<MuiTableHead>
			<MuiTableRow>
				{headers.map((name, index) => {
					const tokenaddress = data[index]?.tokenaddress;
					return (
						<MuiTableCell
							className={classes.tableCellHead}
							align={headers.length - 1 === index ? 'right' : 'left'}
							key={tokenaddress || name}
						>
							<MuiTableSortLabel
								active={index > 0 && orderBy === name}
								direction={orderBy === name ? order : 'asc'}
								onClick={() => createSortHandler(name)}
							>
								{t(`donut_chart.${name}`)}
							</MuiTableSortLabel>
						</MuiTableCell>
					);
				})}
			</MuiTableRow>
		</MuiTableHead>
	);

	const tableBody = (
		<MuiTableBody>
			{stableSort(data, getComparator(order, orderBy)).map(row =>
				createTableRow(row),
			)}
		</MuiTableBody>
	);

	const legendColumn = smallScreenSize ? 3 : 2;
	const alignLegendBox = () => {
		const box = [];
		const legendNumRequirt = legendColumn - (legendNum % legendColumn);

		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < legendNumRequirt; i++) {
			box.push(<div className={classes.legendWrapper} key={i} />);
		}
		if (box.length === 0 || legendNumRequirt === legendColumn) {
			return null;
		}
		return box;
	};

	if (mediumScreenSize) {
		mainContent = data.map(item => {
			const { name, value, color, tokenaddress } = item;
			return (
				<div className={classes.legendWrapper} key={tokenaddress || name}>
					<Box className={classes.legendRect} bgcolor={color} />
					<Box className={classes.legendText}>
						{name} - {`${value}%`}
					</Box>
				</div>
			);
		});
		mainContent = (
			<div className={classes.legendContainer}>
				{mainContent}
				{alignLegendBox()}
			</div>
		);
	} else {
		mainContent = (
			<>
				<MuiTableContainer className={classes.tableContainer}>
					<MuiTable className={classes.table} size="small">
						{tableHead}
						{tableBody}
					</MuiTable>
				</MuiTableContainer>
			</>
		);
	}

	return mainContent;
};

Legend.defaultProps = {
	classes: null,
};

Legend.propTypes = {
	headers: PropTypes.array.isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			path: PropTypes.string.isRequired,
			color: PropTypes.string,
		}),
	).isRequired,
	classes: PropTypes.object,
	/*
	theme: PropTypes.shape({
		palette: PropTypes.shape({
			primary: PropTypes.shape({
				main: PropTypes.string,
			}),
			secondary: PropTypes.shape({
				main: PropTypes.string,
			}),
			default: PropTypes.shape({
				main: PropTypes.string,
			}),
		}),
	}).isRequired,
	*/
};

const DonutChart = ({ legendHeaders, data, sum }) => {
	const classes = useStyles();
	const theme = useTheme();
	const mediumScreenSize = useMediaQuery(theme.breakpoints.up('md'));
	const { currency } = useContext(currencyCtx);
	const { darkmode } = useContext(darkmodeCtx);
	const currentTheme = darkmode ? darkTheme : lightTheme;
	const [colorExtractor, setColorExtractor] = useState();
	const [hiddenChart, setHiddenChart] = useState(true);
	const isMounted = useRef(false);
	const groupRef = useRef(null);
	let svgWidth = '100%';
	let svgHeight = '100%';
	const width = 450;
	const height = 450;
	const dataNum = data.length;
	const customTheme = darkmode
		? createTheme({
				palette: {
					type: 'dark',
					primary: {
						main: currentTheme.palette.grey[50],
					},
					secondary: {
						main: currentTheme.palette.grey[200],
					},
					default: {
						main: currentTheme.palette.grey[50],
					},
				},
		  })
		: createTheme({
				palette: {
					type: 'light',
					primary: {
						main: currentTheme.palette.grey[500],
					},
					secondary: {
						main: currentTheme.palette.grey[200],
					},
					default: {
						main: currentTheme.palette.grey[200],
					},
				},
		  });

	const createColors = newData => {
		let finished = false; // prevent infinite state loop
		let count = 0;

		const cbColor = (index, { data: color, loading }) => {
			if (!loading && !finished) {
				count += 1;
				newData[index].color = color;
				if (count === dataNum) {
					finished = true;
					// "setHiddenChart" will set visibility=visible as well as force a rerender where newColorExtractor has its final colors
					// NOTE: we need to set a new state AFTER createColors state has finished
					// Therefore, we will invoke `setHiddenChart` at the end of the event stack
					// by using setTimeout with 150 ms we make sure all colors are well extracted
					setTimeout(() => {
						if (isMounted.current) {
							setHiddenChart(false);
						}
					}, 150);
				}
			}
		};

		const newColorExtractor = newData.map((d, i) => {
			const cbFunc = cbColor.bind(null, i);
			let url = d.path;
			url = `${url.substring(0, 7)}_sized${url.substring(7)}`;
			return (
				<Color src={url} format="hex" key={d.tokenaddress || d.name}>
					{cbFunc}
				</Color>
			);
		});
		if (isMounted.current) {
			setHiddenChart(true);
			setColorExtractor(newColorExtractor);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		createColors(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const graphDetails = (
		<g id="graphDetails">
			<text
				textAnchor="middle"
				fill={customTheme.palette.secondary.main}
				y={-35}
				fontSize="18px"
				fontWeight={700}
			>
				{currency.toUpperCase()}
			</text>
			<text
				textAnchor="middle"
				fill={customTheme.palette.primary.main}
				y={0}
				fontSize="25px"
				fontWeight={700}
			>
				{sum}
			</text>
			<text
				textAnchor="middle"
				fill={customTheme.palette.secondary.main}
				y={25}
				fontSize="18px"
				fontWeight={500}
			>
				Total Account Balance
			</text>
		</g>
	);

	if (mediumScreenSize) {
		svgWidth = 450;
		svgHeight = 450;
	}

	const donutChart = (
		<svg
			width={svgWidth}
			height={svgHeight}
			visibility={hiddenChart ? 'hidden' : 'visible'}
			viewBox={`0 0 ${width * padding} ${height * padding}`}
		>
			<g
				ref={groupRef}
				transform={`translate(${(width / 2) * padding},${
					(height / 2) * padding
				})`}
			>
				<Slice
					width={width}
					// height={height}
					data={data}
					// sum={sum}
					theme={customTheme}
				/>
				{graphDetails}
			</g>
		</svg>
	);

	return (
		<>
			{colorExtractor}
			<MuiThemeProvider theme={customTheme}>
				<div className={classes.root}>
					<div className={classes.donut}>{donutChart}</div>
					<Legend
						headers={legendHeaders}
						data={data}
						classes={classes}
						theme={customTheme}
					/>
				</div>
			</MuiThemeProvider>
		</>
	);
};

DonutChart.propTypes = {
	sum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	legendHeaders: PropTypes.array.isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			path: PropTypes.string.isRequired,
			color: PropTypes.string,
		}),
	).isRequired,
};

export default memo(DonutChart);
