import { useEffect, useState } from 'react';
import newFeatures from 'api/new-features';
import { later } from '@itsa.io/web3utils';
import { cloneDeep, isEqual } from 'lodash';

const UPDATE_INTERVAL = 5 * 60 * 1000; // every 5 mins

const subscribers = [];

let timer;
let prevNewFeatures = [];

const updateNewFeatures = async () => {
	const remoteNewFeatures = await newFeatures();
	if (!isEqual(prevNewFeatures, remoteNewFeatures || [])) {
		prevNewFeatures = cloneDeep(remoteNewFeatures);
		subscribers.forEach(cb => cb(remoteNewFeatures));
	}
};

const subscribe = cb => {
	subscribers.push(cb);
	if (subscribers.length === 1) {
		timer = later(updateNewFeatures, 0, UPDATE_INTERVAL);
	}
};

const unsubscribe = cb => {
	const index = subscribers.findIndex(cbFn => cbFn === cb);
	if (index !== -1) {
		subscribers.splice(index, 1);
	}
	if (subscribers.length === 0 && timer) {
		timer.cancel();
		prevNewFeatures = [];
	}
};

const useNewFeatures = () => {
	const [newFeatures, setNewFeatures] = useState([]);

	useEffect(() => {
		subscribe(setNewFeatures);
		return () => {
			unsubscribe(setNewFeatures);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return newFeatures;
};

export default useNewFeatures;
