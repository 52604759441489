import { makeStyles, alpha } from '@itsa.io/ui';

const LARGE_LEFT_SIDEBAR = '222px';
const MEDIUM_LEFT_SIDEBAR = '72px';

export default makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
	},
	root: {
		position: 'fixed',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		justifyItems: 'center',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: theme.zIndex.drawer + 1,
		[theme.breakpoints.up('md')]: {
			left: MEDIUM_LEFT_SIDEBAR,
		},
		[theme.breakpoints.up('lg')]: {
			left: LARGE_LEFT_SIDEBAR,
		},
	},
	wrapper: {
		width: '100%',
		height: '100%',
		background: theme.palette.background.light,
		margin: 'auto',
		borderRadius: 6,
		boxShadow: `rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;`,
		overflow: 'hidden',
		[theme.breakpoints.up('sm')]: {
			maxWidth: 410,
			maxHeight: 450,
		},
	},
	headerWrap: {
		padding: theme.spacing(2.5, 2.5, 1, 2.5),
	},
	header: {
		display: 'flex',
	},
	hidden: {
		visibility: 'hidden',
		position: 'absolute',
		zIndex: -100,
		left: -9999, // to prevent showing a scrollbar
		top: -9999, // to prevent showing a scrollbar
	},
	tokenListContainer: {
		overflowY: 'auto',
		'-webkitOverflowScrolling': 'touch',
		height: '100%',
		cursor: 'auto',
		[theme.breakpoints.up('sm')]: {
			height: 200,
		},
	},
	item: {
		display: 'flex',
		padding: theme.spacing(1, 2, 1, 2.5),
	},
	tokenImg: {
		width: 38,
		height: 38,
		marginRight: theme.spacing(1.5),
	},
	tokenImgUnknown: {
		fontSize: 24,
		fontWeight: 500,
		color: theme.palette.secondary.light,
	},
	selectedItemIcon: {
		width: 24,
		height: 24,
	},
	selectedUnknownIcon: {
		fontSize: 24,
		fontWeight: 500,
		width: 24,
		height: 24,
		backgroundColor: alpha(theme.palette.grey[300], 0.8),
	},
	itemName: {
		fontSize: 14,
		fontWeight: 300,
	},
	itemSymbol: {
		fontSize: 16,
		fontWeight: 500,
		color: theme.palette.text.primary,
	},
	itemAmount: {
		fontSize: 16,
		fontWeight: 400,
		marginLeft: 'auto',
	},

	title: {
		fontSize: 20,
		fontWeight: 500,
	},
	closeIcon: {
		position: 'relative',
		top: -10,
		right: -12,
		marginLeft: 'auto',
	},
	button: {
		display: 'block',
		textAlign: 'left',
		textTransform: 'none',
		overflow: 'hidden',
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	buttonName: {
		fontSize: 14,
		fontWeight: 400,
		marginRight: theme.spacing(1),
		textTransform: 'none',
		whiteSpace: 'nowrap',
	},
	buttonSymbol: {
		fontSize: 16,
		fontWeight: 500,
		marginRight: theme.spacing(1),
	},
	buttonArrowDown: {
		marginLeft: 'auto',
	},
	selectTokens: {
		borderRadius: 6,
		fontSize: 14,
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
	},
	selectedItemSymbol: {
		marginLeft: theme.spacing(0.5),
	},
	textFieldSearch: {
		'& .MuiInputBase-root, & .MuiInputBase-root:hover': {
			backgroundColor: alpha(theme.palette.grey[500], 0.3),
			'&.Mui-focused': {
				backgroundColor: alpha(theme.palette.grey[500], 0.3),
			},
		},
		'& .MuiFilledInput-input': {
			fontSize: 16,
			fontWeight: 500,
		},
	},
	searchIcon: {
		position: 'relative',
		top: 2,
	},
	stableCoin: {
		fontSize: 16,
		fontWeight: 4,
		textAlign: 'center',
		borderRadius: 6,
		marginBottom: theme.spacing(3),
		padding: theme.spacing(1, 3),
	},
	errorOutlineIcon: {
		color: theme.palette.grey[50],
	},
}));
