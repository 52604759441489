import { utils } from 'web3';
import { getWeb3 } from 'utils/get-web3';
import setGasLimitAndPrice from 'utils/set-gas-limit-and-price';
import { BN_GAS_LIMIT_UNIT_PRICES } from 'config/constants/transaction-gas-units';

const errorMessages = {
	NOT_ENOUGH_BALANCE: 'Not enough balance',
	INVALID_ADDRESS: 'Invalid Address',
	COIN_EXCEPTION: 'Coin Exception',
};

/**
 * Generates an Exception thrown by a claim error
 *
 * @method MasternodeException
 * @protected
 * @param txResponse {Object} the txResponse that raised the exception
 * @since 0.0.1
 */
// eslint-disable-next-line func-names
const CoinException = function (msg) {
	// note: do not use arrow function, because we need to maintain the right context
	let keys;
	let l;
	let i;
	let key;

	this.name = 'CoinException';
	if (typeof msg === 'string') {
		this.message = msg;
	} else {
		this.message = errorMessages.COIN_EXCEPTION;
		keys = Object.keys(msg);
		l = keys.length;
		i = -1;
		// eslint-disable-next-line no-plusplus
		while (++i < l) {
			key = keys[i];
			this[key] = msg[key];
		}
	}
};

const transferCoin = async (
	chainId,
	from,
	recipient,
	amount,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	// return tokenContract.methods.transfer(recipient, amount).call();
	if (utils.isBN(amount)) {
		amount = amount.toString();
	}

	if (amount.toString() === '0') {
		throw new CoinException(errorMessages.NOT_ENOUGH_BALANCE);
	}

	const rawTx = {
		from,
		to: recipient,
		// NOT always 21000 for transfer coins anymore: Ethereum can have variable values
		// gasLimit: utils.toHex(21000),
		value: utils.toHex(amount),
	};

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.cointransfer[chainId],
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new CoinException(errorMessages.INVALID_ADDRESS);
	}

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export default transferCoin;
