import React, { useContext } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@itsa.io/ui';
import { useIntl } from '@itsa.io/web3utils';
import { LIMIT_TOKENS } from 'config/constants';
import maxtokensCtx from 'context/maxtokens';

import useStyles from 'styles/components/settings/ShowGasPrice';

const MaxTokens = () => {
	const { t } = useIntl();
	const classes = useStyles();
	const { allTokens, toggleMaxTokens } = useContext(maxtokensCtx);

	const handleRadioButtonsChange = event => {
		// note: typeof event.target.value === 'string'
		toggleMaxTokens(event.target.value === 'true');
	};

	return (
		<FormControl component="fieldset">
			<RadioGroup
				aria-label="expertmode"
				className={classes.radioGroup}
				name="sound"
				onChange={handleRadioButtonsChange}
				row
				value={allTokens}
			>
				<FormControlLabel
					control={<Radio color="primary" />}
					label={t('page.settingspage.top_cmc', {
						values: { count: LIMIT_TOKENS },
					})}
					value={false}
				/>
				<FormControlLabel
					control={<Radio color="primary" />}
					label={t('page.settingspage.all')}
					value
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default MaxTokens;
