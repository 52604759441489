/* eslint-disable no-restricted-syntax */
import {
	getSilverNftContract,
	getGoldNftContract,
	getPlatinaNftContract,
} from 'utils/get-contract';
import { utils } from 'web3';
import {
	ITSA_NFTS_SC_ADDRESSES,
	BN_GAS_SUBSCRIBE,
	HEX0,
} from 'config/constants';
import setGasLimitAndPrice from 'utils/set-gas-limit-and-price';
import { BN_GAS_LIMIT_UNIT_PRICES } from 'config/constants/transaction-gas-units';
import { getWeb3 } from 'utils/get-web3';
import getMetadata from 'api/nft-metadata';

const errorMessages = {
	ITSA_NFT_CONTRACT_EXCEPTION: 'NftException Contract Exception',
	NFTID_NOT_A_NUMBER: 'NFT-id not a number type',
	INVALID_ADDRESS: 'Invalid address',
	INVALID_ADDRESSES: 'Address not an Array',
	INVALID_SMARTCONTRACT_ADDRESS:
		'Invalid ItsaSubscription SmartContract Address',
};

/**
 * Generates an Exception thrown by a claim error
 *
 * @method SubscriptionException
 * @protected
 * @param txResponse {Object} the txResponse that raised the exception
 * @since 0.0.1
 */
// eslint-disable-next-line func-names
const NftException = function (msg) {
	// note: do not use arrow function, because we need to maintain the right context
	let keys;
	let l;
	let i;
	let key;

	this.name = 'NftException';
	if (typeof msg === 'string') {
		this.message = msg;
	} else {
		this.message = errorMessages.ITSA_NFT_CONTRACT_EXCEPTION;
		keys = Object.keys(msg);
		l = keys.length;
		i = -1;
		// eslint-disable-next-line no-plusplus
		while (++i < l) {
			key = keys[i];
			this[key] = msg[key];
		}
	}
};

const getSilverTokenURI = async (chainId, nftId) => {
	const contract = getSilverNftContract(chainId);
	const tokenURI = contract && (await contract.methods.tokenURI(nftId).call());
	return tokenURI || '';
};

const getGoldTokenURI = async (chainId, nftId) => {
	const contract = getGoldNftContract(chainId);
	const tokenURI = contract && (await contract.methods.tokenURI(nftId).call());
	return tokenURI || '';
};

const getPlatinaTokenURI = async (chainId, nftId) => {
	const contract = getPlatinaNftContract(chainId);
	const tokenURI = contract && (await contract.methods.tokenURI(nftId).call());
	return tokenURI || '';
};

export const getSilverTokenMetadata = async (chainId, nftId) => {
	const uri = await getSilverTokenURI(chainId, nftId);
	if (!uri) {
		return null;
	}
	return getMetadata(uri);
};

export const getGoldTokenMetadata = async (chainId, nftId) => {
	const uri = await getGoldTokenURI(chainId, nftId);
	if (!uri) {
		return null;
	}
	return getMetadata(uri);
};

export const getPlatinaTokenMetadata = async (chainId, nftId) => {
	const uri = await getPlatinaTokenURI(chainId, nftId);
	if (!uri) {
		return null;
	}
	return getMetadata(uri);
};

export const getGoldSubscriptions = async (chainId, nftId) => {
	const contract = getGoldNftContract(chainId);
	const subscriptions =
		contract && (await contract.methods.getAllSubscriptions(nftId).call());
	return subscriptions || [];
};

export const getPlatinaSubscriptions = async (chainId, nftId) => {
	const contract = getPlatinaNftContract(chainId);
	const subscriptions =
		contract && (await contract.methods.getAllSubscriptions(nftId).call());
	return subscriptions || [];
};

export const getMaxGoldSubscriptions = async chainId => {
	const contract = getGoldNftContract(chainId);
	const subscriptions =
		contract && (await contract.methods.maxSubscriptions().call());
	return subscriptions || [];
};

export const getMaxPlatinaSubscriptions = async chainId => {
	const contract = getPlatinaNftContract(chainId);
	const subscriptions =
		contract && (await contract.methods.maxSubscriptions().call());
	return subscriptions || [];
};

/* ********************************************************************************* */

// write methods:
export const transferSilverOwnership = async (
	chainId,
	nftId,
	sender,
	newOwnerAddress,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!utils.isAddress(sender)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	if (!utils.isAddress(newOwnerAddress)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	const contract = getSilverNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.transferFrom(sender, newOwnerAddress, nftId)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].silver,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.silvernft[chainId].transferFrom,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const transferGoldOwnership = async (
	chainId,
	nftId,
	sender,
	newOwnerAddress,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!utils.isAddress(sender)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	if (!utils.isAddress(newOwnerAddress)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	const contract = getGoldNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.transferFrom(sender, newOwnerAddress, nftId)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].gold,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.goldnft[chainId].transferFrom,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const transferPlatinaOwnership = async (
	chainId,
	nftId,
	sender,
	newOwnerAddress,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!utils.isAddress(sender)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	if (!utils.isAddress(newOwnerAddress)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	const contract = getPlatinaNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.transferFrom(sender, newOwnerAddress, nftId)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].platina,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.platinanft[chainId].transferFrom,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const subscribeGoldAddress = async (
	chainId,
	nftId,
	address,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!utils.isAddress(address)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	const contract = getGoldNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.subscribeAddress(nftId, address)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].gold,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.goldnft[chainId].subscribeAddress,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const subscribePlatinaAddress = async (
	chainId,
	nftId,
	address,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!utils.isAddress(address)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	const contract = getPlatinaNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.subscribeAddress(nftId, address)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].platina,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.platinanft[chainId].subscribeAddress,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const unsubscribeGoldAddress = async (
	chainId,
	nftId,
	address,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!utils.isAddress(address)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	const contract = getGoldNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.unsubscribeAddress(nftId, address)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].gold,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.goldnft[chainId].unsubscribeAddress,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const unsubscribePlatinaAddress = async (
	chainId,
	nftId,
	address,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!utils.isAddress(address)) {
		throw new NftException(errorMessages.INVALID_ADDRESS);
	}
	const contract = getPlatinaNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.unsubscribeAddress(nftId, address)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].platina,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.platinanft[chainId].unsubscribeAddress,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const resetGoldSubscriptions = async (
	chainId,
	nftId,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	const contract = getGoldNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.resetSubscriptions(nftId)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].gold,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.goldnft[chainId].resetSubscriptions,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const resetPlatinaSubscriptions = async (
	chainId,
	nftId,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	const contract = getPlatinaNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.resetSubscriptions(nftId)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].platina,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.platinanft[chainId].resetSubscriptions,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const setAllGoldSubscriptionAddresses = async (
	chainId,
	nftId,
	addresses,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!Array.isArray(addresses)) {
		throw new NftException(errorMessages.INVALID_ADDRESSES);
	}
	for (const address of addresses) {
		if (!utils.isAddress(address)) {
			throw new NftException(errorMessages.INVALID_ADDRESS);
		}
	}
	const contract = getGoldNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.setAllSubscriptions(nftId, addresses)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].gold,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.goldnft[chainId].setAllSubscriptions,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const setAllPlatinaSubscriptionAddresses = async (
	chainId,
	nftId,
	addresses,
	sender,
	sendTx,
	currentGasPrice,
	extraPercentageGas = 10, // HardwareDevices
	isHardwareDevice,
) => {
	if (typeof nftId !== 'number') {
		throw new NftException(errorMessages.NFTID_NOT_A_NUMBER);
	}
	if (!Array.isArray(addresses)) {
		throw new NftException(errorMessages.INVALID_ADDRESSES);
	}
	for (const address of addresses) {
		if (!utils.isAddress(address)) {
			throw new NftException(errorMessages.INVALID_ADDRESS);
		}
	}
	const contract = getPlatinaNftContract(chainId);
	if (!contract) {
		return null;
	}
	const transactionData = contract.methods
		.setAllSubscriptions(nftId, addresses)
		.encodeABI();
	const rawTx = {
		to: ITSA_NFTS_SC_ADDRESSES[chainId].platina,
		data: transactionData,
		from: sender,
		value: HEX0,
	};

	// we NEED an extra check, to be sure that rawTx.to is a valid ethereum address, otherwise the tx-funds will be lost:
	if (!rawTx.to || !utils.isAddress(rawTx.to)) {
		throw new NftException(errorMessages.INVALID_SMARTCONTRACT_ADDRESS);
	}

	await setGasLimitAndPrice(
		chainId,
		rawTx,
		BN_GAS_LIMIT_UNIT_PRICES.platinanft[chainId].setAllSubscriptions,
		currentGasPrice,
		extraPercentageGas,
		isHardwareDevice,
	);

	const web3 = getWeb3(chainId);

	const tx = await sendTx(rawTx, { web3, extraPercentageGas });
	// Metamask transaction: return tx as a String:
	return tx;
};

export const enoughGasForTrial = balance => balance.gte(BN_GAS_SUBSCRIBE);
