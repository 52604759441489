import { utils } from 'web3';
import { parseEnode } from 'utils/key-parser';

/**
 * Checks if an enode string is a valid String
 *
 * @method isValidEnode
 * @param enodeUrl {String} the masternode enode string
 * @protected
 * @return {Boolean} whether the enode string is valid
 * @since 0.4.0
 */
const validEnode = enodeUrl => {
	const parsed = parseEnode(enodeUrl);
	return utils.isAddress(parsed?.masternodeAddress);
};

export default validEnode;
