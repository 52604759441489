import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'column',
		margin: '0 auto',
		order: 2,
		[theme.breakpoints.up('sm')]: {
			maxWidth: 450,
		},
		[theme.breakpoints.up('lg')]: {
			flexDirection: 'row',
			maxWidth: 700,
			order: 1,
		},
	},
	donut: {
		[theme.breakpoints.down('sm')]: {
			width: '80%',
		},
	},
	legendRect: {
		width: 15,
		height: 15,
		borderRadius: 3,
		marginRight: theme.spacing(1),
		[theme.breakpoints.up('lg')]: {
			marginRight: 0,
		},
	},
	tableContainer: {
		maxWidth: 220,
		marginLeft: theme.spacing(5),
	},
	table: {},
	tableCellHead: {
		padding: theme.spacing(0.5),
		borderBottom: 0,
		'&:last-child': {
			padding: theme.spacing(0.5),
		},
	},
	tableRowCell: {
		borderBottom: 0,
		padding: theme.spacing(0.5),
	},
	tableRowCellRect: {},
	tableRowCellName: {
		fontSize: 14,
	},
	tableRowCellValue: {
		fontSize: 14,
		fontWeight: 500,
	},
	tableRowCellInfo: {
		'&:last-child': {
			padding: theme.spacing(0.5),
		},
	},
	legendContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		marginTop: theme.spacing(3),
	},
	legendWrapper: {
		display: 'flex',
		flexBasis: 150,
		margin: theme.spacing(1),
	},
	legendText: {},

	infoIcon: {
		verticalAlign: 'middle',
	},
	popover: {
		pointerEvents: 'none',
	},
	popoverIconDetails: {},
	popoverPaper: {
		padding: theme.spacing(1),
	},
}));
