import { makeStyles } from '@itsa.io/ui';

export default makeStyles(() => ({
	root: {
		'& > svg': {
			width: 140,
		},
	},
	clickable: {
		cursor: 'pointer',
	},
}));
