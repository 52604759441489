import { makeStyles } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {},
	circularContainer: {
		width: '100%',
		textAlign: 'center',
		marginTop: theme.spacing(10),
	},
}));
