import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Box,
	ListItem,
	IconButton,
	InputAdornment,
	TextField,
	Fade,
	Backdrop as MuiBackdrop,
} from '@itsa.io/ui';
import {
	Search as SearchIcon,
	Close as CloseIcon,
	EditOutlined as EditOutlinedIcon,
	Done as DoneIcon,
	DeleteForeverOutlined as DeleteForeverOutlinedIcon,
	FiberManualRecord as FiberManualRecordIcon,
	Security as SecurityIcon,
} from '@material-ui/icons';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import contactsCtx from 'context/contacts';
import addressMatch from 'utils/address-match';

import useStyles from 'styles/components/common/ContactManager';

const ContactManager = ({ open, onClose, onSelect }) => {
	const { t } = useIntl();
	const classes = useStyles();
	const [searchValue, setSearchValue] = useState('');
	const [editAddress, setEditAddress] = useState();
	const [editName, setEditName] = useState('');
	const [nameBeforeEdited, setNameBeforeEdited] = useState('');
	const { contacts, removeContact, saveContact } = useContext(contactsCtx);
	const { address: cryptowalletAddress } = useContext(cryptowalletCtx);
	const inputRef = useRef();

	const handleSearchChange = e => {
		setSearchValue(e.target.value);
	};

	const handleSelectClick = token => {
		if (!editAddress && !addressMatch(token.address, cryptowalletAddress)) {
			onClose();
			onSelect(token);
			setSearchValue('');
		}
	};

	const handleMouseDown = (token, e) => {
		if (addressMatch(token.address, cryptowalletAddress)) {
			e.preventDefault();
		}
	};

	let searchField;
	if (contacts.length > 1) {
		searchField = (
			<TextField
				className={classes.textFieldSearch}
				label="Search"
				variant="filled"
				onChange={handleSearchChange}
				value={searchValue}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon className={classes.searchIcon} />
						</InputAdornment>
					),
					disableUnderline: true,
				}}
				fullWidth
			/>
		);
	}

	let emptyMessage;
	if (contacts.length === 0) {
		emptyMessage = (
			<Box className={classes.emptyMessage}>
				{t('contact_manager.no_addresses_message')}
			</Box>
		);
	}

	const handleEditName = async (e, contact) => {
		e.stopPropagation();

		// need to await setEditAddress, to assure the proper value for `inputRef`
		await setEditAddress(contact.address);
		setEditName(contact.name);

		// get the Input component:
		const textFieldDiv = inputRef?.current;
		if (textFieldDiv) {
			const inputElement = textFieldDiv.querySelector('input');
			if (inputElement) {
				inputElement.focus();
			}
		}
	};

	const handleDeleteAddress = (e, contact) => {
		e.stopPropagation();
		removeContact(contact);
	};

	const handleToggleSecured = (e, contact) => {
		e.stopPropagation();
		contact.secured = !contact.secured;
		saveContact(contact);
	};

	const handleChangeName = e => {
		setEditName(e.target.value);
	};

	const handleFocus = contact => {
		setNameBeforeEdited(contact.name);
	};

	const handleBlur = contact => {
		const nameChanged = nameBeforeEdited !== editName;
		contact.name = editName;
		setEditAddress('');
		setEditName('');
		setNameBeforeEdited('');
		if (nameChanged) {
			saveContact(contact);
		}
	};

	const handleKeyDown = (contact, e) => {
		if (e.keyCode === 27) {
			const nameChanged = nameBeforeEdited !== editName;
			setEditAddress('');
			contact.name = nameBeforeEdited;
			setEditName('');
			setNameBeforeEdited('');
			if (nameChanged) {
				saveContact(contact);
			}
		}
		if (e.keyCode === 13) {
			handleBlur(contact);
		}
	};

	const contactListContent = contacts.map((contact, i) => {
		const { name, address, secured } = contact;
		const query = searchValue.toLowerCase();
		const contactFound =
			query === '' ||
			address.toLowerCase().includes(query) ||
			name.toLowerCase().includes(query);
		const isCurrentAddress = addressMatch(address, cryptowalletAddress);
		const isEditField = editAddress === address;
		const firstIcon = isEditField ? <DoneIcon /> : <EditOutlinedIcon />;

		let prependedIndicator;
		if (isCurrentAddress) {
			prependedIndicator = (
				<FiberManualRecordIcon className={classes.currentAddress} />
			);
		} else {
			prependedIndicator = (
				<IconButton
					// className={clsx(classes.iconButton, {
					// 	[classes.iconButtonCurrAddress]: isCurrentAddress,
					// })}
					onClick={e => handleToggleSecured(e, contact)}
					size="small"
				>
					<SecurityIcon
						className={clsx(classes.security, {
							[classes.isSecured]: secured,
						})}
					/>
				</IconButton>
			);
		}

		let ref;
		if (isEditField) {
			ref = inputRef;
		}

		return (
			<ListItem
				button={!isCurrentAddress}
				component="div"
				className={clsx(classes.item, {
					[classes.hiddenItem]: !contactFound,
					[classes.disabledItem]: isCurrentAddress,
				})}
				onClick={() => handleSelectClick(contact)}
				key={address || i}
			>
				<div className={classes.accountOptions}>
					{prependedIndicator}
					<TextField
						className={clsx(classes.textFieldAccount, {
							[classes.textFieldDisabled]: !isEditField,
						})}
						placeholder={`Account ${i + 1}`}
						color="primary"
						value={editAddress === address ? editName : name}
						onBlur={() => handleBlur(contact)}
						onChange={handleChangeName}
						onFocus={() => handleFocus(contact)}
						onKeyDown={e => handleKeyDown(contact, e)}
						onMouseDown={e => handleMouseDown(contact, e)}
						disabled={!isEditField}
						ref={ref}
						fullWidth
						InputProps={{
							disableUnderline: !isEditField,
						}}
					/>
					<div className={classes.itemIconsBox}>
						<IconButton
							className={clsx(classes.iconButton, {
								[classes.iconButtonCurrAddress]: isCurrentAddress,
							})}
							onClick={e => handleEditName(e, contact)}
							size="small"
						>
							{firstIcon}
						</IconButton>
						<IconButton
							className={clsx(classes.iconButton, {
								[classes.iconButtonCurrAddress]: isCurrentAddress,
							})}
							onClick={e => handleDeleteAddress(e, contact)}
							size="small"
						>
							<DeleteForeverOutlinedIcon />
						</IconButton>
					</div>
				</div>
				<div className={classes.itemAddress}>{address}</div>
			</ListItem>
		);
	});

	// AddCircle as AddIcon,
	// Edit as EditIcon,
	// DeleteForever as TrashIcon,

	const listContent = (
		<Fade timeout={300} in={open}>
			<Box className={classes.root}>
				<Box display="flex">
					<Box className={classes.title}>
						{t('contact_manager.select_address')}
					</Box>
					<IconButton onClick={onClose} className={classes.closeIcon}>
						<CloseIcon />
					</IconButton>
				</Box>
				{searchField}
				{emptyMessage}
				<Box className={classes.contactListContent}>{contactListContent}</Box>
			</Box>
		</Fade>
	);

	return (
		<>
			<MuiBackdrop className={classes.backdrop} open={open} onClick={onClose} />
			<div>{listContent}</div>
		</>
	);
};

ContactManager.propTypes = {
	onClose: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
};

export default ContactManager;
