/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import navigatorCtx from 'context/navigator';
import gaspriceCtx from 'context/gasprice';
import { Box, Button, TextField as MuiTextField } from '@material-ui/core';
import { useIntl, cryptowalletCtx } from '@itsa.io/web3utils';
import useAlert from 'hooks/useAlert';
import {
	PAGES_NAMES,
	GAS_PERCENTAGES,
	NETWORK_NAMES,
	GENERAL_MESSAGE_TIMEOUT,
} from 'config/constants';
import extragaspricehardwarewalletCtx from 'context/extragaspricehardwarewallet';
import securedsendtxCtx from 'context/securedsendtx';
import nftcardCtx from 'context/nftcard';
import GasSlider from 'components/common/GasSlider';
import { utils } from 'web3';
import addressMatch from 'utils/address-match';
import {
	transferSilverOwnership,
	transferGoldOwnership,
	transferPlatinaOwnership,
} from 'utils/smartcontracts/itsa-wallet-nft';

import useStyles from 'styles/pages/TransferNftPage';

const { isAddress } = utils;

const REGEXP_ADDRESS_TYPING = /^0x[0-9a-fA-F]*$/;

const TransferNftPage = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const { chainId, address, hardwareWallet } = useContext(cryptowalletCtx);
	const sendTx = useContext(securedsendtxCtx);
	const alert = useAlert();
	const { gasprice } = useContext(gaspriceCtx);
	const { extraGaspriceHardwareWallet } = useContext(
		extragaspricehardwarewalletCtx,
	);
	const [gaspriceIndex, setGaspriceIndex] = useState(
		extraGaspriceHardwareWallet,
	);
	const { setPage } = useContext(navigatorCtx);
	const { selectedNftCard } = useContext(nftcardCtx);
	const [newOwnerWallet, setNewOwnerWallet] = useState('');
	const [sendButtonEnabled, setSendButtonEnabled] = useState(true);
	const isMounted = useRef(false);
	const validNewOwnerWallet = utils.isAddress(newOwnerWallet);

	let helperNewOwnerWalletText;

	const getExtraPercentageGas = () => GAS_PERCENTAGES[gaspriceIndex];

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAbort = () => {
		setPage(PAGES_NAMES.NFTADRESSES);
	};

	const handleChangeNewOwnerWallet = e => {
		const value = e.target.value.trim();
		if (value === '' || value === '0' || REGEXP_ADDRESS_TYPING.test(value)) {
			setNewOwnerWallet(value);
		}
	};

	const targetPermitted = !addressMatch(newOwnerWallet, address);
	if (!targetPermitted) {
		helperNewOwnerWalletText = t(
			'page.transfernftpage.cannot_be_this_wallet_address',
		);
	}

	const textFieldNewOwnerWallet = (
		<MuiTextField
			id="newOwnerWallet"
			className={classes.textFieldNewOwnerWallet}
			label={t('page.transfernftpage.address_new_nft_owner')}
			placeholder="0x..."
			variant="filled"
			value={newOwnerWallet}
			onChange={handleChangeNewOwnerWallet}
			fullWidth
			error={!targetPermitted}
			helperText={helperNewOwnerWalletText}
			InputProps={{
				disableUnderline: true,
			}}
			InputLabelProps={{
				shrink: true,
			}}
		/>
	);

	const handleTransferOwnership = async () => {
		if (selectedNftCard.chainid === chainId) {
			setSendButtonEnabled(false);
			try {
				if (selectedNftCard.type === 'silver') {
					await transferSilverOwnership(
						chainId,
						selectedNftCard.nftId,
						address,
						newOwnerWallet,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				} else if (selectedNftCard.type === 'gold') {
					await transferGoldOwnership(
						chainId,
						selectedNftCard.nftId,
						address,
						newOwnerWallet,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				}
				if (selectedNftCard.type === 'platina') {
					await transferPlatinaOwnership(
						chainId,
						selectedNftCard.nftId,
						address,
						newOwnerWallet,
						sendTx,
						gasprice,
						getExtraPercentageGas(),
						hardwareWallet,
					);
				}
			} catch (err) {
				alert(err.message, 'error');
			}
			if (isMounted.current) {
				setSendButtonEnabled(true);
			}
		} else {
			alert(
				t('page.transfernftpage.select_network', {
					values: {
						networkname: NETWORK_NAMES[selectedNftCard.chainid],
					},
				}),
				{
					severity: 'warning',
					timeout: GENERAL_MESSAGE_TIMEOUT,
				},
			);
		}
	};

	const bindToSubscriptionButton = (
		<Box
			className={clsx(classes.buttonHorizontalGroup, classes.buttonFirstRow)}
		>
			<Button
				disabled={!sendButtonEnabled}
				variant="outlined"
				size="large"
				onClick={handleAbort}
			>
				{t('page.transfernftpage.cancel')}
			</Button>
			<Button
				disabled={
					!validNewOwnerWallet ||
					!targetPermitted ||
					!sendButtonEnabled ||
					!isAddress(newOwnerWallet)
				}
				onClick={handleTransferOwnership}
				variant="outlined"
				size="large"
			>
				{t('page.transfernftpage.transfer_nft_ownership')}
			</Button>
		</Box>
	);

	const title = (
		<Box className={classes.newOwnerWalletContainer}>
			{t('page.transfernftpage.title')}
		</Box>
	);

	const description = (
		<Box className={classes.description}>
			{t('page.transfernftpage.description', {
				values: {
					nftname: selectedNftCard.metadata.name,
					nftid: selectedNftCard.nftId,
				},
			})}
		</Box>
	);

	const changeGaspriceIndex = (e, val) => {
		setGaspriceIndex(val);
	};

	let gaspriceSlider;
	if (hardwareWallet) {
		gaspriceSlider = (
			<GasSlider value={gaspriceIndex} onChange={changeGaspriceIndex} />
		);
	}

	return (
		<Box
			className={classes.root}
			autoComplete="off"
			component="form"
			noValidate
		>
			{title}
			{description}
			{textFieldNewOwnerWallet}
			{gaspriceSlider}
			{bindToSubscriptionButton}
		</Box>
	);
};

export default TransferNftPage;
