import React, { useContext } from 'react';
import { FormControlLabel, Checkbox, Button } from '@itsa.io/ui';
import { useIntl } from '@itsa.io/web3utils';
import disclaimermodalCtx from 'context/disclaimermodal';
import useStyles from 'styles/components/common/DisclaimerBox';

const DisclaimerBox = () => {
	const classes = useStyles();
	const { t } = useIntl();
	const { show, comply, complied } = useContext(disclaimermodalCtx);

	const handleChange = event => {
		comply(event.target.checked);
	};

	return (
		<div className={classes.root}>
			<FormControlLabel
				className={classes.formControlLabel}
				control={
					<Checkbox
						checked={complied}
						onChange={handleChange}
						color="primary"
					/>
				}
				label={t('disclaimer_box.disclaimer_description')}
			/>

			<Button
				className={classes.buttonDisclaimer}
				variant="contained"
				color="primary"
				disableElevation
				onClick={show}
			>
				{t('disclaimer_box.disclaimer')}
			</Button>
		</div>
	);
};

export default DisclaimerBox;
