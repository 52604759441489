import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: () => {
		return {
			padding: theme.spacing(2, 0, 1, 0),
			height: 6,
			'& .MuiSlider-track': {
				height: 18,
				borderRadius: 22,
				backgroundColor: alpha(theme.palette.orange[200], 0.3),
			},
			'& .MuiSlider-rail': {
				height: 18,
				borderRadius: 22,
				backgroundColor: theme.palette.orange[200],
				'&::before': {
					position: 'absolute',
					content: '""',
					top: 0,
					left: 0,
					width: '100%',
					height: 18,
					borderRadius: 0,
					backgroundColor: alpha(theme.palette.secondary.main, 0.8),
				},
			},
			'& .MuiSlider-thumb': {
				height: 26,
				width: 26,
				marginTop: -5,
				backgroundColor: theme.palette.orange[200],
				'&:before': {
					boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
				},
			},
			'& .MuiSlider-valueLabel': {
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'row',
				whiteSpace: 'nowrap',

				top: -40,
				left: 0,
				right: 0,
				margin: theme.spacing(0, 'auto'),
				'& *': {
					borderRadius: 0,
					left: 0,
					right: 0,
					backgroundColor: 'transparent',
					width: 'auto',
					fontWeight: 500,
					color: theme.palette.secondary.main,
					transform: 'none',
				},
				'& > span': {
					// background: theme.palette.orange[100],
				},
				'& > span:last-child': {
					borderRadius: 8,
					// width: '100%',
					// minWidth: 60,
					padding: theme.spacing(0, 1),
					backgroundColor: theme.palette.orange[100],
				},
				'&:before': {
					display: 'none',
				},
			},
			'& .MuiSlider-mark': {
				width: 3,
				height: 18,
				borderRadius: 0,
				backgroundColor: theme.palette.secondary.main,
			},
			'& .MuiSlider-markActive': {
				left: `-4px!important`,
			},
			'& .MuiSlider-markLabel': {
				top: 43,
			},
			'&.Mui-disabled': {
				opacity: 0.3,
			},
		};
	},
}));
